var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 36 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M10.9963 4.00005C8.87509 4.00005 7.14941 5.72572 7.14941 7.84689C7.14941 9.96798 8.87509 11.6937 10.9963 11.6937C13.1174 11.6937 14.8431 9.96798 14.8431 7.84689C14.8431 5.72572 13.1174 4.00005 10.9963 4.00005ZM10.9963 5.22885C12.4421 5.22885 13.6143 6.40095 13.6143 7.84689C13.6143 9.29275 12.4421 10.4649 10.9963 10.4649C9.5504 10.4649 8.37821 9.29275 8.37821 7.84689C8.37821 6.40095 9.5504 5.22885 10.9963 5.22885Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M17.1835 17.5856C16.918 16.0718 16.1911 14.8215 15.0814 13.9698C14.0079 13.146 12.5954 12.7106 10.9963 12.7106C9.39731 12.7106 7.98463 13.146 6.91119 13.9698C5.80149 14.8215 5.07466 16.0718 4.80915 17.5856C4.75052 17.9199 4.9739 18.2383 5.30813 18.2969C5.34403 18.3033 5.37975 18.3062 5.41495 18.3062C5.70775 18.3062 5.96712 18.0962 6.01943 17.7979C6.44951 15.3457 8.26348 13.9394 10.9963 13.9394C13.7292 13.9394 15.5432 15.3457 15.9732 17.7979C16.0255 18.0962 16.2848 18.3062 16.5776 18.3062C16.6129 18.3062 16.6486 18.3033 16.6845 18.2969C17.0188 18.2383 17.2421 17.9199 17.1835 17.5856Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M31.25 0.75H4.75C2.54086 0.75 0.75 2.54086 0.75 4.75V18.45C0.75 20.6591 2.54086 22.45 4.75 22.45H31.25C33.4591 22.45 35.25 20.6591 35.25 18.45V4.75C35.25 2.54086 33.4591 0.75 31.25 0.75Z",
          stroke: _vm.color,
          "stroke-width": "1.5",
        },
      }),
      _c("path", {
        attrs: {
          d: "M30.4002 8.26666H19.7335C19.4391 8.26666 19.2002 8.02773 19.2002 7.73333C19.2002 7.43893 19.4391 7.2 19.7335 7.2H30.4002C30.6946 7.2 30.9335 7.43893 30.9335 7.73333C30.9335 8.02773 30.6946 8.26666 30.4002 8.26666Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M30.3999 11.4667H22.9332C22.6388 11.4667 22.3999 11.2277 22.3999 10.9333C22.3999 10.6389 22.6388 10.4 22.9332 10.4H30.3999C30.6943 10.4 30.9332 10.6389 30.9332 10.9333C30.9332 11.2277 30.6943 11.4667 30.3999 11.4667Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M30.3999 14.6667H22.9332C22.6388 14.6667 22.3999 14.4277 22.3999 14.1333C22.3999 13.8389 22.6388 13.6 22.9332 13.6H30.3999C30.6943 13.6 30.9332 13.8389 30.9332 14.1333C30.9332 14.4277 30.6943 14.6667 30.3999 14.6667Z",
          fill: _vm.color,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }