import { render, staticRenderFns } from "./CurrentRecord.vue?vue&type=template&id=021b6948&scoped=true"
import script from "./CurrentRecord.vue?vue&type=script&lang=js"
export * from "./CurrentRecord.vue?vue&type=script&lang=js"
import style0 from "./CurrentRecord.vue?vue&type=style&index=0&id=021b6948&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "021b6948",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/drone/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('021b6948')) {
      api.createRecord('021b6948', component.options)
    } else {
      api.reload('021b6948', component.options)
    }
    module.hot.accept("./CurrentRecord.vue?vue&type=template&id=021b6948&scoped=true", function () {
      api.rerender('021b6948', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dtList/components/CurrentRecord.vue"
export default component.exports