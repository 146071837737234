import Vue from '../main'

/**
 * 取得時區
 * @date 2021-05-18
 * @returns {String} 時區 Asia/Taipei (目前設置時區)
 */
export const timezone = () => Vue.$day.tz().$x.$timezone

/**
 * 時間區間 (起始時間,結束時間)
 * @date 2021-05-18
 * @param {Number} howManyDayFromToday 從今天起算幾天(過去)
 * @returns {String} 時間區間 '2020-01-01,2020-01-30'
 */
export const getDayRange = howManyDayFromToday => {
  const today = Vue.$day().format('YYYY-MM-DD') // 今天日期

  // 過去日期
  const beforeDay = Vue.$day()
    .subtract(howManyDayFromToday, 'day')
    .format('YYYY-MM-DD')

  return `${beforeDay},${today}`
}
