<template>
  <div>
    <div class="header p-relative">
      <!-- 底圖 -->
      <v-img
        class="header__bg"
        cover
        :src="baseImgUrl + serviceData.bgBanner"
      />
      <!-- 文字說明區塊 -->
      <div class="header__description p-absolute d-flex">
        <div class="w-70">
          <!-- TITLE -->
          <div class="text-h5 icon--text font-weight-bold">
            {{ websiteName + serviceData.title }}
          </div>
          <!-- DESCRIPTION -->
          <div>
            {{ serviceData.description }}
          </div>
        </div>

        <div class="website-logo w-h-60">
          <v-img :src="baseImgUrl + websiteLogo" />
        </div>
      </div>
    </div>

    <!-- APPS -->
    <!-- 全站下載 -->
    <div class="px-6">
      <div class="navBar app rounded mt-10 px-3 py-4 d-flex align-center justify-center">
        <div class="app__icon d-flex justify-center align-center">
          <v-img
            max-width="60"
            class="rounded-lg"
            :src="baseImgUrl + application.icon"
          />
        </div>

        <div class="app__description ml-4 flex-grow-1">
          <div class="text-h6">
            {{ application.title }}
          </div>
          <div class="caption hint--text">
            {{ application.description }}
          </div>
        </div>

        <div class="app_download">
          <v-btn
            height="32"
            color="primary"
            @click="downloadApp"
          >
            <span class="caption btnText--text">
              {{ $t('global.download') }}
            </span>
          </v-btn>
        </div>
      </div>
    </div>

    <!-- 下載後無法打開 btn -->
    <div class="download-problem-btn w-100 px-6">
      <router-link
        to="/app/tutorial"
        class="text-decoration-none"
      >
        <div
          class="d-flex rounded justify-center align-center py-5 px-4"
          :style="customGradientBgTypeA"
        >
          <!-- 在線客服 -->
          <div class="btnText--text caption flex-grow-1">
            {{ $t('global.downloadProblem') }}
          </div>
          <div class="">
            <v-icon
              right
              class="btnText--text"
            >
              mdi-chevron-right
            </v-icon>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import hexToRgba from 'hex-to-rgba'
import { deviceCheck } from '../../../utils/device'

export default {
  data: () => ({}),

  computed: {
    ...mapGetters(['gradientBg', 'baseImgUrl', 'websiteLogo', 'customGradientBgTypeA', 'websiteName']),

    ...mapState({
      serviceUrl: state => state.serviceUrl,
      theme: state => state.theme,

      // 站台應用
      serviceData: state => state.service.app,
      // 全站 APP
      application: state => state.service.application,
    }),

    /**
     * primary 色 轉 hex
     * @date 2021-10-08
     * @returns {any}
     */
    primaryColor() {
      const color = this.$vuetify.theme.themes[this.theme]?.primary || ''
      return hexToRgba(color)
    },

    /**
     * 客製化漸層色
     * @date 2021-10-08
     * @returns {any}
     */
    customerClr() {
      const computePrimaryClr = this.primaryColor.split(', ')
      const r = computePrimaryClr[0].slice(5, computePrimaryClr[0].length)
      const g = computePrimaryClr[1]
      const b = computePrimaryClr[2]

      return `background-image: linear-gradient(251deg, rgba(${r}, ${g}, ${b}, 1), rgba(${r - 21}, ${g - 28}, ${b - 42}, 1));`
    },
  },

  mounted() {
  },

  methods: {
    ...mapActions(['get_app_download_url', 'set_website', 'show_alert']),

    goDownloadPage(downloadUrl) {
      if (!downloadUrl) {
        this.show_alert({
          icon: 'fail',
        })
        return false
      }

      location.href = downloadUrl
    },

    /**
     * 下載 app && 復製 recommend
     * @date 2022-01-22
     */
    async downloadApp() {
      const device = deviceCheck()
      const website = await this.set_website()
      const { data: { android_landscape_update_url, app_landscape_download_url } } = await this.get_app_download_url({ website })

      device === 'android' ? this.goDownloadPage(android_landscape_update_url) : this.goDownloadPage(app_landscape_download_url)
    },

    ...mapActions([
      'get_service_url',
      'show_alert',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.header {
	&__description {
		top: 50px;
		left: 24px;
	}
}

.w-70 {
	width: 70%;
}

.w-h-60 {
	width: 60px;
	height: 60px;
}

.download-problem-btn {
	position: fixed;
	bottom: 80px;
	left: 0;
}

.btnText--text {
  white-space:normal;
	word-break:break-all;
	word-wrap:break-word;
}
</style>
