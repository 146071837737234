var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100dvh over-flow-y-scroll pt-56" },
    [
      _c(
        "Toolbar",
        [
          _c(
            "v-btn",
            {
              attrs: {
                slot: "btn",
                icon: "",
                color: "success",
                to: "/service",
              },
              slot: "btn",
            },
            [
              _c("Icon", {
                staticClass: "icon--text",
                attrs: {
                  data: require("@icon/bottomNav/service.svg"),
                  width: "24px",
                  height: "24px",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "px-1" },
            [
              _c(
                "v-col",
                { staticClass: "py-0 text-center", attrs: { cols: "12" } },
                [
                  _c(
                    "h2",
                    { staticClass: "secondary--text text-center mt-7 mb-6" },
                    [_vm._v(" " + _vm._s(_vm.$t("app.title")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    { attrs: { to: "/app/tutorial", color: "primary" } },
                    [
                      _c("span", { staticClass: "title--text" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("app.installationTutorial")) + " "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-center download-app",
                  attrs: { cols: "12" },
                },
                [
                  _c("v-img", {
                    staticClass: "mt-4",
                    attrs: {
                      height: "290",
                      src: _vm.baseImgUrl + _vm.appDemoImg,
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "btn relative-y-3" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-3",
                          attrs: { color: "primary" },
                          on: { click: _vm.downloadApp },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("global.landscape") + " APP") +
                              " "
                          ),
                        ]
                      ),
                      _c("input", {
                        attrs: { id: "recommendCode", type: "text" },
                        domProps: { value: _vm.recommendCode },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "px-10" },
            [
              _vm._l(_vm.feature, function (item, index) {
                return _c(
                  "v-col",
                  {
                    key: index,
                    staticClass:
                      "d-flex align-center justify-start flex-column subtitle-2 text-center title--text",
                    attrs: { cols: "6" },
                  },
                  [
                    _c("Icon", {
                      staticClass: "secondary--text mb-4",
                      attrs: {
                        data: _vm.featureIcon[index],
                        width: "38",
                        height: "38",
                      },
                    }),
                    _c("div", { staticClass: "feature__title mb-1" }, [
                      _vm._v(" " + _vm._s(Object.keys(item)[0]) + " "),
                    ]),
                    _c("div", { staticClass: "feature__description caption" }, [
                      _vm._v(" " + _vm._s(Object.values(item)[0]) + " "),
                    ]),
                  ],
                  1
                )
              }),
              _c("v-col", { attrs: { cols: "6" } }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }