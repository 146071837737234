var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "maintenance d-flex justify-center align-center",
      style: { backgroundColor: _vm.bgColor },
    },
    [
      _c("Icon", {
        staticClass: "white--text",
        attrs: {
          data: require("@icon/maintenance.svg"),
          width: "24px",
          height: "24px",
        },
      }),
      _c("strong", { staticClass: "white--text ml-3" }, [
        _vm._v(" " + _vm._s(_vm.$t("global.maintenance")) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }