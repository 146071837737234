import request from '@/api/request'
/*
  API 電子遊藝
  電子遊藝相關的 API
    - 取得遊戲商logo列表
    - 取得個別遊戲商的遊戲清單
    - 取得個別遊戲商的熱門遊戲
    - 取得個別遊戲商的最新遊戲
    - 加入/移除我的最愛遊戲
    - 取得遊戲連結
*/

/**
 * 取得產品商游戲列表 與 logo、相關資訊
 * @date 2021-06-02
 * @param {string} vendorName 產品商類型
 * 全部：all ,
 * 棋牌：card ,
 * 漁機：fish,
 * 體育：sport,
 * 電子：slot ,
 * 電競：egame ,
 * 真人：live,
 * 彩票：lottery
 * @returns {function} 請求實例
 */
export const getVendorLogo = (vendorName) =>
  request.get('api/v1/game/brands', { params: { type: vendorName } })

/**
 * 取得個別遊戲商的所有遊戲 (全部)
 * @date 2021-06-02
 * @param {string} {vendor 遊戲商代碼
 * @param {string} page 頁碼
 * @param {string} filter} 搜尋遊戲用，輸入遊戲名稱
 * @returns {object} 請求實例
 */
export const getVendorGameList = ({ vendor, page, filter }) =>
  request.get('api/v2/game/all', {
    params: {
      vendor,
      page,
      filter,
    },
  })

/**
 * 取得個別 vendor(熱門)遊戲
 * @date 2021-06-02
 * @param {string}} {vendor 遊戲商代碼
 * @param {string} page } 頁數
 * @returns {object} 熱門游戲
 */
export const getVendorHotGameList = ({ vendor, page }) =>
  request.get('api/v1/game/hot', {
    params: { vendor, page },
  })

/**
 * 取得個別遊戲商的(最新)遊戲
 * @date 2021-06-03
 * @param {string}} {vendor 遊戲商代碼
 * @param {string} page } 頁數
 * @returns {object} 最新遊戲
*/
export const getVendorNewGameList = ({ vendor, page }) =>
  request.get('api/v1/game/new', {
    params: {
      vendor,
      page,
    },
  })

/**
 * 取得個別遊戲商的(最愛)遊戲
 * @date 2021-06-03
 * @param {string}} {vendor 遊戲商代碼
 * @param {string} page } 頁數
 * @returns {object} (最愛)遊戲
 */
export const getVendorFavorGameList = ({ vendor, page }) =>
  request.get('api/v1/game/favor', {
    params: { vendor, page },
  })

/**
 * (新增/移除) 使用者收藏的遊戲
 * @date 2021-06-07
 * @param {object} gameCode 遊戲代號
 * @returns {object} 收藏狀態 "favor":"Y"
 */
export const editFavorGame = (gameCode) =>
  request.post('api/v1/game/toggle-favor', gameCode)

/**
 * 取得遊戲連結
 * @date 2021-06-15
 * @param {object} gameCode 遊戲代號
 */
export const getGameLaunchUrl = (gameCode) =>
  request.post('api/v2/game/launch', gameCode)

/**
 * 取得遊戲連結 @依裝置 (navbar)
 * @date 2021-09-30
 * @param {String} gameCode 遊戲代碼 (pc)
 * @returns {String} 啟動遊戲連結 (navbar 類)
 */
export const getGameLaunchUrlByDevice = (gameCode) =>
  request.post('api/v2/game/launch', gameCode)

/*
  取得所有熱門遊戲
*/
export function getAllHotGameLaunchUrlAPI() {
  return request({
    url: 'api/v1/game/hot-list',
    method: 'get',
  })
}

/*
  取得雷火所有賽事
*/
export function getGameEventAPI(data) {
  return request({
    url: `/api/v4/events/?timing=${data.timing}&lang=${data.lang}`,
    method: 'get',
    headers: {
      Authorization: 'Bearer aaf84633f4d54c6c9b4cd744364619ea',
    },
    baseURL: 'https://api-v4.zly889.com',
  })
}
