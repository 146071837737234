<template>
  <v-bottom-navigation
    fixed
    :background-color="isLiffMode ? '#B77A96' : 'navBar'"
    grow
    :height="navHeight"
  >
    <!-- 優惠 -->
    <v-btn
      :color="isLiffMode ? '#B77A96' : 'navBar'"
      :height="navHeight"
      :to="isLiffMode ? '/gift' : '/bonus'"
    >
      <!-- 如果是liffMode -->
      <template v-if="isLiffMode">
        <img
          src="@/assets/icons/gift-icon.png"
          width="37px"
        >
      </template>

      <template v-else>
        <Icon
          v-if="$route.name === 'bonus'"
          data="@icon/bottomNav/bonus-active.svg"
          width="24px"
          height="24px"
          class="primary--text nav-active"
        />
        <Icon
          v-else
          data="@icon/bottomNav/bonus.svg"
          width="24px"
          height="24px"
          class="icon--text"
        />
      </template>
    </v-btn>

    <!-- 客服 -->
    <v-btn
      :color="isLiffMode ? '#B77A96' : 'navBar'"
      class="service-btn p-relative"
      :height="navHeight"
      @click="showServiceArea"
    >
      <template v-if="isLiffMode">
        <img
          src="@/assets/icons/lf-service.png"
          width="37px"
        >
      </template>

      <template v-else>
        <!-- to="/service" -->
        <Icon
          v-if="$route.name === 'service'"
          data="@icon/bottomNav/service-active.svg"
          width="24px"
          height="24px"
          class="primary--text nav-active"
        />
        <Icon
          v-else
          data="@icon/bottomNav/service.svg"
          width="24px"
          height="24px"
          class="icon--text"
        />
      </template>

      <!-- Service Items -->
      <div
        v-show="serviceArea.status"
        class="service-items-area text-left"
      >
        <ul class="pl-0">
          <li
            v-for="(service, index) in serviceItems"
            :key="service + index"
            class="my-3 cursor-pointer"
            @click.stop="goService(service.name)"
          >
            <div v-if="service.name === 'Line' && showLineService || service.name === '24h'">
              <v-avatar
                color="white"
                size="40"
              >
                <img
                  class="pa-2"
                  :src="service.icon"
                  alt="line-service"
                >
              </v-avatar>
              <span class="caption ml-2 white--text">
                {{ service.text }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </v-btn>

    <!-- WEBSITE LOGO -->
    <router-link
      class="websiteLogo"
      to="/"
    >
      <div
        class="wh-56px rounded-circle d-flex justify-center align-center"
        :class="customClr"
      >
        <img
          :src="baseImgUrl + websiteLogo"
          :alt="websiteName"
          class="wh-38px"
        >
      </div>
    </router-link>

    <!-- 紀錄 -->
    <v-btn
      :color="isLiffMode ? '#B77A96' : 'navBar'"
      :height="navHeight"
      to="/record"
    >
      <template v-if="isLiffMode">
        <img
          src="@/assets/icons/lf-record.png"
          width="37px"
        >
      </template>

      <template v-else>
        <Icon
          v-if="$route.name === 'record'"
          data="@icon/bottomNav/record-active.svg"
          width="24px"
          height="24px"
          class="primary--text nav-active"
        />
        <Icon
          v-else
          data="@icon/bottomNav/record.svg"
          width="24px"
          height="24px"
          class="icon--text"
        />
      </template>
    </v-btn>

    <!-- 我的 -->
    <v-btn
      :color="isLiffMode ? '#B77A96' : 'navBar'"
      :height="navHeight"
      to="/user"
    >
      <template v-if="isLiffMode">
        <img
          src="@/assets/icons/lf-user.png"
          width="37px"
        >
      </template>

      <template v-else>
        <Icon
          v-if="$route.name === 'user'"
          data="@icon/bottomNav/profile-active.svg"
          width="24px"
          height="24px"
          class="primary--text nav-active"
        />
        <Icon
          v-else
          data="@icon/bottomNav/profile.svg"
          width="24px"
          height="24px"
          class="icon--text"
        />
      </template>
    </v-btn>

    <v-overlay
      :value="serviceArea.status"
      opacity=".9"
    />
  </v-bottom-navigation>
</template>

<script>
import bottomNavMixins from '../mixins/bottomNavMixins'

export default {
  mixins: [bottomNavMixins],
  computed: {
    customClr() {
      return this.isLiffMode ? 'custom-nav-color' : 'primary'
    },
  },
}
</script>

<style lang="scss" scoped>
.websiteLogo {
  transform: translateY(-10px);
}

.wh-56px {
  height: 56px;
  width: 56px;
}

.wh-38px {
  height: 38px;
  width: 38px;
}

.service-items-area {
  position: absolute;
  bottom: 72px;
  left: 0;
  z-index: 99;

  >ul {
    list-style: none;
  }
}

.custom-nav-color {
  background: #EABB87;
}
</style>
