<template>
  <div
    id="agent"
    class="pt-56"
  >
    <!-- Toolbar -->
    <Toolbar
      class="toolbar"
    >
      <v-btn
        slot="btn"
        icon
        color="success"
        to="/service"
      >
        <Icon
          data="@icon/bottomNav/service.svg"
          width="24px"
          height="24px"
          class="icon--text"
        />
      </v-btn>
    </Toolbar>

    <!--  content -->
    <v-container class="h-100">
      <v-row class="px-1">
        <v-col
          cols="12"
          class="header text-center pb-1"
        >
          <div class="header__img text-center d-flex justify-center">
            <div>
              <v-img
                v-if="websiteLogo"
                id="goAdminImg"
                class="cursor-pointer"
                width="72"
                contain
                height="72"
                :src="baseImgUrl + websiteLogo"
              />
              <h1
                v-if="!websiteLogo"
                class="title--text"
              >
                {{ websiteName }}
              </h1>
            </div>
          </div>

          <div class="header__subtitle title--text my-2">
            {{ $t('agent.page1Title') }}
          </div>

          <div class="header__title text-h6 font-weight-medium vipCard1 my-1">
            {{ $t('agent.page1Subtitle') }}
          </div>

          <div class="header__description comment--text subtitle-2 my-1">
            {{ $t('agent.page1Desc') }}
          </div>
        </v-col>

        <!-- 特色 -->
        <v-col
          v-for="(item, index) in joinUsItem"
          :key="index"
          cols="6"
          class="text-center py-5"
        >
          <!-- ICON -->
          <component
            :is="`Join0${index + 1}`"
            :color="primaryColor"
          />
          <!-- 說明文字 -->
          <div class="title--text caption">
            {{ $t(`agent_slider2.section${index + 1}_title`) }}
          </div>
        </v-col>

        <!-- call me -->
        <v-col
          v-for="item in contacts"
          :key="item._id"
          cols="12"
        >
          <div
            class="contact inputBg d-flex justify-start px-6 align-center rounded"
            :style="{ border: `solid 1px ${primaryColor}` }"
          >
            <div class="contact__img mr-5">
              <v-img
                width="32"
                height="32"
                contain
                :src="imgCheck(item.type)"
              />
            </div>

            <div class="contact__text caption">
              <div class="contact__text-name mb-1 title--text">
                {{ item.title }}
              </div>
              <div
                :id="item.type"
                class="contact__text--info link--text"
              >
                {{ item.info }}
              </div>
            </div>

            <v-spacer />

            <v-btn
              icon
              class="pa-1"
              @click="copyText(item.type)"
            >
              <Icon
                data="@icon/copy.svg"
                width="24"
                height="24"
                class="subtitle--text"
              />
            </v-btn>
          </div>
        </v-col>

        <!-- 加入我們 btn -->
        <v-col
          cols="12"
          class="pt-5 pb-6"
        >
          <v-btn
            height="44"
            block
            color="primary"
            to="/service"
          >
            <span class="btnText--text subtitle-1">
              {{ $t('myCenter.joinUs') }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import agentMixin from '../mixins/agentMixin'

export default {
  mixins: [agentMixin],
}
</script>

<style lang="scss" scoped>
#goAdminImg {
	user-select: none;
}
</style>
