<template>
  <div
    id="home"
    class="h-100dvh over-flow-y-scroll"
    :style="{'padding-top':`${fixLayoutHeight}px`}"
    :class="[currentDevice === 'ios' ? 'pb-72' : 'pb-56']"
  >
    <button
      ref="hiddenButton"
      class="hidden-btn"
    />

    <!-- SAFARI 禁開 -->
    <Dialog
      :dialog="dialog.status"
      :width="280"
      :height="170"
      @update-dialog="setSafariDialogStatus"
    >
      <p
        slot="text"
        class="px-6 mb-0"
      >
        {{
          $t('flashMessage.browserSetting', {browser: device})
        }}
      </p>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="dialog.status = !dialog.status"
      >
        {{ $t('global.confirmation') }}
      </v-btn>
    </Dialog>

    <!-- 最新消息 dialog -->
    <v-dialog
      v-model="newsDialog"
      overlay-opacity=".8"
      :max-width="280"
    >
      <!-- 關閉 dialog btn -->
      <div
        v-if="layoutNotTemplate3OrTemplate4"
        class="text-right mb-3"
      >
        <v-btn
          icon
          outlined
          dark
          max-height="24"
          max-width="24"
          class="close-dialog-icon mr-1"
          @click="newsDialog = false"
        >
          <v-icon
            size="18"
            color="white"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <v-card
        class="bg rounded-lg"
        height="356"
      >
        <v-container
          class="ma-0 h-100 p-relative"
          fluid
        >
          <v-row dense>
            <!-- 上一則 -->
            <v-col
              cols="12"
              class="d-flex py-0"
            >
              <div
                v-show="activeNews > 0"
                class="prev"
              >
                <v-btn
                  class="card2"
                  @click="prevNews"
                >
                  <Icon
                    data="@icon/double-arrows-left.svg"
                    width="20"
                    height="20"
                    class="primary--text mr-1"
                  />
                </v-btn>
              </div>

              <v-spacer />

              <!-- 下一則 -->
              <div class="next">
                <v-btn
                  :text="!layoutNotTemplate3OrTemplate4"
                  :class="{ card2: layoutNotTemplate3OrTemplate4 }"
                  @click="nextNews"
                >
                  <span
                    class="mr-1 caption"
                    :class="layoutNotTemplate3OrTemplate4 ? 'primary--text' : 'text--text'"
                  >
                    {{ $t('global.nextItem') }}
                  </span>
                  <Icon
                    data="@icon/double-arrows-right.svg"
                    width="20"
                    height="20"
                    class="mr-1"
                    :class="layoutNotTemplate3OrTemplate4 ? 'primary--text' : 'text--text'"
                  />
                </v-btn>
              </div>
            </v-col>

            <v-col
              cols="12"
              class="py-0 px-6"
            >
              <div
                v-if="layoutNotTemplate3OrTemplate4"
                class="mt-3 text-center"
              >
                <!-- <Icon
                  data="@icon/bell.svg"
                  width="24"
                  height="24"
                  class="secondary--text mr-1"
                /> -->
              </div>
              <div
                v-else
                class="subtitle-1 text-center font-weight-bold"
                :class="[ layout === 'template3' && theme === 'light' ? 'title--text' : 'secondary--text' ]"
              >
                {{ $t('global.notice') }}
              </div>

              <div
                class="news-title subtitle-1 font-weight-medium mt-5 text-overflow"
                :class="[ layout === 'template3' && theme === 'light' ? 'primary--text' : 'title--text' ]"
              >
                {{
                  news[activeNews].title
                }}
              </div>

              <div
                class="news-contnet caption mt-4 text-content-overflow fix-img-size fix-img-height"
                :class="{ 'text--text': !layoutNotTemplate3OrTemplate4 }"
              >
                <div v-html="news[activeNews].content" />
                <!-- {{
                  news[activeNews].content
                }} -->
              </div>
            </v-col>
          </v-row>

          <!-- 不再顯示 -->
          <div class="p-absolute d-flex align-center not-remember w-100 px-4">
            <v-checkbox
              v-model="notRememberMe"
              class="primary--text"
              dense
            >
              <div
                slot="label"
                class="caption"
                :class="layoutNotTemplate3OrTemplate4 ? 'primary--text' : 'text--text'"
              >
                {{ $t('global.DontShowAgainToday') }}
              </div>
            </v-checkbox>

            <template
              v-if="!layoutNotTemplate3OrTemplate4"
            >
              <v-spacer />
              <v-btn
                class="caption"
                color="primary"
                @click="newsDialog = false"
              >
                {{ $t('global.gotIt') }}
              </v-btn>
            </template>
          </div>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- app 無法開啟 dialog -->
    <v-dialog
      v-model="cantOpenAppDialog.status"
      class="cant-open-app-dialog"
      overlay-opacity=".4"
      :max-width="280"
    >
      <!-- 關閉 dialog btn -->
      <div class="text-right mb-3">
        <v-btn
          icon
          outlined
          dark
          max-height="24"
          max-width="24"
          class="close-dialog-icon mr-1"
          @click="cantOpenAppDialog.status = false"
        >
          <v-icon
            size="18"
            color="white"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <!-- 長按保存圖片 -->
      <div class="text-center">
        <v-snackbar
          v-model="snackbar"
          centered
          class="snackbar"
          :timeout="timeout"
        >
          {{ $t('service.longPressThePictureToSave') }}
        </v-snackbar>
      </div>

      <v-card class="bg rounded-lg p-relative">
        <v-container
          class="ma-0 h-100 p-relative"
          fluid
        >
          <v-row>
            <v-col
              cols="12"
              class="pa-0 p-relative"
            >
              <img :src="baseImgUrl + csProfile.backup_domains_image">

              <!-- ACTIONS -->
              <div class="d-flex actions p-absolute w-100 px-6">
                <div class="flex-grow-1 mr-2">
                  <v-btn
                    block
                    class="card2"
                    @click="snackbar = true"
                  >
                    {{ $t('global.save') }}
                  </v-btn>
                </div>

                <div class="flex-grow-1">
                  <v-btn
                    block
                    color="primary"
                    class="btnText--text"
                    @click="copyText('copyText')"
                  >
                    {{ $t('global.copy') }}
                  </v-btn>

                  <!-- 復制文字 (hidden) -->
                  <input
                    id="copyText"
                    type="text"
                    :value="csProfile.backup_domains_text"
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- fixed區塊(下載app/navbar/fifaWorldCup):不吃api資料,前端寫死 -->
    <div
      ref="fixedAreaRef"
      class="fixed-area bg"
    >
      <DownloadApp
        v-show="downloadAppDsp"
        ref="downloadApp"
        @close-download-app="closeDownloadApp"
      />
      <Navbar v-if="layoutIsTemplate1OrTemplate5" />
      <UserInfo v-if="layoutNotTemplate7" />
      <Marquee v-if="layoutNotTemplate7" />
      <!-- <FifaWorldCup v-show="worldCupAreaDisplay" /> -->
    </div>

    <!-- 後台首頁版型配置的component元件區塊:吃api資料,動態渲染 -->
    <div
      class="fix-component-wrapper bg"
    >
      <!-- 需fix置頂的動態元件(目前有:banner(僅限版七)/maraquee/UserInfo) -->
      <component
        :is="item.block"
        v-for="(item, index) in fixLayoutArea"
        ref="apiFixComponent"
        :key="item.block + `-${index}`"
        :data="item"
      />
    </div>

    <div
      v-if="isParentRendered"
      class="component-wrapper"
      :style="{'padding-top':`${apiFixComponentsHeight}px`}"
    >
      <!-- 一般動態元件 -->
      <component
        :is="item.block"
        v-for="(item, index) in layoutArea"
        :key="item.block + `-${index}`"
        :data="item"
        :api-fix-components-height="apiFixComponentsHeight"
        :fix-layout-height="fixLayoutHeight"
        :is-parent-rendered="isParentRendered"
        :layout-area-length="layoutAreaLength"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mobileDevice } from '../../utils/browserCheck'
import { timezone } from '@/utils/day'
import { setCookie, getCookie } from '../../utils/cookie'
import { copy } from '@/utils/copy'
import { curryTemplate } from '../../utils/template'
import { detectDevIsLineLiffMode } from '../../utils/liff'

import UserInfo from './components/UserInfo'
import Marquee from './components/Marquee.vue'
import Navbar from './components/Navbar.vue'
import DownloadApp from './components/DownloadApp.vue'
import BootomNav from '@/components/base/BottomNav'
import Dialog from '@/components/dialog/index.vue'

// forEach Components
import AllHomeAreaComponents from './components/index.js'
import { resStatus } from '../../utils/resUtils'
import FifaWorldCup from './components/FifaWorldCup'

export default {
  name: 'Home',
  components: {
    Dialog,
    DownloadApp,
    UserInfo,
    Marquee,
    BootomNav,
    FifaWorldCup,
    Navbar,
    ...AllHomeAreaComponents,
  },

  data: () => ({
    downloadAppHeight: 0, // downloadApp元件高度
    apiFixComponents: ['maraquee', 'UserInfo'],
    fixLayoutHeight: 0, // 寫死fix區塊總高度(下載app/navbar/fifaWorldCup)
    apiFixComponentsHeight: 0, // 需fix的動態元件總高度(maraquee/UserInfo)
    device: null,
    dialog: {
      status: false,
    },

    form: {
      timezone: '',
      page: 1,
    },

    // download APP dispaly
    downloadAppDsp: true,

    news: [
      {
        content: '--',
        start_datetime: '--',
        title: '--',
      },
    ],
    activeNews: 0,
    newsDialog: false,

    notRememberMe: false,

    // 無法開啟 app dialog 狀態
    cantOpenAppDialog: {
      status: false, // 顯示狀態
    },

    // 長按保存到本地 popup
    snackbar: false,
    timeout: 2000,
    isParentRendered: false,
  }),

  computed: {
    ...mapGetters(['websiteName', 'baseImgUrl', 'currentDevice', 'layout', 'theme']),

    ...mapState({
      layoutComponent: state => state.indexPageLayoutArea,
      isLogin: state => state.user.isLogin,
      csProfile: state => state.websiteData.cs_profile,
      // 是否顯示世界盃賽程區塊
      worldCupAreaDisplay: state => state?.websiteData?.worldcup_info?.worldcup_enable || false,
      // 判斷後台有無APP參數顯示APP Download 提示
      appDownloadType: state => state.websiteData.app_download_type,
    }),
    fixLayoutArea() {
      return this.layoutComponent.filter(l => this.apiFixComponents.includes(l.type))
    },
    layoutArea() {
      return this.layoutComponent.filter(l => !this.apiFixComponents.includes(l.type))
    },
    layoutAreaLength() {
      return this.layoutArea.length > 1
    },
    layoutNotTemplate3OrTemplate4() {
      if (this.layout === 'template3' || this.layout === 'template4') return false
      return true
    },
    layoutNotTemplate7() {
      return this.layout !== 'template7'
    },

    layoutIsTemplate1OrTemplate5() {
      if (this.layout === 'template1' || this.layout === 'template5' || this.layout === 'template6' || this.layout === 'template7') return true
      return false
    },

    allDevice() {
      return navigator.userAgent
    },

    dontShowAginCookie() {
      return getCookie('dontShowNewsToday') === 'true'
    },
    homePaddingTopClass() {
      // 目前棄用,改用ref直接抓元件總高度,就無需每種條件都要重新定義padding-top
      // 因為版七沒有引入UserInfo,所以針對版七的padding-top都要重新定義樣式
      if (this.downloadAppDsp && !this.worldCupAreaDisplay && this.layout === 'template7') return 'pt-137'
      else if (!this.downloadAppDsp && this.worldCupAreaDisplay && this.layout === 'template7') return 'pt-164'
      else if (this.downloadAppDsp && this.worldCupAreaDisplay && this.layout === 'template7') return 'pt-200'
      else if (!this.downloadAppDsp && !this.worldCupAreaDisplay && this.layout === 'template7') return 'pt-52'
      else if (this.downloadAppDsp && !this.worldCupAreaDisplay) return 'pt-196'
      else if (!this.downloadAppDsp && this.worldCupAreaDisplay) return 'pt-184'
      else if (this.downloadAppDsp && this.worldCupAreaDisplay) return 'pt-240'
      else return 'pt-140'
    },

    // 判斷是否為 liff 模式
    isLiffMode() {
      return detectDevIsLineLiffMode() || window.liff.isInClient()
    },
  },

  watch: {
    device(isNotBlankWindowBrowser) {
      if (!isNotBlankWindowBrowser) return false
      if (sessionStorage.getItem('safariDialog')) return false
      if (detectDevIsLineLiffMode() || window.liff.isInClient()) return false
      this.dialog.status = true
    },

    /**
     * 無法安裝 app 彈窗 狀態監聽
     * 若關閉 -> 儲存 cookie (今日不再顯示)
     * @date 2021-10-25
     * @param {boolean} newStatus 最新狀態
     */
    'cantOpenAppDialog.status'(newStatus) {
      if (!newStatus) this.dialogNotDispalyToday('cantOpenAppDialogStatus')
    },

    /**
     * 最新消息 彈窗 狀態監聽
     * 若關閉 且 今日不再顯示勾選 -> set cookie
     * @date 2021-10-25
     * @param {boolean} newStatus 最新狀態
     */
    newsDialog(newStatus) {
      if (!newStatus && this.notRememberMe) this.dialogNotDispalyToday('dontShowNewsToday')
    },

    'dialog.status'(newStatus) {
      if (newStatus) return false
      sessionStorage.setItem('safariDialog', 'false')
    },
  },
  created() {
    if (this.layout === 'template7') this.apiFixComponents.push('banner')
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.isParentRendered = true
        setTimeout(() => {
          this.$refs.hiddenButton.click() // 為了正確取得banner高度,需做一個更新觸發
          this.getFixLayoutHeight() // 取得寫死fix區塊總高度(下載app/navbar/fifaWorldCup)
          this.getApiFixComponentsHeight() // 取得需fix的動態元件總高度(banner/maraquee/UserInfo)
          if (this.$refs.downloadApp) this.downloadAppHeight = this.$refs.downloadApp.$el.offsetHeight || 53 // 取得下載元件高度
        }, 500)
      }, 1000)

      // 监听窗口大小变化事件
      window.addEventListener('resize', this.getFixLayoutHeight)
      window.addEventListener('resize', this.getApiFixComponentsHeight)
      window.addEventListener('resize', this.getFixLayoutHeight)
    })
    this.device = mobileDevice()

    this.checkDwonloadDspSession()

    this.form.timezone = timezone()

    if (this.isLogin) this.getNewsData()

    // webview 不顯示下載 app
    this.$log(`webview: ${!this.checkWebView()}`)
    this.downloadAppDsp = this.checkWebView()

    // Line Liff 模式 [或] 後台設置 app_download_type 提示，不顯示下載 app
    if (this.isLiffMode || this.appDownloadType === 'none') {
      this.downloadAppDsp = false
    }
  },
  methods: {
    ...mapActions(['get_news_api', 'show_alert']),
    checkWebView() {
      const webview = navigator.userAgent.indexOf('platform')
      const ios = navigator.userAgent.indexOf('iOS')
      const android = navigator.userAgent.indexOf('android')

      if (webview > -1 && ios > -1) return false
      if (webview > -1 && android > -1) return false
      else return true
    },

    getApiFixComponentsHeight() {
      if (!this.$refs.apiFixComponent) return
      let acc = 0
      this.$refs.apiFixComponent.forEach((element) => {
        // console.log(element.$el)
        // console.log('元件高度：', element.$el.offsetHeight)
        acc += element.$el.offsetHeight
      })
      this.apiFixComponentsHeight = acc
      this.$forceUpdate()
    },
    getFixLayoutHeight() {
      if (!this.$refs.fixedAreaRef) return
      this.fixLayoutHeight = this.$refs.fixedAreaRef.offsetHeight
      this.$forceUpdate()
    },
    /**
     * 即下載 區塊顯示 (關閉)
     * @date 2021-08-27
     */
    closeDownloadApp() {
      this.downloadAppDsp = false
      sessionStorage.setItem('downloadAppDsp', JSON.stringify(this.downloadAppDsp))
      this.fixLayoutHeight = this.fixLayoutHeight - this.downloadAppHeight // fix區塊總高度需扣除下載元件高度
    },
    setSafariDialogStatus(newStatus) {
      this.dialog.status = newStatus
    },

    /**
     * 立即下載 區塊顯示 設置
     * @date 2021-08-27
     */
    checkDwonloadDspSession() {
      const downloadAppDspStatus = sessionStorage.getItem('downloadAppDsp')

      if (!downloadAppDspStatus) sessionStorage.setItem('downloadAppDsp', JSON.stringify(this.downloadAppDsp))
      else this.downloadAppDsp = JSON.parse(downloadAppDspStatus)
    },

    /**
     * 下載應用程式 && 復製 website namne
     * @date 2021-06-21
     * @param {string} appUrl app 下載連結
     */
    downloadApp(appUrl) {
      this.copyNumber('websiteName')
      location.href = appUrl
    },
    getNewsDataFail() {},
    getNewsDataSuccess(data) {
      const news = data.news.data

      //  如果有圖片，才會顯示 彈窗 @備用 url 彈窗
      if (this.csProfile.backup_domains_image && !getCookie('cantOpenAppDialogStatus')) this.cantOpenAppDialog.status = true

      if (news.length > 0) this.news = news
      if (news.length > 0 && !getCookie('dontShowNewsToday')) this.newsDialog = true
    },

    /**
     * 取得最新消息
     * @date 2021-10-13
     */
    async getNewsData() {
      const res = await this.get_news_api(this.form)
      resStatus(res, this.getNewsDataSuccess, this.getNewsDataFail)
    },

    /**
     * 上一則
     * @date 2021-10-01
     */
    prevNews() {
      if (this.activeNews === 0) return false
      this.activeNews--
    },

    /**
     * 下一則
     * @date 2021-10-01
     */
    nextNews() {
      if (this.activeNews + 1 === this.news.length) return false
      this.activeNews++
    },

    /**
     * 復制文字功能
     * @date 2021-04-07
     * @param {string} id 需要復製的文字 id
     */
    copyText(id) {
      try {
        copy(id)
        this.show_alert({
          icon: 'done',
        })
      } catch {
        this.show_alert({
          icon: 'fail',
        })
      }
    },

    /**
     * 今日不再顯示 (當日晚上 23:59:59)
     * @date 2021-10-22
     */
    dialogNotDispalyToday(item) {
      const nowDate = this.$day()
      document.cookie = `${item}=false; expires=${new Date(nowDate.year(), nowDate.month(), nowDate.date(), 23, 59, 59).toGMTString()}`
    },
  },
}
</script>

<style lang="scss" scoped>
.fixed-area {
	position: fixed;
	top: 0;
	z-index: 2;
	width: 100%;
}

.pt-196 {
	padding-top: 196px;
}
.pt-240 {
  padding-top: 240px;
}

.pt-84 {
  padding-top: 84px;
}

.pt-140 {
	padding-top: 140px;
}
.pt-184 {
  padding-top: 184px;
}
.pt-200{
  padding-top: 200px;
}
.pt-137{
  padding-top: 137px;
}
.pt-164{
  padding-top: 164px;
}
.pt-52{
  padding-top: 52px;
}

.not-remember {
	bottom: 0;
	left: 0px;
}

#copyText {
	position: absolute;
	height: 0;
}

img {
	width: 100%;
	object-fit: cover;
}

.actions {
	bottom: 16px;
	left: 0;
}

.text-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-content-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.fix-img-height{
  max-height:170px;
  overflow-y:auto;
  >div{
    display: flex;
    flex-direction: column;
  }
}
</style>
<style >
/* bonus>item.vue 和 user>news>template>itemTemplate1.vue有寫，重構時需另外拉出 */
.fix-img-size img{
  max-width: 100% !important;
  height: auto !important;
}
.hidden-btn{
    visibility: hidden;
    position: absolute;
    pointer-events: none;
}
.fix-component-wrapper{
    position: fixed;
    left: 0;
    z-index:1;
    width: 100%;
}
.component-wrapper{
    z-index: 0;
    position: relative;
}
</style>
