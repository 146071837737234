import store from '../store/index'
import Vue from '../main'

export const copy = id => {
  const el = document.querySelector(`#${id}`)
  const textRange = document.createRange()
  textRange.selectNode(el)
  const sel = window.getSelection()
  sel.removeAllRanges()
  sel.addRange(textRange)
  document.execCommand('copy')
  sel.removeAllRanges()
}

export const copyNumber = id => {
  try {
    copy(id)
    store.dispatch('show_alert', {
      // 復製成功
      text: `${Vue.$t('global.copy') + Vue.$t('global.success')}`,
    })
  } catch {
    store.dispatch('show_alert', {
      icon: 'fail',
    })
  }
}
