import {
  getWalletListAPI,
  changeWalletModeAPI,
  walletTransferAPI,
  retrieveGameWalletsAPI,
  eventwalletDetailsAPI,
  unlockEventAwardsAPI,
} from '@/api'
import axios from 'axios'

const state = () => ({
  wallert: '123',
})

const getters = {}

const mutations = {}

const actions = {
  async get_user_wallet_list() {
    const res = await getWalletListAPI()
    return res
  },

  async set_user_wallet_mode({ commit }, wallet_type) {
    const res = await changeWalletModeAPI(wallet_type)
    return res
  },

  async user_transfer_wallet(context, form) {
    const res = await walletTransferAPI(form)
    return res
  },

  async user_retrieve_all_wallet_balance() {
    const res = await retrieveGameWalletsAPI()
    return res
  },

  async multiple_wallet_transfer_to_main_wallet(context, transferWalletList) {
    const ary = []
    transferWalletList.forEach(item => {
      ary.push(walletTransferAPI(item))
    })
    const res = await axios.all(ary)

    return res
  },
  async event_wallet_details() {
    const res = await eventwalletDetailsAPI()
    return res
  },
  async unlock_event_awards(context, wallet_code) {
    const res = await unlockEventAwardsAPI(wallet_code)
    return res
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
