<template>
  <div class="pt-56 h-100dvh over-flow-y-scroll text-center">
    <v-img
      :src="imgUrl"
    />
    <v-btn
      color="primary"
      class="mt-4 mb-10 font-weight-bold"
      x-large
      @click="goGiftPage"
    >
      兌換
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getGiftImageAPI } from '@/api'
import isLocalDev from '@/utils/isLocalDev'

export default {
  data: () => ({
    imgUrl: null,
    giftUrl: null,
  }),

  mounted() {
    this.set_toolbar_title('禮物兌換')
    this.getGiftData()
  },

  methods: {
    ...mapActions(['set_toolbar_title']),

    // website 設定
    set_website() {
    // 是否本地開發
      const website = isLocalDev()
        ? process.env.VUE_APP_WEBSITE
        : location.host
          .replace('m.', '')
        // .replace('www.', '')
          .replace('v2.', '')
      return website
    },

    async getGiftData() {
      const {
        data: {
          gift_shop_image,
          image_url,
          gift_shop_url,
        },
      } = await getGiftImageAPI(this.set_website())

      this.imgUrl = image_url + gift_shop_image
      this.giftUrl = gift_shop_url
    },

    goGiftPage() {
      location.href = this.giftUrl
    },
  },
}
</script>

<style lang="scss" scoped>
.pt-56 {
  padding-top: 56px;
}
</style>
