<template>
  <div class="vh-100 d-flex flex-column justify-center align-center px-10">
    <div class="text-center">
      <Icon
        data="@icon/alert/error.svg"
        width="60"
        height="60"
        color="red"
      />
      <h1>403</h1>
      <span>Forbidden</span>
    </div>

    <div
      class="text-center caption my-5"
    >
      很抱歉，站台不再提供服务
      <br>
      {{ errorText }}
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    /**
     * 錯誤信息文字
     * @date 2021-09-08
     */
    errorText() {
      return 'This site is no longer in service'
    },

  },
}
</script>

<style lang="scss" scoped></style>
