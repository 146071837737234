<template>
  <!-- server 錯誤 500 -->
  <div class="vh-100 d-flex flex-column justify-center align-center px-12">
    <div class="text-center">
      <Icon
        data="@icon/alert/error.svg"
        width="60"
        height="60"
        color="red"
      />
      <h1>500</h1>
      <span>Internal Server Error</span>
    </div>

    <div
      class="text-center caption my-5"
    >
      {{ errorText }}
      <span
        class="primary--text cursor-pointer"
        @click="toServicePage"
      >{{ $t('flashMessage.contactCustomerService') }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      serviceUrl: state => state.serviceUrl,
      // 來自 api 的客服設定
      fromApiAboutService: state => state.websiteData.cs_settings,
    }),
    /**
     * 錯誤信息文字
     * @date 2021-09-08
     */
    errorText() {
      return this.$t('flashMessage.500')
    },
    /**
     * 判斷是否依 api 取得客服 url
     * @date 2021-12-28
     * @returns {boolean} 是否打 api 取得客服 url
     */
    isChatboxUrl() {
      return this.fromApiAboutService.cs_platform_type === 'chatbox'
    },
  },
  mounted() {
    if (this.isChatboxUrl) this.getServiceUrl()
  },
  methods: {
    ...mapActions(['get_service_url',
      'show_alert']),
    /**
     * 取得客服連結 (失敗)
     * @date 2021-08-17
     */
    getServiceUrlFail() {
      this.show_alert({ icon: 'fail' })
    },

    /**
     * 取得客服連結
     * @date 2021-08-17
     */
    getServiceUrl() {
      this.get_service_url()
    },

    async toServicePage() {
      const httpChk = (url) => {
        const hadHttps = url.includes('https://')
        const hadHttp = url.includes('http://')
        if (!hadHttps && !hadHttp) {
          // 網址沒有https和http，需補上http://
          return `http://${url}`
        }
        return url
      }
      // 在線客服系统类型
      if (this.fromApiAboutService.cs_platform_type !== 'revechat') {
        if (this.fromApiAboutService.cs_platform_type === 'chatbox') {
          // 前往 在線客服
          return this.serviceUrl
            ? window.open(httpChk(this.serviceUrl), '_blank')
            : this.getServiceUrlFail()
        } else {
          // 前往 直接連結
          return this.fromApiAboutService.cs_direct_url
            ? window.open(httpChk(this.fromApiAboutService.cs_direct_url), '_blank')
            : this.getServiceUrlFail()
        }
      }
      // 前往 revechat
      const url = this.serviceUrl
      // console.log(url)
      // window.open(httpChk(url), '_blank')
      window.webkit.messageHandlers.openCustomService.postMessage(url)
    },
  },
}
</script>

<style lang="scss" scoped></style>
