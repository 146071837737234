<template>
  <v-bottom-navigation
    v-model="navIndex"
    fixed
    grow
    :height="navHeight"
    class="bottom-nav"
  >
    <template v-for="(item, index) in navBarData">
      <v-btn
        v-if="item.path !== '/service'"
        :key="index"
        :color="matchRouteName(item.path, index)?item.bgcolor2:item.bgcolor"
        :height="navHeight"
        :to="item.path"
      >
        <span
          v-if="layout==='template7'"
          class="nav-text grey-text"
          :class="[
            matchRouteName(item.path, index)
              ? 'primary--text'
              : '',
          ]"
        >{{ item.title }}</span>
        <img
          :src="matchRouteName(item.path, index) ? baseUrl + item.icon_active : baseUrl + item.icon"
          height="56"
        >
      </v-btn>
      <!-- 客服模式會根據是否為 line liff 模式而不同動作
        - 一般模式：點擊後跳轉至後台串接的客服系統
        - LineLiff 模式：點擊後浮出 Line 按鈕
      -->
      <v-btn
        v-else
        :key="index"
        :color="matchRouteName(item.path, index)?item.bgcolor2:item.bgcolor"
        :height="navHeight"
        @click="showServiceArea(item.path)"
      >
        <template v-if="isLiffMode">
          <img
            :src="baseUrl + item.icon"
            height="56"
          >
        </template>
        <template v-else>
          <span
            v-if="layout==='template7'"
            class="nav-text grey-text"
            :class="[
              matchRouteName(item.path, index)
                ? 'primary--text'
                : '',
            ]"
          >{{ item.title }}</span>
          <img
            :src="matchRouteName(item.path, index) ? baseUrl + item.icon_active : baseUrl + item.icon"
            height="56"
          >
        </template>

        <!-- Service Items -->
        <div
          v-show="serviceArea.status"
          class="service-items-area text-left"
        >
          <ul class="pl-0">
            <li
              v-for="(service, idx) in serviceItems"
              :key="service + idx"
              class="my-3 cursor-pointer"
              @click.stop="goService(service.name)"
            >
              <div v-if="service.name === 'Line' && showLineService || service.name === '24h'">
                <v-avatar
                  color="white"
                  size="40"
                >
                  <img
                    class="pa-2"
                    :src="service.icon"
                    alt="line-service"
                  >
                </v-avatar>
                <span class="caption ml-2 white--text">
                  {{ service.text }}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </v-btn>
    </template>
    <v-overlay
      :value="serviceArea.status"
      opacity=".9"
    />
  </v-bottom-navigation>
</template>

<script>
import bottomModeMixins from '../mixins/bottomNavModeMixins'

export default {
  mixins: [bottomModeMixins],
}
</script>

<style lang="scss" scoped>
.websiteLogo {
  transform: translateY(-10px);
}

.wh-56px {
  height: 56px;
  width: 56px;
}

.wh-38px {
  height: 38px;
  width: 38px;
}

.service-items-area {
  position: absolute;
  bottom: 72px;
  left: 0;
  z-index: 99;

  >ul {
    list-style: none;
  }
}

.v-btn {
  width: auto;
}
</style>
