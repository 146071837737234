/* eslint-disable no-trailing-spaces */
import store from '../store'
import { resStatus } from './resUtils'
import router from '../router'

// 解析 liff url query to obj
export const parseUrlQuery = (locationSearch) => {
  let liffId = null
  let agentCode = null
  let log = null

  // console.log(deviceCheck())

  let liffState = new URLSearchParams(locationSearch).get('liff.state')

  if (liffState) {
    // 如果有 /?
    if (liffState.indexOf('/?') > -1) {
      liffState = liffState.replace('/?', '')
    }
    liffId = new URLSearchParams(liffState).get('liff_id')
    agentCode = new URLSearchParams(liffState).get('agent_code')
    log = new URLSearchParams(liffState).get('log')
  } else {
    liffId = new URLSearchParams(locationSearch).get('liff_id')
    agentCode = new URLSearchParams(locationSearch).get('agent_code')
    log = new URLSearchParams(locationSearch).get('log')
  }

  return { liff_Id: liffId, agent_code: agentCode, log }
}

// 取得使用者 id token
export const getLiffIDToken = async({ liff_Id, agent_code }, routerBeforeNextFunc) => {
  const idToken = window.liff.getIDToken()
  // Liff ID && AGENT CODE
  console.log(`Liff ID: ${liff_Id} / Agent Code: ${agent_code}`)
  if (idToken) {
    const loginData = {
      id_token: idToken,
      website: await store.dispatch('set_website'),
    }
    // 如果有 agent code 的話，帶入登入資料
    if (agent_code) {
      loginData.agent_code = agent_code
    }

    // 使用者登入
    const res = await store.dispatch('line_login', loginData)
    await resStatus(res, liffLoginSuccess, liffLoginFail)

    // 取得 line 使用者資料
    await getUserData()
    console.log('NEXT')
    store.dispatch('set_progress_status', false)
    routerBeforeNextFunc()
  }
}

export const getUserData = () => {
  window.liff
    .getProfile()
    .then((profile) => {
      store.dispatch('set_line_user_data', profile)
    })
    .catch((err) => {
      console.log('error', err)
    })
}

export const liffLoginSuccess = (data) => {
  store.commit('SET_COMPLETED_BASEINFO', data.member.completed_baseinfo === 'Y')
  const isLineLiff = (process.env.NODE_ENV === 'local' && process.env.VUE_APP_LINE_LIFF_MODE === 'true') || window.liff.isInClient()

  if (isLineLiff && data.member.completed_baseinfo === 'N') {
    setTimeout(() => {
      // 請完善個人資料
      store.dispatch('show_alert', {
        icon: 'fail',
        text: '請完善個人資料',
      })
    }, 1500)
    router.push('/user/personal')
  }

  store.dispatch('set_user_data', data).then(async() => {
    // 取得使用者錢包
    const walletcode = { walletcode: 'player_balance' }
    await store.dispatch('get_user_balance', walletcode)
    store.commit('SET_USERINFO_BALANCE_PROGRESS_STATUS', false)
  })
}

export const liffLoginFail = () => {
  window.liff.logout().then(() => window.liff.login())
}

/**
 * 偵測在開發模式下是否為 LINE LIFF 模式
 * VUE_APP_LINE_LIFF_MODE: 由 settingConfig.js 終端機互動控制
 * @returns {Boolean} true: 開發模式且選擇 LINE LIFF 或生產環境在 LINE 開啟
 */
export const detectDevIsLineLiffMode = () => {
  return (process.env.VUE_APP_LINE_LIFF_MODE === 'true' && process.env.NODE_ENV === 'local')
}
