import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import { copyNumber } from '@/utils/copy'
import QrcodeVue from 'qrcode.vue'
import html2canvas from 'html2canvas'

export default {
  components: {
    QrcodeVue,
  },

  data: () => ({
    recommend: {
      bonus: '--',
      settlement: '--',
      total: '--',
    },

    qrCodeCanvasImgUrl: null,
  }),

  computed: {
    ...mapState({
    // 使用者推薦碼
      recommendCode: (state) => state.user.userData.recommend_code,
    }),
    ...mapGetters(['currencySymbol', 'theme', 'layout', 'currencySymbol_']),

    /**
     * 註冊頁面 query 推薦碼
     * @date 2021-06-01
     */
    recommendResgisterUrl() {
      const url = window.location.origin

      const { href } = this.$router.resolve({
        name: 'register',
        query: {
          recommend: this.recommendCode,
        },
      })

      return `${url}${href}`
    },

    /**
     * 推薦相關紀錄 (推薦禮金 / 返利奖励 / 返利奖励)
     * item 為 api 的指定項目 (this.recommend)
     * @date 2021-06-01
     * @returns {array}
     */
    recommendItem() {
      return [
        {
          // 推薦禮金
          title: this.$t('myCenter.recommendBonus') + ' ' + `(${this.currencySymbol_})`,
          // 說明文字
          description: this.$t('myCenter.totalAmountofIssued'),
          // API 項目
          item: 'bonus',
        },
        {
          // 返利奖励
          title: this.$t('myCenter.returnBonus') + ' ' + `(${this.currencySymbol_})`,
          // 已结算总金额
          description: this.$t('myCenter.totalAmountofSettled'),
          // API 項目
          item: 'settlement',
        },
        {
          // 返利奖励
          title: this.$t('myCenter.returnBonus') + ' ' + `(${this.currencySymbol_})`,
          // <!-- 未结算总金额 -->
          description: this.$t('myCenter.totalAmountofUnsettled'),
          // API 項目
          item: 'total',
        },
      ]
    },
  },

  mounted() {
    this.set_toolbar_title(this.$t('myCenter.recommendBonus'))
    this.getUserRecommend()
    this.createDownloadImg()
  },

  methods: {
    ...mapActions(['set_toolbar_title', 'get_user_recommend']),

    countTextColor(itemType) {
      if (itemType === 'total') return 'text--text'

      return this.theme === 'dark'
        ? 'secondary--text'
        : 'primary--text'
    },

    /**
     * 取得玩家推薦禮金概要(成功)
     * @date 2021-05-31
     * @param {any} {recommand_bonus} 推薦禮金資料
     */
    getUserRecommendSuccess({ recommand_bonus }) {
      this.recommend = recommand_bonus
    },

    /**
     * 取得玩家推薦禮金概要
     * @date 2021-05-31
     * @returns {object} 玩家推薦禮金資料
     */
    async getUserRecommend() {
      const res = await this.get_user_recommend()
      resStatus(res, this.getUserRecommendSuccess)
    },

    /**
     * 復製文字
     * @date 2021-06-01
     * @param {string} id 需要復製文字的 input id
     */
    copyNumber(id) {
      copyNumber(id)
    },

    /**
     * 將 qrcode 區塊(canvas 的元素) 轉為 jpg 格式 寫入 data
     * @date 2021-08-12
     */
    async createDownloadImg() {
      const qrcodeImg = await this.html2Canvas('.qrcode')
      const qrCodeCanvasImgUrl = qrcodeImg.toDataURL('image/jpeg')

      this.qrCodeCanvasImgUrl = qrCodeCanvasImgUrl
    },

    /**
     * html 轉為 CANVAS, 且將元素插入 document (不顯示)
     * @date 2021-08-12
     * @param {any} qrcodeDomClassName
     * @returns {dom} 轉為 canvas 的元素
     */
    async html2Canvas(qrcodeDomClassName) {
      const canvas = await html2canvas(document.querySelector(qrcodeDomClassName))
      canvas.classList.add('qrcode-canvas-img')
      document.querySelector('.d-none').appendChild(canvas)
      canvas.classList.add('d-none')
      return document.querySelector('.qrcode-canvas-img')
    },

  },
}
