<template>
  <div class="userInfo user bg d-flex align-center px-4 p-relative user-info">
    <!-- 使用者區塊 -->
    <div class="user-area">
      <!-- 如果尚未登入 -->
      <template v-if="(!isLogin)">
        <!-- 如果 liff 不顯示登入 btn -->
        <div class="no-login-block grey-text">
          <router-link
            v-if="!isLiffMode"
            to="/login"
            class="title--text"
          >
            您还未登录
          </router-link><br>
          <span class="caption">
            <router-link
              v-if="!isLiffMode"
              to="/login"
            >{{ $t('subHeader.login') }}</router-link>
            <span>/</span>
            <router-link
              v-if="registerStatus"
              to="/register"
            >{{ $t('subHeader.register') }}</router-link>后查看
          </span>
        </div>
      </template>

      <!-- 如果登入 -->
      <template v-else>
        <div class="pl-0 login-block grey-text">
          <!-- liff 模式 -->
          <template v-if="isLiffMode">
            <!-- 有頭像 -->
            <template v-if="lineAvatar">
              <router-link to="/user">
                <v-avatar>
                  <img
                    size="20"
                    :src="lineAvatar"
                    :alt="lineDisplayName"
                  >
                </v-avatar>
              </router-link>
            </template>

            <!-- 無頭像 -->
            <template v-else>
              <Icon
                v-show="!lineIsLogin"
                data="@icon/user.svg"
                width="24"
                height="24"
                class="icon--text"
              />
            </template>
            <span class="title--text caption ml-2">
              {{ lineDisplayName }}
            </span>
          </template>

          <!-- 一般模式 -->
          <template v-else>
            <!-- <Icon
              data="@icon/user.svg"
              width="24"
              height="24"
              class="icon--text"
            />
            <span class="title--text caption ml-2">
              {{ userAccount }}
            </span> -->
            <!-- 使用者錢包 -->
            <div
              class="user-balance d-flex align-center"
            >
              <!-- 取得錢包 loading -->
              <div
                v-if="userBalanceProgressStatus || balanceLoading"
              >
                <v-progress-circular
                  class="mr-4"
                  indeterminate
                  :width="2"
                  :size="20"
                  color="primary"
                />
              </div>

              <!-- (成功) 取得使用者錢包 -->
              <div
                v-else
                style="line-height: 0;"
              >
                <!-- 一鍵回收 -->
                <!-- <v-btn
                  v-if="!overlay && isLogin"
                  icon
                  @click="retrieveAllWalletBalance"
                >
                  <Icon
                    data="@icon/user/walletItem/transfer.svg"
                    width="23"
                    height="20"
                  />
                </v-btn> -->

                <!-- liff 模式可導向 transfer 頁面 -->
                <template v-if="isLiffMode">
                  <div class="d-flex align-center">
                    <router-link
                      to="/user/transfer"
                      class="text-decoration-none"
                    >
                      <span class="mr-1 title--text">
                        {{ currencySymbol_ }}
                      </span>
                      <span class="text-h5 title--text">
                        {{ userBalance }}
                      </span>
                    </router-link>
                  </div>
                </template>

                <!-- 一般模式，僅顯示 -->
                <template v-else>
                  <div class="d-flex align-center">
                    <span class="mr-1 title--text">
                      {{ currencySymbol_ }}
                    </span>
                    <span class="text-h5 title--text">
                      {{ userBalance }}
                    </span>
                  </div>
                  <span
                    class="caption"
                  >{{ $t('myCenter.mainWallet') }}</span>
                </template>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>

    <v-spacer />

    <!-- 使用者快捷按鈕 -->
    <div
      class="user-links d-flex align-center"
    >
      <!-- 存款 -->
      <v-btn
        v-if="!isLiffMode"
        text
        height="56"
        class="px-2"
        @click="goPageFunc('user/deposit')"
      >
        <div class="d-flex flex-column align-center justify-center title--text caption">
          <div class="w-h-28 d-flex justify-center align-center">
            <Icon
              data="@icon/user/walletItem/deposit2.svg"
              class="secondary--text"
              width="70"
              height="70"
            />
          </div>
          {{ $t('global.deposit') }}
        </div>
      </v-btn>
      <!-- 取款 -->
      <v-btn
        v-if="!isLiffMode"
        to="user/withdrawal"
        text
        height="56"
        class="px-2"
      >
        <div class="d-flex flex-column align-center justify-center title--text caption">
          <div class="w-h-28 d-flex justify-center align-center">
            <Icon
              data="@icon/user/walletItem/withdrawal2.svg"
              width="70px"
              height="70px"
              class="secondary--text"
            />
          </div>
          {{ $t('global.withdraw') }}
        </div>
      </v-btn>

      <!-- 轉換 -->
      <v-btn
        v-if="!isLiffMode"
        to="user/transfer"
        text
        height="56"
        class="px-2"
      >
        <div
          class="d-flex flex-column align-center justify-center title--text caption"
        >
          <div class="w-h-28 d-flex justify-center align-center">
            <Icon
              data="@icon/user/walletItem/transfer2.svg"
              width="70px"
              height="70px"
              class="secondary--text"
            />
          </div>
          {{ $t('global.transferBtn') }}
        </div>
      </v-btn>

      <!-- VIP -->
      <v-btn
        v-if="!isLiffMode && showVip"
        text
        height="56"
        class="px-2"
        to="user/vip"
      >
        <div class="d-flex flex-column align-center justify-center title--text caption">
          <div class="w-h-28 d-flex justify-center align-center">
            <Icon
              data="@icon/user/walletItem/vip.svg"
              class="secondary--text"
              width="70"
              height="70"
            />
          </div>
          {{ 'vip' }}
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
import userInfoMixin from '../mixins/userinfo'

export default {
  mixins: [userInfoMixin],
}
</script>

<style lang="scss" scoped>
.userInfo {
	height: 56px;
}

.btn-grop {
	right: 0;
	z-index: 99;
}

.w-h-32 {
	width: 32px;
	height: 32px;
}
.w-h-28 {
	width: 28px;
	height: 28px;
}

.w-h-35 {
	width: 35px;
	height: 35px;
}

.thaigi-gradient {
  top: 0;
  right: 0;
  background-image: linear-gradient(110deg, #e6ce71 -5%, #bc954d 20%, #a57539 49%, #c09a50 77%, #e6ce71 100%);
}
</style>
