
import Toolbar from '@/components/base/Toolbar'
import iconComponents from '@/components/icon/joinUs'

import { mapActions, mapGetters, mapState } from 'vuex'
import { copy } from '@/utils/copy'
import { resStatus } from '@/utils/resUtils'

export default {
  components: { Toolbar, ...iconComponents },

  data: () => ({
    sec: 0,
    timeId: null,
  }),

  computed: {
    ...mapState({
      webLogo: state => state.websiteData.site_params.logo,
      theme: state => state.theme,
      contacts: state => state.websiteData.plan.contacts,
      serviceUrl: state => state.serviceUrl,
    }),

    ...mapGetters(['baseImgUrl', 'websiteLogo', 'websiteName', 'theme', 'layout']),

    primaryColor() {
      return this.$vuetify.theme.themes[this.theme]?.primary || ''
    },

    joinUsItem() {
      return 6
    },
  },

  mounted() {
    this.set_toolbar_title(this.$t('myCenter.joinUs'))
    this.clearTimeId()

    if (this.layout !== 'template1') return false
    // PC
    document.querySelector('#goAdminImg').addEventListener('mousedown', () => this.setTimeToGoAdmin())
    document.querySelector('#goAdminImg').addEventListener('mouseup', () => this.clearTimeId())

    // 行動
    document.querySelector('#goAdminImg').addEventListener('touchstart', () => this.setTimeToGoAdmin())
    document.querySelector('#goAdminImg').addEventListener('touchend', () => this.clearTimeId())
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'show_alert',
    ]),

    imgCheck(socialMediaImgName) {
      let src
      try {
        if (socialMediaImgName === 'qq') {
          src = require(`@/assets/icons/socialMedia/${socialMediaImgName}.webp`)
        } else {
          src = require(`@/assets/icons/socialMedia/${socialMediaImgName}.png`)
        }

        const img = new Image()
        img.src = src
        const imgOK = img.complete && img.naturalWidth !== 0
        return imgOK ? src : null
      } catch {
        console.log(`${socialMediaImgName} 圖片不存在!`)
      }
    },

    /**
     * 復制文字功能
     * @date 2021-04-07
     * @param {string} id 需要復製的文字 id
     */
    copyText(id) {
      try {
        copy(id)
        this.show_alert({
          icon: 'done',
        })
      } catch {
        this.show_alert({
          icon: 'fail',
        })
      }
    },

    setTimeToGoAdmin() {
      this.timeId = setInterval(() => {
        this.sec++
        if (this.sec >= 3) this.toAdmin()
      }, 1000)
    },

    clearTimeId() {
      clearInterval(this.timeId)
      this.sec = 0
    },

    toAdmin() {
      this.clearTimeId()
      document.location.href =
            'https://giocoplus.gf-gaming.com/admin/auth/login'
    },
  },
}
