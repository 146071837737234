// routes 路由設置
import store from '../store'
import Vue from '../main'

import Main from '../views/Main.vue'
import home from '../views/home/index.vue'
import error403 from '../views/error/403.vue'
import error500 from '../views/error/500.vue'
import error503 from '../views/error/503.vue'
import errorTokenExpiredFromTelegram from '../views/error/tokenExpiredFromTelegram.vue'

import bonus from '../views/bonus/index.vue'
import gift from '../views/gift/index.vue'
import bonusItem from '../views/bonus/item.vue'

import App from '../views/app/index.vue'
import Tutorial from '../views/app/Tutorial.vue'
import Agent from '../views/agent/index.vue'
import DtList from '../views/dtList'

import service from '../views/service/index.vue'
import AppStore from '../views/service/AppStore.vue'
import ServiceApp from '../views/service/Service-app.vue'

import Slot from '../views/slot/index.vue'
import Vendor from '../views/vendor/index.vue'
import launchGame from '../views/launchGame/index.vue'
import recommend from '../views/recommend'

import FifaPage from '../views/home/components/FifaWorldCup/page.vue'

const User = () =>
  import(/* webpackChunkName: "user" */ '../views/user/index.vue')
const Personal = () =>
  import(/* webpackChunkName: "user" */ '../views/user/personal')

const Deposit = () =>
  import(/* webpackChunkName: "transaction" */ '../views/user/deposit')
const Transfer = () =>
  import(/* webpackChunkName: "transaction" */ '../views/user/transfer')
const Withdrawal = () =>
  import(/* webpackChunkName: "transaction" */ '../views/user/withdrawal')

const Card = () =>
  import(/* webpackChunkName: "bank" */ '../views/user/card')
const AddCard = () =>
  import(/* webpackChunkName: "bank" */ '../views/user/card/AddCard.vue')

const News = () =>
  import(/* webpackChunkName: "news" */ '../views/user/news')

const NewsItem = () =>
  import(/* webpackChunkName: "news" */ '../views/user/news/item.vue')

const Vip = () =>
  import(/* webpackChunkName: "vip" */ '../views/user/vip')
const Rules = () =>
  import(/* webpackChunkName: "vip" */ '../views/user/vip/Rules')

const record = () =>
  import(/* webpackChunkName: "record" */ '../views/record/index.vue')
const trade = () =>
  import(/* webpackChunkName: "record" */ '../views/record/trade.vue')
const wager = () =>
  import(/* webpackChunkName: "record" */ '../views/record/wager.vue')
const prize = () =>
  import(/* webpackChunkName: "record" */ '../views/record/prize.vue')

const faq = () =>
  import(/* webpackChunkName: "faq" */ '../views/faq/index.vue')
const problem = () =>
  import(/* webpackChunkName: "faq" */ '../views/faq/problem.vue')
const help = () =>
  import(/* webpackChunkName: "faq" */ '../views/faq/help.vue')

const loginIndex = () =>
  import(/* webpackChunkName: "login" */ '../views/login/index.vue')
const login = () =>
  import(/* webpackChunkName: "login" */ '../views/login/login.vue')
const register = () =>
  import(/* webpackChunkName: "login" */ '../views/login/register.vue')

export default [
  {
    // LAYOUT 頁面入口
    path: '/',
    component: Main,
    beforeEnter: async(to, from, next) => {
      // 設置色彩包與主題
      if (!store.state.statusCodeError) {
        store.dispatch('get_website_theme') // 設置主題色
        store.dispatch('get_homepage_layout_area') // 首頁版塊
      }
      next()
    },
    children: [
      {
        // 首頁
        path: '',
        name: 'home',
        component: home,
      },
      {
        path: 'qrcode',
        name: 'qrcode',
        component: () => import('../views/user/qrcode/Reader.vue'),
      },
      {
        // 加入我們
        path: 'agent',
        name: 'agent',
        component: Agent,
        beforeEnter(to, from, next) {
          // 如果 api 不顯示 agent，且無法進此頁
          if (!store.getters.dynamicAgentDisplay) return next({ path: '/' })
          next()
        },
      },
      {
        // 龍虎榜
        path: 'dt-list',
        name: 'dragonTigerList',
        component: DtList,
      },
      // Fifa 賽程頁
      {
        path: 'fifa',
        name: 'fifa',
        component: FifaPage,
      },
      {
        // 登入註冊入口
        path: 'login',
        component: loginIndex,
        children: [
          {
            // 登入
            path: '',
            name: 'login',
            component: login,
            beforeEnter: (to, from, next) => {
              // 如果已經有登入過
              if (store.state.user.isLogin) return next({ path: '/' })
              next()
            },
          },
        ],
      },
      {
        // 註冊入口
        path: 'register',
        name: 'register',
        component: register,
        beforeEnter: (to, from, next) => {
          if (store.state.user.isLogin) {
            // 已經登入
            if (from.name === 'login') return next({ path: '/' })
          }
          next()
        },
      },
      {
        // 優惠
        path: 'bonus',
        name: 'bonus',
        component: bonus,
        meta: { requiresAuth: false },
      },
      {
        // 禮物
        path: 'gift',
        name: 'gift',
        component: gift,
        meta: { requiresAuth: true },
      },
      {
        // 優惠活動項目
        path: 'bonus/:id',
        name: 'bonusItem',
        component: bonusItem,
        meta: { requiresAuth: false },
      },
      {
        // 客服
        path: 'service',
        name: 'service',
        component: service,
      },

      // 客服 app 下載頁面
      {
        path: 'app-store',
        name: 'appStore',
        component: AppStore,
      },
      // 客服 app 頁面
      {
        path: 'service-app',
        name: 'service-app',
        component: ServiceApp,
      },
      // 電子游藝 / 分類列表
      {
        path: 'slot/:slotName',
        name: 'slot',
        component: Slot,
        meta: { requiresAuth: true },
      },
      // VENDOR 列表 (分類版塊 進入)
      {
        path: 'vendor/:id',
        name: 'vendor',
        component: Vendor,
      },
      {
        // 紀錄主頁
        path: 'record',
        name: 'record',
        component: wager,
        meta: { requiresAuth: true },
      },
      {
        // 我的 (使用者中心)
        path: 'user',
        name: 'user',
        component: User,
      },
      {
        // 個人資料
        path: 'user/personal',
        name: 'personal',
        component: Personal,
        meta: { requiresAuth: true },
      },
      {
        // vip
        path: 'user/vip',
        component: Vip,
        meta: { requiresAuth: true },
      },
      // VIP 條款與規則
      {
        path: 'user/vip-rules',
        name: 'vip-rules',
        component: Rules,
      },
      {
        // 卡片
        path: 'user/card',
        name: 'card',
        component: Card,
        meta: { requiresAuth: true },
      },
      {
        // 新增卡片
        path: 'user/add-card',
        name: 'add-card',
        component: AddCard,
        meta: { requiresAuth: true },
      },
      {
        // 取款
        path: 'user/withdrawal',
        name: 'withdrawal',
        component: Withdrawal,
        meta: { requiresAuth: true },
      },
      {
        // 轉換
        path: 'user/transfer',
        name: 'transfer',
        component: Transfer,
        meta: { requiresAuth: true },
      },
      {
        // 存款
        path: 'user/deposit',
        name: 'deposit',
        component: Deposit,
        meta: { requiresAuth: true },
      },
      {
        // news
        path: 'user/news',
        name: 'news',
        component: News,
        meta: { requiresAuth: true },
      },
      {
        // 消息中心 單項
        path: 'user/news/:id',
        name: 'news-item',
        component: NewsItem,
        props: (route) => ({ id: route.params.id }),
        meta: { requiresAuth: true },
      },
      {
        // 交易紀錄
        path: 'user/trade',
        name: 'trade',
        component: trade,
        meta: { requiresAuth: true },
      },
      {
        // 投注紀錄
        path: 'user/wager',
        name: 'wager',
        component: wager,
        meta: { requiresAuth: true },
      },
      {
        // 兌獎紀錄
        path: 'user/prize',
        name: 'prize',
        component: prize,
        meta: { requiresAuth: true },
      },
      {
        // 手機app
        path: 'app',
        name: 'app',
        component: App,
      },
      // app 安裝教程
      {
        path: 'app/tutorial',
        name: 'tutorial',
        component: Tutorial,
      },
      {
        // 啟動遊戲
        path: 'launch-game',
        name: 'launchGame',
        component: launchGame,
        // meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.state.user.isLogin) return next()
          store.dispatch('show_alert', {
            icon: 'fail',
            text: Vue.$t('flashMessage.loginFirst'),
          })
        },
      },
      {
        // 推薦禮金
        path: 'recommend',
        name: 'recommend',
        component: recommend,
        meta: { requiresAuth: true },
      },
      {
        // 幫助
        path: 'faq',
        component: faq,
        redirect: { path: 'faq/help' },
        children: [
          {
            // 常見問題
            path: 'problem',
            name: 'problem',
            meta: { requiresAuth: true },
            component: problem,
          },

          {
            // 新手幫助
            path: 'help',
            name: 'help',
            meta: { requiresAuth: true },
            props: (route) => ({ docType: route.name }),
            component: help,
          },
          {
            // 企業事務
            path: 'corporate-affairs',
            name: 'corporateAffairs',
            meta: { requiresAuth: true },
            component: help,
            props: (route) => ({ docType: route.name }),
          },
        ],
      },
      // error page
      {
        // server 錯誤
        path: '500',
        name: '500',
        component: error500,
      },
      {
        // 網站 維護頁面
        path: '503',
        name: '503',
        component: error503,
      },
      {
        // 網站 telegram token登錄逾時提醒頁面
        path: 'tokenExpiredFromTelegram',
        name: 'tokenExpiredFromTelegram',
        component: errorTokenExpiredFromTelegram,
      },
    ],
  },
  {
    // 禁止訪問 頁面 (站台被關閉，Operator has been blocked)
    path: '403',
    name: '403',
    component: error403,
  },
  {
    // 不存在頁面，導引首頁
    path: '*',
    redirect: { path: '/' },
  },
]
