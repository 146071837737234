var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-56", attrs: { id: "agent" } },
    [
      _c(
        "Toolbar",
        { staticClass: "toolbar" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                slot: "btn",
                icon: "",
                color: "success",
                to: "/service",
              },
              slot: "btn",
            },
            [
              _c("Icon", {
                staticClass: "icon--text",
                attrs: {
                  data: require("@icon/bottomNav/service.svg"),
                  width: "24px",
                  height: "24px",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "h-100 join-us-bg" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "pa-0 p-relative" }, [
                _c("img", {
                  attrs: {
                    width: "100%",
                    src: require("@/assets/icons/joinUs/joinus-template3.png"),
                  },
                }),
                _c("div", { staticClass: "title-template3 text-center" }, [
                  _vm._v(" " + _vm._s(_vm.$t("agent.page1Title")) + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-center text--text pt-0 subtitle-2" },
                [_vm._v(" " + _vm._s(_vm.$t("agent.page1Desc")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "px-10 feature-area" },
            [
              _c("div", { staticClass: "line-1" }),
              _c("div", { staticClass: "line-2" }),
              _vm._l(_vm.joinUsItem, function (item, index) {
                return _c(
                  "v-col",
                  {
                    key: index,
                    staticClass: "text-center py-2",
                    attrs: { cols: "6" },
                  },
                  [
                    _c(`Join0${index + 1}`, {
                      tag: "component",
                      attrs: { color: "null", width: "72" },
                    }),
                    _c(
                      "div",
                      { staticClass: "caption feature-text secondary--text" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(`agent_slider2.section${index + 1}_title`)
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              _vm._l(_vm.contacts, function (item) {
                return _c(
                  "v-col",
                  {
                    key: item._id,
                    staticClass:
                      "d-flex justify-center align-center flex-column mb-0 mt-2",
                    attrs: { cols: "6" },
                  },
                  [
                    _c("v-img", {
                      attrs: {
                        width: "32",
                        height: "32",
                        contain: "",
                        src: _vm.imgCheck(item.type),
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "subtitle-2 mt-3 secondary--text" },
                      [_vm._v(" " + _vm._s(item.title) + " ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "white--text caption",
                        attrs: { id: item.type },
                      },
                      [_vm._v(" " + _vm._s(item.info) + " ")]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "pa-1",
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.copyText(item.type)
                              },
                            },
                          },
                          [
                            _c("Icon", {
                              staticClass: "white--text",
                              attrs: {
                                data: require("@icon/copy.svg"),
                                width: "20",
                                height: "20",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pt-5 pb-6 px-4", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "rounded-0",
                      attrs: {
                        height: "44",
                        block: "",
                        color: "joinus-button",
                        to: "/service",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "white--text subtitle-1 font-weight-bold",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("myCenter.joinUs")) + " ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }