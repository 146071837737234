<template>
  <!-- 推薦禮金 -->
  <component
    :is="layoutTemplate"
    class="h-100dvh over-flow-y-scroll"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import { curryTemplate } from '../../utils/template'

import template1 from './template/recommendTemplate1.vue'
import template3 from './template/recommendTemplate3.vue'
import template4 from './template/recommendTemplate4.vue'

export default {
  computed: {
    ...mapGetters(['layout']),

    layoutTemplate() {
      const templateObj = {
        template1,
        template3,
        template4,
      }
      return curryTemplate(this.layout, templateObj)
    },
  },
}
</script>
