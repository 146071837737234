import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import { isScrollToBottom, scrollBottomFunc } from '@/utils/scrollToBottom'

import SearchBar from '../components/SearchBar.vue'
import SearchBar2 from '../components/SearchBar2.vue'
import SearchBarTemplate7 from '../components/SearchBarTemplate7.vue'
import Nodata from '@/components/base/Nodata.vue'
import NodataTemplate3 from '@/components/base/Nodata-template3.vue'
import NodataBig from '@/components/base/NodataBig.vue'
import GameList from '../components/GamesList.vue'

import template1 from '@/components/base/template/ToolbarTemplate1.vue'
import template3 from '@/components/base/template/ToolbarTemplate3.vue'
import template4 from '@/components/base/template/ToolbarTemplate4.vue'
import template7 from '@/components/base/template/ToolbarTemplate7.vue'
import { curryTemplate } from '@/utils/template'

export default {
  components: { Nodata, SearchBar, SearchBar2, SearchBarTemplate7, GameList, NodataBig },
  props: {
    activeSubTabType: { // 從allGameType傳來的遊戲廠商名稱
      type: String,
      required: false,
      default: 'noData',
    },
    activePage: { // 專屬gameType7使用的參數
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => ({
    chineseFlag: '',
    mode: 'general', // 顯示模式: general (一般) || search (搜尋)

    searchResolve: false, // 是否已搜尋過 (顯示預設文字)

    tab: 0, // 預設熱門 -> 收藏 -> 全部

    form: {
      vendor: '', // 遊戲商代碼
      page: 1, // 頁碼
      filter: '', // 搜尋遊戲用，輸入遊戲名稱
      per_page: '8', // 請求幾筆資料
    },

    games: [], // 搜尋到的遊戲
    searchConfig: [], // 搜尋到的遊戲相關筆數資料
    loading: false,

    type: 'all',
    gameBrands: [
    ],

    scrollBottom: {
      status: true,
      ing: false,
      progress: true,
    },

    lastPage: null,
  }),

  computed: {
    ...mapGetters(['layout', 'theme']),
    ...mapState({
      // 預設遊戲類型（全部/熱門/最新/我的收藏）
      defaultGameType: state => state.websiteData.game_list_type,
    }),
    /**
     * vendor 名稱
     * @date 2021-06-02
     * @returns {string} vendor 名稱
     */
    gameBrandName() {
      return this.gameBrands.filter(brand =>
        brand.game_brand_code === this.$route.params.slotName)[0]?.name
    },

    noDataComponent() {
      if (this.layout === 'template3' || this.layout === 'template4') return NodataTemplate3
      return Nodata
    },

    /**
     * TAB 頁籤項目
     * @date 2021-06-04
     * @returns {any}
     */
    tabItem() {
      return [
        this.$t('global.all'), // 全部
        this.$t('global.favorite'), // 收藏
        this.$t('global.hot'), // 熱門
        this.$t('global.new'), // 最新
      ]
    },

    layoutTemplate() {
      const templateObj = {
        template1,
        template3,
        template4,
        template7,
      }
      return curryTemplate(this.layout, templateObj)
    },
  },

  watch: {
    tab(newTab) {
      this.resetNScroll()
      this.main(newTab)
    },
    'form.filter'(newKeyword) {
      // console.log(newKeyword)
      this.games = []
      if (!newKeyword && this.layout !== 'template7') {
        this.searchResolve = false
        return
      }
      if (!this.chineseFlag || this.chineseFlag === 'compositionend') this.filterAllGame()
    },
    activeSubTabType() {
      this.handleractiveSubTabType()
    },
  },

  mounted() {
    this.handleractiveSubTabType()// 當前頁，是否是首頁的allGameType7
    this.getGameBrandsList(this.type) // 取得遊戲商下_遊戲品牌列表
    this.main(this.tab) // 初始化當前tab資料
    if (document.querySelector('#searchInput')) {
      document.querySelector('#searchInput').addEventListener('compositionstart', this.handleComposition)
      document.querySelector('#searchInput').addEventListener('compositionend', this.handleComposition)
    }
  },

  updated() {
    this.set_toolbar_title(this.gameBrandName)
  },
  created() {
    switch (this.defaultGameType) {
      case 'all':
        this.tab = 0
        break

      case 'favorite':
        this.tab = 1
        break

      case 'hot':
        this.tab = 2
        break

      case 'new':
        this.tab = 3
        break

      default:
        this.tab = 0
        break
    }
  },
  methods: {
    ...mapActions([
      'get_vendor_list',
      'set_toolbar_title',
      'get_game_brands',
      'get_vendor_hot_list',
      'get_vendor_new_list',
      'get_vendor_favorite_list',
      'vendor_game_favorite_toggle',
      'show_alert',
    ]),
    // 當前頁，是否是首頁的allGameType7
    handleractiveSubTabType() {
      if (this.activeSubTabType !== 'noData' && this.activePage) {
        // 首頁的allGameType7
        this.form.vendor = this.activeSubTabType
        this.getVendorList(this.form)
      } else {
        // 內頁
        this.form.vendor = this.$route.params.slotName
      }
    },
    handleComposition(val) {
      // console.log(val)
      this.chineseFlag = val.type
      if (this.chineseFlag === 'compositionend') {
        this.games = []
        this.filterAllGame()
      }
    },
    // 設置為 search 模式
    setSearchMode() {
      this.mode = 'search'
      this.form.page = 1
      if (this.layout !== 'template7') this.games = []
    },

    // 設置為 general 模式
    setGeneralMode() {
      this.mode = 'general'
      this.form.filter = ''
      this.searchResolve = false
      this.scrollBottom.status = true
      this.main(this.tab)
    },

    // 清除搜尋文字
    clearFilterKeyword() {
      this.searchResolve = false
      this.form.filter = ''
      if (this.layout === 'template7' || (this.activeSubTabType !== 'noData' && this.activePage)) this.setGeneralMode()
    },

    // 開始搜尋關鍵字
    filterAllGame() {
      this.searchResolve = true
      this.main(0) // 搜尋全部
    },

    /**
     * 取得遊戲商下_遊戲品牌列表 (成功)
     * - 設置資料
     * - 設置 toolbar title
     * @date 2021-06-02
     * @param {object} {game_brands} 遊戲列表
     */
    getGameBrandsListSuccess({ game_brands }) {
      this.gameBrands = game_brands
      this.set_toolbar_title(this.gameBrandName)
    },

    /**
     * 取得遊戲商下_遊戲品牌列表
     * @date 2021-06-02
     * @param {string} vendorName 產品商類型
     * 全部：all ,
     * 棋牌：card ,
     * 漁機：fish,
     * 體育：sport,
     * 電子：slot ,
     * 電競：egame ,
     * 真人：live,
     * 彩票：lottery
     * @returns {object} 遊戲品牌列表
     */
    async getGameBrandsList(type) {
      const res = await this.get_game_brands(type)
      resStatus(res, this.getGameBrandsListSuccess)
    },

    /**
     * 頁籤 tab 執行入口 (收藏/熱門/最新/全部)
     * @date 2021-06-02
     * @param {number} tab 當前頁籤位置
     */
    main(tab) {
      this.loading = true
      const vendorData = {
        vendor: this.form.vendor,
        page: this.form.apge,
      }

      switch (tab) {
        case 1: // 收藏
          this.getVendorFavoriteList(vendorData)
          break

        case 2: // 熱門
          this.getVendorListForHot(vendorData)
          break

        case 3: // 最新
          this.getVendorListForNew(vendorData)
          break

        case 0: // 全部
          this.getVendorList(this.form)
          break

        default:
          break
      }
    },

    /**
     * 儲存搜尋到的遊戲
     * @date 2021-06-04
     * @param {any} {games}
     * @returns {any}
     */
    setGamesToData({ data }) {
      this.games = []
      this.games = data
    },

    /**
     * 遊戲相關筆數資料
     * @date 2021-06-04
     * @param {object} games 遊戲相關筆數資料
     */
    setSearchConfig(games) {
      this.searchConfig = games
    },

    /**
     * 取得遊戲資料 (成功)
     * @date 2021-06-04
     * @param {object} {games} 遊戲相關筆數資料
     */
    getGamesSuccess(data) {
      this.loading = false
      this.scrollBottom.progress = false
      const resData = data.games.data
      this.lastPage = data.games.last_page

      // 如果第一次取得資料
      if (this.form.page === 1) {
        this.games = []
        this.setGamesToData(data.games)
        this.setSearchConfig(data.games)
        // 如果沒有下一頁，不會再有底部加載 circle
        if (this.searchConfig.next_page_url === null) return this.ifFetchNoData()
        return false
      }

      // 如果取得資料為 無
      if (resData.length === 0) return this.ifFetchNoData()

      // 如果是滾動加載取得資料
      setTimeout(() => {
        this.games = [...this.games, ...resData]
        this.scrollBottom.progress = false
        this.setSearchConfig(data.games)
      }, 800)
    },

    /**
     * 取得遊戲品牌下_遊戲列表 (全部)
     * @date 2021-06-02
     * @param {object} vendorData 欲取遊戲列表資料 (this.form)
     * @returns {object} 遊戲列表
     */
    async getVendorList(vendorData) {
      this.loading = true
      const allList = await this.get_vendor_list(vendorData)
      resStatus(allList, this.getGamesSuccess)
    },

    /**
     * 取得 vendor 遊戲列表 (熱門)
     * @date 2021-06-02
     * @param {object} vendorData 請求 vendor 資料 {vendor, page}
     */
    async getVendorListForHot(vendorData) {
      const hotList = await this.get_vendor_hot_list(vendorData)
      resStatus(hotList, this.getGamesSuccess)
    },

    /**
     * 取得 vendor 遊戲列表 (最新)
     * @date 2021-06-02
     * @param {object} vendorData 請求 vendor 資料 {vendor, page}
     */
    async getVendorListForNew(vendorData) {
      this.loading = true
      const newList = await this.get_vendor_new_list(vendorData)
      resStatus(newList, this.getGamesSuccess)
    },

    /**
     * 取得 vendor 遊戲列表 (最愛/收藏)
     * @date 2021-06-02
     * @param {object} vendorData 請求 vendor 資料 {vendor, page}
     */
    async getVendorFavoriteList(vendorData) {
      // this.loading = true
      const favoriteList = await this.get_vendor_favorite_list(vendorData)
      const games = favoriteList.data.games.data
      games.forEach(item => { item.favor = true })
      resStatus(favoriteList, this.getGamesSuccess)
    },

    /**
     * 加入/移除 我的收藏(成功)
     * @date 2021-06-07
     * @param {any} {favor} 收藏狀態 "favor":"Y"
     */
    favoriteGameToggleSuccess({ favor }) {
      const favoriteStatus = favor === 'Y' ? 'favorite' : 'unfavorite'
      this.show_alert({
        icon: favoriteStatus,
      })
      this.resetNScroll()
      // 如果是搜尋模式下，刷新為 (全部) 遊戲
      if (this.mode === 'search') return this.main(3)
      this.main(this.tab)
    },

    /**
     * 加入/移除 我的收藏(失敗)
     * @date 2021-06-07
     */
    favoriteGameToggleFail() {
      this.show_alert({
        icon: 'fail',
      })
    },

    /**
     * 加入/移除 我的收藏(最愛)
     * @date 2021-06-07
     * @param {object} gameCode 遊戲代碼 { game_code: gameCode }
     * @returns {object} 收藏狀態 "favor":"Y"
     */
    async favoriteGameToggle(gameCode) {
      const gameCodeForm = { game_code: gameCode }
      const favoriteStatus = await this.vendor_game_favorite_toggle(gameCodeForm)
      resStatus(favoriteStatus, this.favoriteGameToggleSuccess, this.favoriteGameToggleFail)
    },

    /**
     * 滾動功能重置
     * @date 2021-06-07
     */
    resetNScroll() {
      this.$scrollToTop()
      this.resetBtoomSetting()
    },

    /**
     * 重置原始狀態 bottom
     * @date 2021-05-03
     */
    resetBtoomSetting() {
      this.recordData = []
      this.form.page = 1
      this.scrollBottom.status = true
      this.scrollBottom.ing = false
      this.scrollBottom.progress = false
    },

    /**
     * 如果取得 (無) 資料 || 無下一頁
     * @date 2021-06-07
     * @returns {any}
     */
    ifFetchNoData() {
      this.scrollBottom.status = false
      this.scrollBottom.progress = false
      if (this.form.page > 1) this.form.page -= 1
      this.scrollBottom.ing = false
    },

    /**
     * 設置utils 閉包
     * @date 2021-05-03
     * @returns {any}
     */
    setBottomFun() {
      const bottom = scrollBottomFunc({
        form: this.form,
        scrollData: this.scrollBottom,
        func: () => this.main(this.tab),
      })
      return bottom
    },

    /**
     * 底部加載功能
     * @date 2021-05-03
     * @param {any} e
     * @returns {any}
     */
    onScroll(e) {
      if (!isScrollToBottom(e)) {
        return false
      }

      if (this.form.page >= this.lastPage) return false

      if (isScrollToBottom(e) && !this.scrollBottom.status) {
        if (this.scrollBottom.ing) return false
        return false
      }

      const bottom = this.setBottomFun()
      bottom.getData()
      setTimeout(() => bottom.reset(), 1000)
    },
  },
}
