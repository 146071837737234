import { render, staticRenderFns } from "./DownloadApp.vue?vue&type=template&id=db953c34&scoped=true"
import script from "./DownloadApp.vue?vue&type=script&lang=js"
export * from "./DownloadApp.vue?vue&type=script&lang=js"
import style0 from "./DownloadApp.vue?vue&type=style&index=0&id=db953c34&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db953c34",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/drone/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('db953c34')) {
      api.createRecord('db953c34', component.options)
    } else {
      api.reload('db953c34', component.options)
    }
    module.hot.accept("./DownloadApp.vue?vue&type=template&id=db953c34&scoped=true", function () {
      api.rerender('db953c34', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/components/DownloadApp.vue"
export default component.exports