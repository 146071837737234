import request from '@/api/request'

/*
  API 優惠活動
  優惠活動相關的 API
    - 取得優惠活動列表
    - 取得vip列表
*/

/* 取得優惠活動列表 */
export const getBonusList = (data) => request.post('/api/v1/bonus-news', data)

/* 取得vip列表 */
export const getVipList = () => request.get('/api/v1/vipbonus')

/* 玩家領取紅利 */
export const getVipBonus = (data) =>
  request.post('/api/v1/member/redeem/bonus', data)

/* 玩家領取豪禮 */
export const getVipBonusExtra = (data) =>
  request.post('/api/v1/member/redeem/bonus-extra', data)

/* 檢查玩家領獎按鈕 */
export const userBonusButtonStatus = (data) =>
  request.post('/api/v1/member/redeem/bonus-button-check', data)

/* 優惠活動_判斷顯示按鈕 (我要參加/我要領取) */
export const checkUserBonusBtnStatus = (data) =>
  request.post('/api/v1/member/promo/button-check', data)

/* 參加優惠活動 */
export const userGetBonus = (data) =>
  request.post('/api/v1/member/promo/apply', data)

// 我要領取 (每月)
export const monthlyBonusReceive = (promoId) =>
  request.post('/api/v1/member/promo/m-check-in', promoId)
