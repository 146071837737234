<template>
  <!-- 推薦禮金 -->
  <div class="pt-56">
    <v-container>
      <!-- recommend -->
      <v-row class="pt-2 px-3 pb-2">
        <v-col
          v-for="(item, index) in recommendItem"
          :key="index"
          class="pa-1 text-center"
          cols="4"
        >
          <div class="card1 py-2 rounded d-flex align-center flex-column justify-center caption">
            <div class="recommend__title title--text font-weight-medium">
              {{ item.title }}
            </div>

            <div
              class="recommend__num primary--text text-h6"
              :class="[{'secondary--text': item.item === 'total'}]"
            >
              {{ recommend[item.item] }}
            </div>

            <div class="recommend__description hint--text">
              {{ item.description }}
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row class="px-1">
        <v-col cols="12">
          <v-divider class="divider" />
        </v-col>
      </v-row>

      <!-- 說明文字 -->
      <v-row id="recommend-info">
        <v-col cols="2" />
        <v-col
          cols="8"
          class="text-center title--text"
        >
          <div class="description__title text-h6">
            {{ $t('recommend.title') }}
          </div>

          <div class="description__subtitle subtitle-1 mb-6">
            {{ $t('recommend.subtitle') }}
          </div>

          <div class="description__description caption hint--text">
            {{ $t('recommend.description') }}
          </div>
        </v-col>

        <v-col cols="2" />

        <!-- QRCODE -->
        <v-col cols="12 text-center">
          <QrcodeVue
            class="pt-4 px-4 pb-2 white qrcode rounded"
            :value="recommendResgisterUrl"
            size="92"
            level="L"
            foreground="black"
          />
        </v-col>
      </v-row>

      <!-- 推薦碼 -->
      <v-row class="px-3 pt-3">
        <v-col cols="2" />
        <!-- 文字 -->
        <v-col
          cols="8"
          class="py-0 caption title--text px-1"
        >
          {{ $t('global.recommendCode') }}
        </v-col>
        <v-col cols="2" />

        <v-col cols="2" />
        <v-col
          cols="8"
          class="py-0 px-1"
        >
          <!-- 推薦碼 -->
          <v-text-field
            autofocus
            readonly
            class="mt-2 red--text"
            min-height="36"
            dense
            outlined
            required
            :value="recommendCode"
          >
            <!-- 復製推薦碼 -->
            <div
              v-show="false"
              slot="append"
            >
              <v-btn
                icon
                height="20"
                width="20"
                @click="copyNumber('recommendCode')"
              >
                <Icon
                  data="@icon/copy.svg"
                  width="16"
                  height="16"
                  class="title--text"
                />
              </v-btn>
            </div>
          </v-text-field>
        </v-col>
        <v-col cols="2" />

        <!-- 操作區塊 -->
        <v-col
          cols="12"
          class="py-0 px-1 text-center pb-5"
        >
          <!-- 復製連結 btn -->
          <v-btn
            slot="btn"
            color="success"
            text
            height="64"
            @click="copyNumber('recommendResgisterUrl')"
          >
            <div class="d-flex flex-column align-center justify-center">
              <Icon
                data="@icon/copylink.svg"
                width="24px"
                height="24px"
                class="icon--text"
              />
              <span class="caption title--text mt-2">{{ $t('global.copyRecommendUrl') }}</span>
            </div>
          </v-btn>

          <!-- 下載 btn -->
          <a
            :href="qrCodeCanvasImgUrl"
            download="recommend-qrcode"
            class="text-decoration-none"
          >
            <v-btn
              slot="btn"
              color="success"
              text
              height="64"
            >
              <div class="d-flex flex-column align-center justify-center">
                <Icon
                  data="@icon/download.svg"
                  width="24px"
                  height="24px"
                  class="icon--text"
                />
                <span class="caption title--text mt-2">{{ $t('global.downloadQRcode') }}</span>
              </div>
            </v-btn>
          </a>
        </v-col>

        <!-- 推薦碼 input (HIDDEN) -->
        <input
          id="recommendCode"
          type="text"
          :value="recommendCode"
        >
        <!-- 推薦連結 input (HIDDEN) -->
        <input
          id="recommendResgisterUrl"
          type="text"
          :value="recommendResgisterUrl"
        >
      </v-row>
    </v-container>
    <div class="d-none" />
  </div>
</template>

<script>
import recommendMixin from '../mixins/recommendMixin'

export default {
  mixins: [recommendMixin],
}
</script>

<style lang="scss" scoped >
.h-88 {
	height: 88px;
}

#recommendResgisterUrl,
#recommendCode {
	height: 0;
}

.qrcode {
	display: inline-block;
}

.d-none {
	display: none;
}
</style>
