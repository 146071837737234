var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(_vm.componentAry[_vm.model], { tag: "components" }),
      _c(
        "v-bottom-navigation",
        {
          staticClass: "navBar",
          attrs: { value: _vm.model, color: "primary", grow: "", fixed: "" },
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "pa-0 navBar",
                          attrs: { height: "56", block: "" },
                          on: {
                            click: function ($event) {
                              _vm.model = 0
                            },
                          },
                        },
                        [
                          _c("Icon", {
                            attrs: {
                              data: require("@icon/bottomNav/service.svg"),
                              width: "24px",
                              height: "24px",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "pa-0 navBar",
                          attrs: { block: "", height: "56" },
                          on: {
                            click: function ($event) {
                              _vm.model = 1
                            },
                          },
                        },
                        [
                          _c("Icon", {
                            attrs: {
                              data: require("@icon/app.svg"),
                              width: "24px",
                              height: "24px",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }