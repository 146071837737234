<template>
  <div>
    <!-- TOOLBAR -->
    <Toolbar />
    <div class="pt-56 vh-100">
      <iframe
        :src="worldCupUrl"
        frameborder="0"
        width="100%"
        height="100%"
      >
        Your browser does not support iframes!
      </iframe>
    </div>
  </div>
</template>

<script>
import Toolbar from '@/components/base/Toolbar.vue'
import { mapState } from 'vuex'

export default {
  components: {
    Toolbar,
  },
  computed: {
    ...mapState({
      worldCupUrl: state => state?.websiteData?.worldcup_info?.worldcup_schedule_url || '',
    }),
  },
}
</script>

<style lang="scss" scoped>
</style>
