import isLocalDev from './isLocalDev'

export const isMyCardService = (urlHost) => {
  const myCardDomain = [
    'iph1.vegas.gf-gaming.com',
    'm.v2.iph1.vegas.gf-gaming.com',
  ]

  return myCardDomain.includes(isLocalDev() ? process.env.VUE_APP_WEBSITE : urlHost)
}
