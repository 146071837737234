var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "h-100dvh over-flow-y-scroll pt-56",
      class: { popupbg: _vm.layout === "template3" },
    },
    [
      _c(
        "v-container",
        [
          _vm.bonusNews
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "px-0 py-0 p-relative bonus__item" },
                    [
                      _c("v-img", {
                        staticClass: "rounded",
                        attrs: {
                          src: _vm.baseImgUrl + _vm.bonusNews.content_image,
                          contain: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "placeholder",
                              fn: function () {
                                return [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "fill-height ma-0",
                                      attrs: {
                                        align: "center",
                                        justify: "center",
                                      },
                                    },
                                    [
                                      _c("v-progress-circular", {
                                        attrs: {
                                          indeterminate: "",
                                          color: "grey lighten-5",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          4034393411
                        ),
                      }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.bonusNews.content,
                              expression: "bonusNews.content",
                            },
                          ],
                          staticClass: "px-3 pt-2 news-content fix-img-size",
                        },
                        [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(_vm.bonusNews.content),
                            },
                          }),
                        ]
                      ),
                      _vm.checkBtn.dailycheckin === "Y"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "bonus__daily-checkin",
                              class:
                                _vm.layout === "template7"
                                  ? "btnText--text"
                                  : "",
                              style: _vm.gradientBg,
                              attrs: {
                                fixed: "",
                                rounded:
                                  _vm.layout === "template7"
                                    ? _vm.lg
                                    : _vm.layout !== "template3",
                                outlined: _vm.layout === "template3",
                                width: "160px",
                                color:
                                  _vm.layout !== "template7" ? "secondary" : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getBonusApply(
                                    _vm.bonusNews.promo_id,
                                    "apply"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("global.dailyCheckin")) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.checkBtn.dailycheckin === "DONE"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "bonus__daily-checkin opacity-1",
                              class:
                                _vm.layout === "template7"
                                  ? "btnText--text"
                                  : "",
                              style: _vm.gradientBg,
                              attrs: {
                                fixed: "",
                                rounded:
                                  _vm.layout === "template7" ? _vm.lg : true,
                                width: "160px",
                                color:
                                  _vm.layout !== "template7" ? "secondary" : "",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("global.dailyCheckined")) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.checkBtn.monthly_dailycheckin === "Y"
                        ? _c(
                            "v-btn",
                            {
                              class:
                                _vm.layout === "template7"
                                  ? "btnText--text"
                                  : "",
                              style: _vm.gradientBg,
                              attrs: {
                                fixed: "",
                                rounded:
                                  _vm.layout === "template7"
                                    ? _vm.lg
                                    : _vm.layout !== "template3",
                                outlined: _vm.layout === "template3",
                                width: "160px",
                                color: "bonus__item-apply",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getBonusApply(
                                    _vm.bonusNews.promo_id,
                                    "apply"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("global.join")) + " ")]
                          )
                        : _vm._e(),
                      _vm.checkBtn.monthly_dailycheckin === "DONE"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "bonus__item-apply",
                              class:
                                _vm.layout === "template7"
                                  ? "btnText--text"
                                  : "",
                              style: _vm.gradientBg,
                              attrs: {
                                fixed: "",
                                rounded:
                                  _vm.layout === "template7"
                                    ? _vm.lg
                                    : _vm.layout !== "template3",
                                outlined: _vm.layout === "template3",
                                width: "160px",
                                color:
                                  _vm.layout !== "template7" ? "secondary" : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.monthlyBonusReceive(
                                    _vm.bonusNews.promo_id
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("global.getBonus")) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.checkBtn.apply === "Y"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "bonus__item-apply",
                              class:
                                _vm.layout === "template7"
                                  ? "btnText--text"
                                  : "",
                              style: _vm.gradientBg,
                              attrs: {
                                fixed: "",
                                rounded:
                                  _vm.layout === "template7"
                                    ? _vm.lg
                                    : _vm.layout !== "template3",
                                outlined: _vm.layout === "template3",
                                width: "160px",
                                color:
                                  _vm.layout !== "template7" ? "secondary" : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getBonusApply(
                                    _vm.bonusNews.promo_id,
                                    "apply"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("global.join")) + " ")]
                          )
                        : _vm._e(),
                      _vm.checkBtn.event_wallet_btn === "Y"
                        ? _c(
                            "v-btn",
                            {
                              class:
                                _vm.layout === "template7"
                                  ? "btnText--text"
                                  : "",
                              style: _vm.gradientBg,
                              attrs: {
                                fixed: "",
                                rounded:
                                  _vm.layout === "template7"
                                    ? _vm.lg
                                    : _vm.layout !== "template3",
                                outlined: _vm.layout === "template3",
                                width: "160px",
                                color: "bonus__item-apply",
                                to: {
                                  path: "/user/transfer",
                                  query: { tab: "eventWallet" },
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("global.eventWalle") +
                                      _vm.$t("global.wallet")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.checkBtn.redeem === "Y"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "bonus__item-apply",
                              class:
                                _vm.layout === "template7"
                                  ? "btnText--text"
                                  : "",
                              style: _vm.gradientBg,
                              attrs: {
                                fixed: "",
                                rounded:
                                  _vm.layout === "template7" ? _vm.lg : true,
                                width: "160px",
                                color:
                                  _vm.layout !== "template7" ? "secondary" : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getBonusRedeem(
                                    _vm.bonusNews.promo_id,
                                    "redeem"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("global.getBonus")) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "250" },
          model: {
            value: _vm.failDialog.status,
            callback: function ($$v) {
              _vm.$set(_vm.failDialog, "status", $$v)
            },
            expression: "failDialog.status",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "bg d-flex flex-column" },
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column align-center w-100" },
                  [
                    _c("Icon", {
                      staticClass: "danger--text",
                      attrs: {
                        data: require("@icon/alert/fail.svg"),
                        width: "24px",
                        height: "24px",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "mt-2 subtitle-1 w-100",
                    class: { "text-center": _vm.failDialog.errors.length < 2 },
                  },
                  [_vm._v(" " + _vm._s(_vm.failDialog.errors[0]) + " ")]
                ),
              ]),
              _c(
                "v-card-text",
                { staticClass: "px-4 pb-0" },
                _vm._l(_vm.errorMessage, function (item, index) {
                  return _c("ul", { key: index }, [
                    _c("li", { staticClass: "caption" }, [
                      _vm._v(" " + _vm._s(item) + " "),
                    ]),
                  ])
                }),
                0
              ),
              _c("v-spacer"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-2",
                      attrs: { slot: "btn", text: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.failDialog.status = false
                        },
                      },
                      slot: "btn",
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }