var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: [!_vm.isLiffMode ? "pt-56" : ""], attrs: { id: "lauch-game" } },
    [
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showMiniBtn,
              expression: "showMiniBtn",
            },
          ],
          staticClass: "show-toolbar-btn",
          attrs: { icon: "", color: "primary" },
          on: {
            click: function ($event) {
              return _vm.showMiniIcon(false)
            },
          },
        },
        [
          _c("Icon", {
            staticClass: "icon--text",
            attrs: {
              data: require("@icon/minimize.svg"),
              width: "36",
              height: "36",
            },
          }),
        ],
        1
      ),
      _c(
        "Toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLiffMode,
              expression: "!isLiffMode",
            },
          ],
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "title--text",
              attrs: { slot: "btn", text: "" },
              on: {
                click: function ($event) {
                  return _vm.showMiniIcon(true)
                },
              },
              slot: "btn",
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.fullScreen")) + " ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "lauch h-100dvh over-flow-y-scroll" }, [
        _c("iframe", {
          attrs: {
            frameborder: "0",
            src: _vm.gameUrl,
            height: "100%",
            width: "100%",
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }