var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Toolbar"),
      _c("div", { staticClass: "pt-56 vh-100" }, [
        _c(
          "iframe",
          {
            attrs: {
              src: _vm.worldCupUrl,
              frameborder: "0",
              width: "100%",
              height: "100%",
            },
          },
          [_vm._v(" Your browser does not support iframes! ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }