
import CryptoJS from 'crypto-js'

const locationHost = location.host

/**
 * 加密
 * @date 2021-09-22
 * @param {String}} secret 加密資料
 * @param {String} secretKey 加密金鑰 location host 字串化
 * @returns {String} 加密完成字串
 */
export const encrypt = (secret = '', secretKey = locationHost.toString()) => {
  return CryptoJS.AES.encrypt(secret, secretKey).toString()
}

/**
 * 解密
 * @date 2021-09-22
 * @param {String} secret 解密資料
 * @param {String} secretKey 解密金鑰 location host 字串化
 * @returns {String} 解密完成
 */
export const decrypt = (secret = '', secretKey = locationHost.toString()) => {
  const decryptData = CryptoJS.AES.decrypt(secret, secretKey)
  return decryptData.toString(CryptoJS.enc.Utf8)
}
