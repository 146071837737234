import request from './lineRequest'

/*
  發送 LINE Notify 通知
*/
export function pushLineNotifyAPI(data) {
  return request({
    url: '/api/v1/line/notify',
    method: 'post',
    data,
  })
}
