<template>
  <div
    id="agent"
    class="pt-56"
  >
    <!-- Toolbar -->
    <Toolbar
      class="toolbar"
    >
      <v-btn
        slot="btn"
        icon
        color="success"
        to="/service"
      >
        <Icon
          data="@icon/bottomNav/service.svg"
          width="24px"
          height="24px"
          class="icon--text"
        />
      </v-btn>
    </Toolbar>

    <!--  content -->
    <v-container class="h-100 join-us-bg">
      <v-row>
        <v-col class="pa-0 p-relative">
          <!-- 圖片  -->
          <img
            width="100%"
            src="@/assets/icons/joinUs/joinus-template3.png"
          >
          <!-- title -->
          <div class="title-template3 text-center">
            {{ $t('agent.page1Title') }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center text--text pt-0 subtitle-2">
          {{ $t('agent.page1Desc') }}
        </v-col>
      </v-row>

      <v-row class="px-10 feature-area">
        <div class="line-1" />
        <div class="line-2" />
        <!-- 特色 -->
        <v-col
          v-for="(item, index) in joinUsItem"
          :key="index"
          cols="6"
          class="text-center py-2"
        >
          <!-- ICON -->
          <component
            :is="`Join0${index + 1}`"
            color="null"
            width="72"
          />
          <!-- 說明文字 -->
          <div
            class="caption feature-text secondary--text"
          >
            {{ $t(`agent_slider2.section${index + 1}_title`) }}
          </div>
        </v-col>

        <!-- 聯絡資料 -->
        <v-col
          v-for="item in contacts"
          :key="item._id"
          cols="6"
          class="d-flex justify-center align-center flex-column mb-0 mt-2"
        >
          <v-img
            width="32"
            height="32"
            contain
            :src="imgCheck(item.type)"
          />
          <div
            class="subtitle-2 mt-3 secondary--text"
          >
            {{ item.title }}
          </div>
          <div
            :id="item.type"
            class="white--text caption"
          >
            {{ item.info }}
          </div>
          <div>
            <v-btn
              icon
              class="pa-1"
              @click="copyText(item.type)"
            >
              <Icon
                data="@icon/copy.svg"
                width="20"
                height="20"
                class="white--text"
              />
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <!-- 加入我們 btn -->
        <v-col
          cols="12"
          class="pt-5 pb-6 px-4"
        >
          <v-btn
            height="44"
            block
            color="joinus-button"
            class="rounded-0"
            to="/service"
          >
            <span class="white--text subtitle-1 font-weight-bold">
              {{ $t('myCenter.joinUs') }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import agentMixin from '../mixins/agentMixin'

export default {
  mixins: [agentMixin],
}
</script>

<style lang="scss" scoped>
#goAdminImg {
	user-select: none;
}
.title-template3 {
  font-family: 'Noto Sans SC';
  position: absolute;
  left: 50%;
  margin-left: -20%;
  bottom: 20px;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  /* 合營計畫/金色 */
  background: linear-gradient(130.99deg, #FFC672 20.65%, #BA875D 52.55%, #FFCD8F 75.23%, #E98A32 94.45%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.join-us-bg {
  background: linear-gradient(180deg, rgba(140,67,64,1) 0%, rgba(0,0,0,1) 23%);
}
.feature-text{
  margin-top: -15px;
}

.feature-area {
  position: relative;
  > .col {
    z-index: 1;
  }
}

.line-1 {
  position: absolute;
  left: 0;
  margin-left: 3.3rem;
  height: 100%;
  width: 31%;
  background: linear-gradient(180deg, rgba(208, 183, 178, 0) 0%, #C03111 90%);
  z-index: 0;
  border-radius: 4px;
  overflow: hidden;
  &::before {
    position: absolute;
    bottom: -1rem;
    content: '';
    background: linear-gradient(90deg, rgba(192, 18, 0, 0.5) 0%, rgba(0, 0, 0, .5) 90%);
    width: 100%;
    height: 150px;
    transform: skewY(-13deg);
  }
}

.line-2 {
  content: '';
  position: absolute;
  right: 0;
  margin-right: 3.3rem;
  height: 100%;
  width: 31%;
  background: linear-gradient(180deg, rgba(208, 183, 178, 0) 0%, #C03111 90%);
  z-index: 0;
  border-radius: 4px;
  overflow: hidden;
  &::before {
    position: absolute;
    bottom: -1rem;
    content: '';
    background: linear-gradient(90deg, rgba(192, 18, 0, 0.5) 0%, rgba(0, 0, 0, .5) 90%);
    width: 100%;
    height: 150px;
    transform: skewY(-13deg);
  }
}
.joinus-button {
  background: linear-gradient(180deg, #E32A10 0%, #9F1E0B 100%);
}
</style>
