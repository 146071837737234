<template>
  <v-bottom-navigation
    fixed
    grow
    :height="navHeight"
    :background-color="navBarData[2] ? navBarData[2]['bgcolor'] : '#000'"
  >
    <template
      v-for="(item, index) in navBarData"
    >
      <v-btn
        v-if="item.path !== '/' && item.path !== '/service'"
        :key="index"
        :color="matchRouteName(item.path, index)?item.bgcolor2:item.bgcolor"
        :height="navHeight"
        :to="item.path"
      >
        <img
          v-if="matchRouteName(item.path, index)"
          :src="baseUrl + item.icon_active"
          height="30"
        >
        <img
          v-else
          :src="baseUrl + item.icon"
          height="30"
        >
      </v-btn>

      <!-- 客服模式會根據是否為 line liff 模式而不同動作
        - 一般模式：點擊後跳轉至後台串接的客服系統
        - LineLiff 模式：點擊後浮出 Line 按鈕
      -->
      <v-btn
        v-if="item.path == '/service'"
        :key="index"
        :color="matchRouteName(item.path, index)?item.bgcolor2:item.bgcolor"
        :height="navHeight"
        @click="showServiceArea(item.path)"
      >
        <template v-if="isLiffMode">
          <img
            :src="baseUrl + item.icon"
            height="30"
          >
        </template>
        <template v-else>
          <img
            :src="matchRouteName(item.path, index) ? baseUrl + item.icon_active : baseUrl + item.icon"
            height="30"
          >
        </template>

        <!-- Service Items -->
        <div
          v-show="serviceArea.status"
          class="service-items-area text-left"
        >
          <ul class="pl-0">
            <li
              v-for="(service, idx) in serviceItems"
              :key="service + idx"
              class="my-3 cursor-pointer"
              @click.stop="goService(service.name)"
            >
              <div v-if="service.name === 'Line' && showLineService || service.name === '24h'">
                <v-avatar
                  color="white"
                  size="40"
                >
                  <img
                    class="pa-2"
                    :src="service.icon"
                    alt="line-service"
                  >
                </v-avatar>
                <span class="caption ml-2 white--text">
                  {{ service.text }}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </v-btn>

      <!-- WEBSITE LOGO -->
      <router-link
        v-if="item.path === '/'"
        :key="index"
        class="websiteLogo"
        :to="item.path"
      >
        <v-avatar size="56">
          <img
            :src="matchRouteName(item.path, index) ? baseUrl + item.icon_active : baseUrl + item.icon"
            :alt="item.title"
          >
        </v-avatar>
      </router-link>
    </template>

    <v-overlay
      :value="serviceArea.status"
      opacity=".9"
    />
  </v-bottom-navigation>
</template>

<script>
import bottomModeMixins from '../mixins/bottomNavModeMixins'

export default {
  mixins: [bottomModeMixins],
}
</script>

<style lang="scss" scoped>
.websiteLogo {
  transform: translateY(-15px);
}

.wh-56px {
  height: 56px;
  width: 56px;
}

.wh-38px {
  height: 38px;
  width: 38px;
}

.service-items-area {
  position: absolute;
  bottom: 72px;
  left: 0;
  z-index: 99;

  >ul {
    list-style: none;
  }
}
</style>
