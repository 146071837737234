/**
 * 設置秒數 延遲事件
 * @date 2022-01-26
 * @param {string} sec=5 秒數 (預設 5 秒)
 * @returns {boolean} 是否可以打事件
 */
export const clickEventInterval = (sec = 5) => {
  let status = true
  const timeoutSec = Number(sec) * 1000

  return {
    getStatus() {
      return status
    },
    setStatus(newStatus) {
      status = newStatus
      setTimeout(() => {
        status = !newStatus
      }, timeoutSec)
    },
  }
}
