// 交易
import { withdrawAPI, getPaymentListAPI, depositAPI, getPaymentListPipelineAPI } from '@/api'

const state = () => ({
  amount: 0,
})

const getters = {}

const mutations = {}

const actions = {
  /**
   * 玩家存款 api
   * @date 2021-04-06
   * @param {any} context
   * @param {any} withdrawalData
   * @returns {any}
   */
  async user_withdrawals(context, withdrawalData) {
    const res = await withdrawAPI(withdrawalData)
    return res
  },

  /**
   * 取得玩家付款方式
   * @date 2021-04-07
   * @returns {object} 玩家付款方式列表
   */
  async get_user_payment_list(context, deviceType) {
    const res = await getPaymentListAPI(deviceType)
    return res
  },

  /**
   * 取得玩家 支付 渠道
   * @date 2022-01-17
   * @param {object} context STORE
   * @param {object} deviceNPayType {device, payType} 裝置、支付類型
   * @returns {object} 響應資料
   */
  async get_user_payment_pipeline(context, deviceNPayType) {
    const res = await getPaymentListPipelineAPI(deviceNPayType)
    return res
  },

  /**
   * 玩家存款
   * @date 2021-04-07
   * @param {object} depositData {支付類型, 金額}
   * @returns {object} 存款響應資料 (銀行卡->轉帽資料 / qrCode)
   */
  async user_deposit(context, depositData) {
    const res = await depositAPI(depositData)
    return res
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
