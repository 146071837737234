import isLocalDev from '@/utils/isLocalDev'
import {
  configureUIAppearance,
} from './configureUIAppearance'

export const useLayoutFromAPI = process.env.VUE_APP_USE_API_LAYOUT === 'true'
/**
 * 切換版型 (僅限開發模式)
 * 預設 light 模式
 * 預設 light BB 主題色
 * @returns {any}
 */

export const setLayoutBaseOnEnv = (template, layout, layout_tag) => {
  // 本地環境
  if (isLocalDev()) {
    // 使用 API 版型
    if (process.env.VUE_APP_USE_API_LAYOUT === 'true') {
      return configureUIAppearance(template, layout, layout_tag)
    } else {
      // 自選版型

      return configureUIAppearance(
        process.env.VUE_APP_TEMPLATE,
        process.env.VUE_APP_LAYOUT,
        process.env.VUE_APP_LAYOUT_TAG,
      )
    }
  }

  // Staging 環境、Master 環境：取得 API 營運商設定的版型
  if (!isLocalDev()) {
    return configureUIAppearance(template, layout, layout_tag)
  }
}
