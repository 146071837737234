var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "bg", class: _vm.style },
    [
      _vm.layout && _vm.layout === "template6"
        ? _c("img", {
            staticClass: "watermarkImgUrl",
            attrs: { src: _vm.watermarkImgUrl, height: "300" },
          })
        : _vm._e(),
      _vm.layout && _vm.layout === "template6"
        ? _c("img", {
            staticClass: "watermarkImgUrl",
            attrs: { src: _vm.watermarkImgUrl, height: "300" },
          })
        : _vm._e(),
      _c("v-main", { class: _vm.layout }, [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }