<template>
  <!-- 安裝教程 -->
  <div class="pt-56">
    <!-- TOOLBAR -->
    <Toolbar>
      <v-btn
        slot="btn"
        icon
        color="success"
        to="/service"
      >
        <Icon
          data="@icon/bottomNav/service.svg"
          width="24px"
          height="24px"
          class="icon--text"
        />
      </v-btn>
    </Toolbar>

    <!-- TAB (IOS / Android) -->
    <v-tabs
      v-model="tab"
      grow
      background-color="bg"
      color="primary"
    >
      <v-tab
        v-for="item in tabItem"
        :key="Object.keys(item)[0]"
        class="px-0"
      >
        <span class="subtitle-1">{{ Object.values(item)[0] }}</span>
      </v-tab>
    </v-tabs>
    <v-divider class="divider mb-3" />

    <!-- content -->
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="pb-0"
        >
          <swiper
            ref="slider"
            class="swiper swiper-no-swiping"
            :options="swiperOption"
          >
            <swiper-slide
              v-for="(image,index) in activeTabImage"
              :key="image+index"
              class="d-flex justify-center"
            >
              <v-card
                outlined
                width="191"
                class="bg"
              >
                <v-img
                  :src="baseImgUrl + image"
                />
              </v-card>
            </swiper-slide>

            <!-- 上一張 -->
            <div
              slot="button-prev"
              class="swiper-button-prev"
              @click="slidePrev"
            >
              <v-icon
                width="44"
                :class="[sliderIndex + 1 === 1 ? 'hint--text' : 'icon--text'] "
              >
                mdi-chevron-left
              </v-icon>
            </div>

            <!-- 下一張 -->
            <div
              slot="button-next"
              class="swiper-button-next"
              @click="slideNext"
            >
              <v-icon
                medium
                :class="[sliderIndex + 1 === activeTabImage.length ? 'hint--text' : 'icon--text'] "
              >
                mdi-chevron-right
              </v-icon>
            </div>
          </swiper>
        </v-col>
        <v-col
          cols="12"
          class="text-center pt-0"
        >
          <!-- 顯示索引 (1/6) -->
          <span class="title--text">
            {{ sliderIndex + 1 }} / {{ activeTabImage.length }}
          </span>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import Toolbar from '@/components/base/Toolbar'

export default {
  components: { Toolbar },

  data: () => ({
    tab: 0, // 0 => IOS, 1 => android

    sliderIndex: 0,

    swiperOption: {
      navigation: {
        slidesPerView: 'auto',
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    },
  }),

  computed: {
    ...mapGetters(['baseImgUrl']),

    ...mapState({
      iosImages: state => state.websiteData.mobile.ios_images,
      androidImages: state => state.websiteData.mobile.android_images,
    }),

    tabItem() {
      return [
        {
          iosImages: this.$t('app.ios'),
        },
        {
          androidImages: this.$t('app.android'),
        },
      ]
    },

    activeTabImage() {
      return this.tab === 0 ? this.iosImages : this.androidImages
    },

  },

  watch: {
    tab() {
      this.resetSlider()
    },
  },

  mounted() {
    this.set_toolbar_title(this.$t('app.installationTutorial'))
  },

  methods: {
    ...mapActions(['set_toolbar_title']),

    /**
     * 另開服務頁面
     * @date 2021-06-18
     */
    toServicePage() {
      window.open('https://inprohub.chatbox.one/member/login', '_blank')
    },

    /**
     * 重置 slide 狀置 (回到 index 0)
     * @date 2021-06-18
     */
    resetSlider() {
      this.$refs.slider.$swiper.slideTo(0, 500)
      this.setSliderIndex(0)
    },

    /**
     * 設置 slide index (供顯示)
     * @date 2021-06-18
     * @param {number} index slider 索引
     */
    setSliderIndex(index) {
      this.sliderIndex = index
    },

    /**
     * 上一頁
     * @date 2021-06-18
     */
    slidePrev() {
      this.$refs.slider.$swiper.slidePrev()
      this.setSliderIndex(this.$refs.slider.$swiper.activeIndex)
    },

    /**
     * 下一頁功能
     * @date 2021-06-18
     */
    slideNext() {
      this.$refs.slider.$swiper.slideNext()
      this.setSliderIndex(this.$refs.slider.$swiper.activeIndex)
    },
  },

}
</script>

<style lang="scss" scoped>

.swiper-button-prev,
.swiper-button-next {
	&::after {
		display: none;
	}

	i {
		font-size: 40px;
	}
}

</style>
