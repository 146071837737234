<template>
  <div
    class="h-100dvh over-flow-y-scroll"
  >
    <Toolbar />

    <div class="pt-56 bg pb-5">
      <!-- tab -->
      <v-row class="ma-0 mt-1">
        <v-col
          class="text-h5 font-weight-bold text-center"
          :class="[activeTab === 0 ? 'tab__dragon-active primary--text' : 'navBar tab__dragon']"
          @click="setTab(0)"
        >
          {{ $t('game.dragon') }}
        </v-col>
        <v-col
          class="text-h5 font-weight-bold text-center"
          :class="[activeTab === 1 ? 'tab__tiger-active primary--text' : 'navBar tab__tiger']"
          @click="setTab(1)"
        >
          {{ $t('game.tiger') }}
        </v-col>
      </v-row>

      <!-- Record Tab -->
      <v-row
        class="ma-0 navBar mt-5 px-5 py-2"
        :class="activeTab === 0 ? 'dragon__record' : 'tiger__record'"
      >
        <v-col
          :cols="itemsWidth"
          class="text-center pa-1 caption"
        >
          <v-btn
            block
            class="pa-0 subtitle-2"
            :class="[activeRecord === 0 ? 'white--text' :'']"
            :color="activeRecordTabStyle(0)"
            @click="setRecordTab(0)"
          >
            {{ $t('game.lastResults') }}
          </v-btn>
        </v-col>
        <v-col
          :cols="itemsWidth"
          class="text-center pa-1 caption"
        >
          <v-btn
            block
            class="pa-0 subtitle-2"
            :class="[activeRecord === 1 ? 'white--text' :'']"
            :color="activeRecordTabStyle(1)"
            @click="setRecordTab(1)"
          >
            {{ $t('game.currentSituation') }}
          </v-btn>
        </v-col>
        <v-col
          :cols="itemsWidth"
          class="text-center pa-1 caption"
        >
          <v-btn
            block
            class="pa-0 subtitle-2"
            :color="activeRecordTabStyle(2)"
            @click="showRules"
          >
            {{ $t('game.rules') }}
          </v-btn>
        </v-col>

        <v-col
          :cols="itemsWidth === 4 ? 12 : itemsWidth"
          class="text-center pa-1"
        >
          <v-btn
            block
            class="subtitle-2"
            @click="goVendorPage"
          >
            {{ $t('game.play') }}
          </v-btn>
        </v-col>
      </v-row>

      <component
        :is="components[activeRecord]"
        :active-tab="activeTab"
        :res-data="resData"
      />

      <!-- Dialog Rules -->
      <v-row
        justify="center"
        class=""
      >
        <v-dialog
          v-model="dialog"
          width="600px"
        >
          <v-card class="d-relative">
            <div class="text-right px-2 p-sticky">
              <v-btn
                icon
                class="close-btn"
                @click="dialog = false"
              >
                <v-icon dense>
                  mdi-close
                </v-icon>
              </v-btn>
            </div>

            <v-card-text>
              <h1 class="text-center primary--text">
                {{ $t('game.dtRule') }}
              </h1>

              <v-img src="https://giocoplus.gf-gaming.com/uploads/images/line.png" />

              <p class="mt-4 mb-2 caption">
                {{ rules.rule_explain }}
              </p>

              <v-img :src="baseImgUrl + rules.rule_image" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Toolbar from '../../components/base/Toolbar.vue'
import { resStatus } from '../../utils/resUtils'

import CurrentRecord from './components/CurrentRecord.vue'
import LastRecord from './components/LastRecord.vue'

export default {
  components: {
    Toolbar,
    CurrentRecord,
    LastRecord,
  },

  data: () => ({
    dialog: false,

    activeTab: 0, // 0 dragon ; 1 tiger
    activeRecord: 1, // 0 上期 1 本期 2 rules
    components: [
      LastRecord,
      CurrentRecord,
    ],

    resData: {},

    // rules
    rules: {},
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'clientLang']),

    // 因應語系動態寬度
    itemsWidth() {
      const widthAry = ['en', 'vi-VN', 'th']
      if (this.clientLang === 'ph') return 12
      return widthAry.includes(this.clientLang) ? 6 : 4
    },
  },

  created() {
    this.getDTListData()
    this.getNavbar()
  },
  mounted() {
    this.setToolBar(this.$t('game.dragonTigerList'))
  },

  methods: {
    ...mapActions(['set_toolbar_title', 'get_dt_list_data', 'get_nav_bar', 'show_alert']),

    setToolBar(title) {
      this.set_toolbar_title(title)
    },

    activeRecordTabStyle(tabIndex) {
      const status = this.activeRecord === tabIndex
      if (this.activeTab === 0 && status) return '#7d1c50'
      if (this.activeTab === 1 && status) return '#003c7e'
      else return null
    },

    setTab(tabIndex) {
      this.activeTab = tabIndex
      this.setRecordTab(1)
    },

    setRecordTab(tabIndex) {
      this.activeRecord = tabIndex
    },

    showRules() {
      this.dialog = true
    },

    getDTListDataSuccess(data) {
      const resData = data
      this.resData = resData
      this.rules = resData.rules
    },

    getDTListDataFail() {
      this.show_alert({ icon: 'fail' })
      this.$router.push({ path: '/user' })
    },

    // 取得龍虎榜資料
    async getDTListData() {
      try {
        const res = await this.get_dt_list_data()
        resStatus(res, this.getDTListDataSuccess, this.getDTListDataFail)
      } catch {
        this.getDTListDataFail()
      }
    },

    // 取得 navbar
    async getNavbar() {
      const res = await this.get_nav_bar()
      return res.data.navbar.find(item => item.class === 'slot')._id
    },

    // 立即遊戲
    async goVendorPage() {
      const slotId = await this.getNavbar()
      this.$router.push({
        path: `vendor/${slotId}`,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tab__dragon, .tab__tiger {
  text-shadow: 0 0 3px #fff1a5;
  -webkit-text-stroke: .5px #f0c951;
  -webkit-text-fill-color: #111;
  &-active {
    position: relative;
    &::after{
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -15px;
      bottom: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 15px 0 15px;
    }
  }
}

.tab__dragon {
  &-active {
    background: #7d1c50;
    &::after{
      border-color: #7d1c50 transparent transparent transparent;
    }
  }
}

.tab__tiger {
  &-active {
    background: #003c7e;
    &::after{
      border-color: #003c7e transparent transparent transparent;
    }
  }
}
.p-sticky {
  position: sticky;
  top:0;
  z-index: 1;
}

</style>
