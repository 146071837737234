<template>
  <div class="white">
    <v-container>
      <v-row class="align-star">
        <v-col
          class="black--text flex-grow-0"
        >
          <!-- APP ICON 客服 app  -->
          <div
            class="app-icon"
          >
            <v-img
              class="rounded-xl"
              :src="baseImgUrl + serviceAppIcon"
            />
          </div>
        </v-col>

        <v-col
          class="black--text flex-grow-1"
        >
          <div class="text-h6">
            {{ websiteName + $t('bottomNav.service') }}
          </div>

          <div class="grey--text darken-1">
            {{ $t('service.24HoursAtYourService') }}
          </div>

          <v-btn
            class="mt-7"
            height="30"
            rounded
            color="#0080ff"
            @click="downloadServiceApp"
          >
            {{ $t('service.freeInstallation') }}
          </v-btn>
        </v-col>
      </v-row>

      <!-- 評分 -->
      <v-row class="grey--text darken-1">
        <v-col
          cols="9"
          class="pl-4 pt-0"
        >
          <div class="d-flex align-center">
            <span class="text-h6">
              4.9
            </span>
            <Icon
              v-for="item in 4"
              :key="item"
              data="@icon/star.svg"
              width="16"
              height="16"
              color="#8e8f94"
              class="star"
            />

            <svg
              width="16"
              height="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                fill-rule="evenodd"
              >
                <path
                  d="M8 1.581 9.64 6.5h4.935l-4.16 3.327 1.683 5.049L8 12.416l-4.098 2.46 1.683-5.049L1.425 6.5H6.36L8 1.581z"
                  stroke="#8E8F94"
                />
                <path
                  fill="#8E8F94"
                  d="m8 13-5 3 2-6-5-4h6l2-6z"
                />
              </g>
            </svg>
          </div>

          <div class="caption grey--text lighten-5">
            {{ $t('service.25Ratings') }}
          </div>
        </v-col>

        <v-col
          cols="3"
          class="caption text-center pt-0"
        >
          <span class="text-h6">
            18+
          </span>
          <div>
            {{ $t('service.age') }}
          </div>
        </v-col>

        <v-col
          cols="12"
          class="px-4 py-0 pt-1"
        >
          <v-divider
            light
          />
        </v-col>
      </v-row>

      <!-- 簡介 -->
      <v-row class="black--text">
        <v-col class="px-4">
          <div class="text-h6">
            {{ $t('service.introduction') }}
          </div>
          <div class="caption">
            {{ serviceDescription }}
          </div>

          <v-divider
            light
            class="mt-6"
          />
        </v-col>
      </v-row>

      <!-- 評分與評論 -->
      <v-row class="black--text">
        <v-col class="px-4 pt-0">
          <div class="text-h6">
            {{ $t('service.ratingsAndReviews') }}
          </div>

          <div class="d-flex">
            <!-- 評分 -->
            <div>
              <div class="text-center">
                <span class="text-h2 font-weight-bold">
                  4.9
                </span>

                <div class="grey--text darken-1 subtitle-1">
                  {{ $t('service.outOf5') }}
                </div>
              </div>
            </div>

            <!-- 評論 -->
            <div class="flex-grow-1 d-flex ml-6">
              <!-- 星星 -->
              <div class="text-right lh-12px">
                <div
                  v-for="num in [5,4,3,2,1]"
                  :key="num"
                >
                  <Icon
                    v-for="item in num"
                    :key="item"
                    data="@icon/star.svg"
                    width="8"
                    height="7"
                    color="#8e8f94"
                  />
                  <v-divider />
                </div>
              </div>

              <!-- 條狀 -->
              <div class="flex-grow-1 ">
                <div
                  v-for="item in 5"
                  :key="item"
                  class="py-1 line"
                >
                  <div
                    class="line-progress"
                    :class="[`line${item}`]"
                  />
                </div>

                <div class="mt-1 grey--text darken-1 subtitle-1 text-right">
                  {{ $t('service.25Ratings') }}
                </div>
              </div>
            </div>
          </div>

          <v-divider
            light
            class="mt-6"
          />
        </v-col>
      </v-row>

      <!-- 新功能 -->
      <v-row class="black--text">
        <v-col class="px-4 pt-0">
          <div class="text-h6">
            {{ $t('service.newFeatures') }}
          </div>

          <!-- 版本 -->
          <div class="grey--text darken-1 subtitle-2">
            {{ $t('service.version') }} 10.0
          </div>

          <div class="caption mt-1">
            {{ serviceDescription }}
          </div>

          <v-divider
            light
            class="mt-6"
          />
        </v-col>
      </v-row>

      <!-- 信息 -->
      <v-row class="black--text">
        <v-col class="px-4 pt-0">
          <div>
            <div class="text-h6 mb-3">
              {{ $t('service.information') }}
            </div>

            <div
              v-for="(value, index) in info"
              :key="index"
            >
              <div
                class="d-flex subtitle-2"
              >
                <!-- 名稱 -->
                <div class="grey--text darken-1">
                  {{ index }}
                </div>

                <v-spacer />

                <!-- 內容 -->
                <div class="text-right">
                  {{ value }}
                </div>
              </div>
              <v-divider
                light
                class="my-3"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  data: () => ({}),

  computed: {
    ...mapGetters(['baseImgUrl', 'websiteLogo', 'gradientBg', 'websiteName']),

    ...mapState({
      serviceAppTitle: state => state.service.service.title,
      serviceDescription: state => state.service.service.description,
      serviceAppIcon: state => state.service.serviceApp.icon,
      serviceAppDownloadUrl: state => state.websiteData.mobile.download_cs_profile,
    }),

    info() {
      return {
        [this.$t('service.size')]: '20MB',
        [this.$t('service.category')]: this.$t('service.entertainment'),
        [this.$t('service.compatibility')]: `IOS9.0${this.$t('service.andAboveDevices')}`,
        [this.$t('service.language')]: this.$t('service.lang'),
        [this.$t('service.age')]: '18+',
      }
    },
  },

  methods: {
    downloadServiceApp() {
      window.open(this.serviceAppDownloadUrl, 'blank')
    },
  },
}
</script>

<style lang="scss" scoped>

.app-icon {
	width: 100px;
	height: 100px;
}

.star {
	margin: 0 3px;
}

.text-h2 {
	color: #4c4b50;
}

.lh-12px {
	line-height: 10px;
}

.line {
	padding: 3px 0;
	margin-left: 10px;
	height: 11px;
}

.line-progress {
	position: relative;
	margin-top: 1px;
	width: 100%;
	height: 2px;
	background-color: #e5e5ea;
}

.line-progress.line1::after {
	position: absolute;
	top: -1px;
	left: 0;
	content: '';
	margin-top: 1px;
	width: 90%;
	height: 2px;
	background-color: #8e8e93;
}

.line-progress.line2::after {
	position: absolute;
	top: -1px;
	left: 0;
	content: '';
	margin-top: 1px;
	width: 20%;
	height: 2px;
	background-color: #8e8e93;
}

</style>
