<template>
  <v-app-bar
    fixed
    flat
    class="ma-0 px-0 py-1"
    :color="color || 'bg'"
  >
    <div
      class="rounded-l-xl rounded-r-xl d-flex align-center w-100"
      :class="bgClr"
    >
      <div class="cancelSearch">
        <!-- 取消搜尋按鈕 -->
        <slot name="searchCancelBtn" />
      </div>

      <div class="searchInput">
        <!-- 搜尋輸入框 -->
        <slot name="input" />
      </div>

      <div class="clear">
        <slot name="clearInputTest" />
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    color: {
      type: String,
      required: false,
    },
  },

  computed: {
    ...mapGetters(['layout', 'theme']),
    ...mapState({
      title: state => state.toolbar.title,
    }),

    bgClr() {
      if (this.layout === 'template3' && this.theme === 'dark') return 'black'
      else if (this.layout === 'template3' && this.theme === 'light') return 'white'
      return 'inputBg'
    },
  },

  destroyed() {
    this.set_toolbar_title('')
  },

  methods: {
    ...mapActions(['set_toolbar_title']),
  },

}
</script>

<style lang="scss" scoped>
.cancelSearch,
.clear {
	width: 48px;
}

.w-100 {
	width: 100%;
}

.searchInput {
	width: 100%;
}

</style>
