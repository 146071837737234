var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100dvh over-flow-y-scroll" },
    [
      _c("Toolbar"),
      _c(
        "v-container",
        { staticClass: "vendor-list" },
        [
          _vm.layoutData.length < 1
            ? _c(
                "v-row",
                {
                  staticClass: "white--text px-3 vh-100",
                  attrs: { justify: "center", align: "center" },
                },
                [_c("Nodata")],
                1
              )
            : _c(
                "v-row",
                { staticClass: "pt-72" },
                _vm._l(_vm.activeData.items, function (vendor) {
                  return _c(
                    "v-col",
                    {
                      key: vendor._id,
                      class: [{ "rounded pa-1": _vm.layout !== "template7" }],
                      attrs: { cols: _vm.layout === "template7" ? 4 : 6 },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vendor rounded overflow-hidden",
                          class: [
                            {
                              "shadow-round-container":
                                _vm.layout === "template7",
                            },
                            { maintenanceBox: vendor.status === "0" },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.launchGame(
                                vendor,
                                _vm.activeData.class
                              )
                            },
                          },
                        },
                        [
                          vendor.status == "0" ? _c("maintenance") : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vendor__img rounded overflow-hidden",
                              style:
                                _vm.style === "darkGD"
                                  ? { border: `solid 2px ${_vm.primaryClr}` }
                                  : "",
                            },
                            [
                              _c(
                                "div",
                                { style: _vm.gradientBg },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: _vm.baseImgUrl + vendor.image_h5,
                                      height: "119px",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "vendor__text subtitle-2 mt-1 py-2",
                              class: [
                                _vm.layout === "template7"
                                  ? "pd-5 grey-text"
                                  : "px-3 title--text",
                              ],
                            },
                            [_vm._v(" " + _vm._s(vendor.title) + " ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }