/**
 * API 吐回 errors 的錯誤訊息
 * 其他語系：菲律賓、印度、越南、泰文等，API 吐回對應語系後仍會括號加上英文(en)
 * 例：
 *    泰文會回傳：โทเค็นไม่ถูกต้อง(Token is invalid)
 *    菲律賓會回傳：Imabalido ang token(Token is invalid)
 *    越南會回傳：Mã thông báo không hợp lệ(Token is invalid)
 *    簡中會回傳：认证失败(Token is invalid)
 *
 * 需要用 includes 判斷
 *
 * @returns {String} 回傳 api message 包含的字串
 */
export const ERROR_STRING = {
  // 站台被關閉
  OPERATOR_CLOSED: 'Operator has been blocked',

  // 站台維護中
  MAINTENANCE: 'System is under maintenance',
  // 遊戲維護
  GAME_MAINTENANCE: 'Game under maintenance',

  // Token 失效情況：Token 過期、沒給 Token 給 API、Token 驗證失敗
  TOKEN_UNAUTHORIZED: 'Unauthorized',
  TOKEN_EXPIRED: 'Token is expired',
  NOT_FOUND_TOKEN: 'Authorization token not found',
  TOKEN_INVALID: 'Token is invalid',
  INVALID_AUTH_TOKEN: 'Invalid Auth Token',

  // 營運商產品商或遊戲被關閉
  GAME_LAUNCH_SWITCH_OFF: 'Operator GameLaunch switch off',
}

// 屬於 Token 失效的 API，API 回傳 errors[0].message 用來判斷
export const tokenIsExpiredList = [
  ERROR_STRING.TOKEN_UNAUTHORIZED,
  ERROR_STRING.OPERATOR_CLOSED,
  ERROR_STRING.TOKEN_EXPIRED,
  ERROR_STRING.NOT_FOUND_TOKEN,
  ERROR_STRING.TOKEN_INVALID,
  ERROR_STRING.INVALID_AUTH_TOKEN,
]
