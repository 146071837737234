var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bonusPage",
      class: [_vm.currentDevice === "ios" ? "pb-72" : "pb-56"],
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "mt-1" },
            [
              _c(
                "v-col",
                {
                  staticClass: "p-fixed pb-0 px-0 bg z-index-4",
                  attrs: { cols: "12" },
                },
                [
                  _c("h1", { staticClass: "title--text text-h6 pl-6" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("menu.preferentialActivity")) + " "
                    ),
                  ]),
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "background-color": "bg",
                        "center-active": "",
                        "show-arrows": "",
                      },
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          staticClass: "subtitle-1 font-weight-medium",
                          on: {
                            click: function ($event) {
                              return _vm.changeBonusNewsType("all")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("global.all") +
                                  _vm.$t("bottomNav.preferentialActivity")
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _vm._l(_vm.tab, function (item) {
                        return _c(
                          "v-tab",
                          {
                            key: item._id,
                            staticClass: "subtitle-1 font-weight-medium",
                            on: {
                              click: function ($event) {
                                return _vm.changeBonusNewsType(item.code)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.bonusNews.length === 0
            ? _c(
                "v-row",
                {
                  staticClass: "ma-0 pt-100",
                  attrs: { align: "center", justify: "center" },
                },
                [
                  _c(
                    "v-col",
                    [
                      _c("Nodata", {
                        attrs: { text: _vm.$t("global.noRecords") },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-row",
                { staticClass: "pt-100 px-1" },
                _vm._l(_vm.bonusNews, function (item) {
                  return _c(
                    "v-col",
                    {
                      key: item._id,
                      staticClass: "py-1",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "p-relative" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "text-decoration-none bonus z-index-3",
                              attrs: {
                                to: {
                                  name: "bonusItem",
                                  params: {
                                    id: item.promo_id,
                                  },
                                },
                              },
                            },
                            [
                              _c("div", {
                                staticClass: "p-absolute rounded z-index-1",
                                class: [{ "bonus__item-bg": item.title }],
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "bonus__more white--text w-100 d-flex justify-space-between p-absolute z-index-3",
                                },
                                [
                                  _c("div", { staticClass: "bonus__title" }, [
                                    _vm._v(" " + _vm._s(item.title) + " "),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "caption bonus__description",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("global.more")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [_vm._v(" mdi-chevron-right ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-img",
                                {
                                  staticClass: "rounded",
                                  attrs: {
                                    "min-height": "104",
                                    src: _vm.baseImgUrl + item.banner,
                                    alt: item.title,
                                  },
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "fill-height ma-0",
                                      attrs: {
                                        slot: "placeholder",
                                        align: "center",
                                        justify: "center",
                                      },
                                      slot: "placeholder",
                                    },
                                    [
                                      _c("v-progress-circular", {
                                        attrs: {
                                          indeterminate: "",
                                          color: "primary",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }