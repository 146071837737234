import request from '@/api/request'

/*
  API 頭像
  頭像相關的 API
    - 取得頭像列表
    - 設置頭像
    - 刪除客製頭像
    - 上傳客製頭像
*/

export const getAvatarList = () => request.get('/api/v1/member/avatar/list')

export const setAvatar = (data) =>
  request.post('/api/v1/member/avatar/setting', data)

export const deleteCustomAvatar = (data) =>
  request.post('/api/v1/member/avatar/delete', data)

export const uploadCustomAvatar = (data) =>
  request.post('/api/v1/member/avatar/upload', data)
