var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "vh-100 d-flex flex-column justify-center align-center px-12",
    },
    [
      _c("div", { staticClass: "text-center" }, [
        _c("img", {
          attrs: {
            width: "88",
            src: require("@/assets/image/503.png"),
            alt: 503,
          },
        }),
        _c("div", { staticClass: "hint--text" }, [
          _vm._v(" " + _vm._s(_vm.errorText) + " "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }