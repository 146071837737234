<template>
  <v-bottom-navigation
    v-model="navIndex"
    fixed
    grow
    :height="navHeight"
    class="bottom-nav"
  >
    <template v-for="(item, index) in navBarData">
      <v-btn
        v-if="item.path !== '/service'"
        :key="index"
        :height="navHeight"
        :to="item.path"
        class="tep5BtnStyle"
      >
        <img
          :src="matchRouteName(item.path, index) ? baseUrl + item.icon_active : baseUrl + item.icon"
          height="30"
        >
        <div
          class="bgblock"
          :style="{'background-color': matchRouteName(item.path, index) ? item.bgcolor2 : item.bgcolor}"
        />
      </v-btn>
      <!-- 客服模式會根據是否為 line liff 模式而不同動作
        - 一般模式：點擊後跳轉至後台串接的客服系統
        - LineLiff 模式：點擊後浮出 Line 按鈕
      -->
      <v-btn
        v-else
        :key="index"
        :height="navHeight"
        class="tep5BtnStyle"
        @click="showServiceArea(item.path)"
      >
        <template v-if="isLiffMode">
          <img
            :src="baseUrl + item.icon"
            height="30"
          >
        </template>
        <template v-else>
          <img
            :src="matchRouteName(item.path, index) ? baseUrl + item.icon_active : baseUrl + item.icon"
            height="30"
          >
        </template>

        <!-- Service Items -->
        <div
          v-show="serviceArea.status"
          class="service-items-area text-left"
        >
          <ul class="pl-0">
            <li
              v-for="(service, idx) in serviceItems"
              :key="service + idx"
              class="my-3 cursor-pointer"
              @click.stop="goService(service.name)"
            >
              <div v-if="service.name === 'Line' && showLineService || service.name === '24h'">
                <v-avatar
                  color="white"
                  size="40"
                >
                  <img
                    class="pa-2"
                    :src="service.icon"
                    alt="line-service"
                  >
                </v-avatar>
                <span class="caption ml-2 white--text">
                  {{ service.text }}
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div
          class="bgblock"
          :style="{'background-color': matchRouteName(item.path, index) ? item.bgcolor2 : item.bgcolor}"
        />
      </v-btn>
    </template>
    <v-overlay
      :value="serviceArea.status"
      opacity=".9"
    />
  </v-bottom-navigation>
</template>

<script>
import bottomModeMixins from '../mixins/bottomNavModeMixins'

export default {
  mixins: [bottomModeMixins],
}
</script>

<style lang="scss" scoped>
.v-bottom-navigation {
  background-color: transparent;
}
.websiteLogo {
  transform: translateY(-10px);
}

.service-items-area {
  position: absolute;
  bottom: 72px;
  left: 0;
  z-index: 99;

  >ul {
    list-style: none;
  }
}

.v-btn {
  width: auto;
}
.bottom-nav{
  box-shadow: 0 0 2px #808daa;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}
.tep5BtnStyle{
  padding: 0 !important;
  position: relative;
  background-color: transparent !important;
  .bgblock{
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
  }
  img{
    position: relative;
    z-index: 1;
  }
}
</style>
