<template>
  <!-- Telegram Token 過期頁面 -->
  <div class="vh-100 d-flex flex-column justify-center align-center px-12">
    <div class="text-center">
      <img
        width="88"
        :src="require('@/assets/image/503.png')"
        :alt="503"
      >
      <div class="hint--text">
        {{ errorText }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    /**
     * 錯誤信息文字
     * @date 2021-09-08
     */
    errorText() {
      return this.$t('global.telegramLinkExpired')
    },
  },
}
</script>

<style lang="scss" scoped></style>
