var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "vh-100 d-flex flex-column justify-center align-center px-10",
    },
    [
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("Icon", {
            attrs: {
              data: require("@icon/alert/error.svg"),
              width: "60",
              height: "60",
              color: "red",
            },
          }),
          _c("h1", [_vm._v("403")]),
          _c("span", [_vm._v("Forbidden")]),
        ],
        1
      ),
      _c("div", { staticClass: "text-center caption my-5" }, [
        _vm._v(" 很抱歉，站台不再提供服务 "),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.errorText) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }