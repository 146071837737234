<template>
  <v-app-bar
    fixed
    flat
    class="pa-0 ma-0"
    :color="color || bgClr"
  >
    <!-- 回上一頁 -->
    <v-btn
      v-if="hasToken"
      icon
      class="pa-0"
      @click="goBack"
    >
      <Icon
        data="@icon/toolbar/arrow_left.svg"
        width="16px"
        height="16px"
        class="icon--text"
      />
    </v-btn>

    <!-- 標題文字 -->
    <v-toolbar-title class="title--text font-weight-medium">
      {{ title }}
    </v-toolbar-title>
    <v-spacer />

    <!-- button -->
    <slot name="btn" />
  </v-app-bar>
</template>

<script>
import ToolbarMixin from '../mixins/ToolbarMixin'

export default {
  mixins: [ToolbarMixin],
}
</script>

<style lang="scss" scoped>

</style>
