import { getGameEvent } from './gameEvent.js'

import {
  getWtoken,
  getNews,
  getNavbar,
  getFooter,
  getMarquee,
  getBanner,
  getSiteInfo,
  getDocument,
  getWebsiteStyle,
  getWebsiteIndexLayout,
  getDocumentDetails,
  getServiceUrl,
  getMemberNews,
  updateMemberNewsStatus,
  getAppDownloadUrl,
  getDTListData,
  qrcodeLogin,
  getGiftImage,
  getBottomNavData,
  pageVisitSend,
} from './base'

import {
  getAvatarList,
  setAvatar,
  deleteCustomAvatar,
  uploadCustomAvatar,
} from './avatar'

import {
  getBankCardList,
  getUsdtList,
  deleteBankCard,
  deleteUsdtCard,
  addBankCard,
  addUsdtCard,
  getBankList,
} from './banks'

import {
  getBonusList,
  getVipList,
  getVipBonus,
  getVipBonusExtra,
  userBonusButtonStatus,
  checkUserBonusBtnStatus,
  userGetBonus,
  monthlyBonusReceive,
} from './bonus'

import {
  getTransactionRecord,
  getPaymentRecord,
  paymentReport,
  getPaymentInfo,
  getUserredeemRecord,
  getUserMemberSubtypeList,
  getRecommendBonus,
  getWagerRecord,
} from './records'

import { getPaymentList, deposit, withdraw, setWithdrawPw, getPaymentListPipeline } from './trade'

import {
  userLogin,
  tgLogin,
  userLogout,
  userRegister,
  userRecommand,
  editUserPassword,
  getUserVipData,
  getUserInfo,
  editUserInfo,
  getUserWalletDetail,
  userDataRefresh,
  sendEmailValidateCode,
  validateEmailValidateCode,
  resetUserPasswordByNoLogin,
  googleAuthLogin,
  appleAuthLogin,
  lineAuthLogin,
} from './user'

import {
  getWalletListNew,
  getWalletList,
  getWalletBalance,
  changeWalletMode,
  retrieveGameWallets,
  allTransferInGameWallets,
  walletTransfer,
  eventwalletDetails,
  unlockEventAwards,
} from './wallet'

import { getMobileSms, verifyMobileSms, getPhoneCountryCode } from './sms'

import {
  getVendorLogo,
  getVendorGameList,
  getVendorHotGameList,
  getVendorNewGameList,
  getVendorFavorGameList,
  editFavorGame,
  getGameLaunchUrl,
  getGameLaunchUrlByDevice,
} from './egame'

/* 雷火直播 */
export const getGameEventAPI = getGameEvent

/* base 網站資訊 */
export const getGiftImageAPI = getGiftImage
export const getWtokenAPI = getWtoken // 根據 Domain 取得 wtoken
export const getNewsAPI = getNews // 取得 News
export const getMemberNewsAPI = getMemberNews // 取得站內信
export const updateMemberNewsStatusAPI = updateMemberNewsStatus // 更新站內信狀態
export const pageVisitSendAPI = pageVisitSend // 頁面訪問紀錄
export const getNavbarAPI = getNavbar // 取得 選單
export const getFooterAPI = getFooter // 取得 Footer
export const getMarqueeAPI = getMarquee // 取得 跑馬燈
export const getBannerAPI = getBanner // 取得 輪播圖
export const getSiteInfoAPI = getSiteInfo // 取得 基本設定
export const getDocumentAPI = getDocument // 取得新手幫助內的文字
export const getDocumentDetailsAPI = getDocumentDetails // 取得新手幫助 依類別取得
export const getAppDownloadUrlAPI = getAppDownloadUrl
export const getBottomNavDataAPI = getBottomNavData

export const getWebsiteStyleAPI = getWebsiteStyle // 取得網站主題色與深淺
export const getWebsiteIndexLayoutAPI = getWebsiteIndexLayout // 取得主頁layout版塊

export const getServiceUrlAPI = getServiceUrl // 取得客服連結

export const getDTListDataAPI = getDTListData // 取得 龍虎榜資料

/* avatar 頭像 */
export const getAvatarListAPI = getAvatarList
export const setAvatarAPI = setAvatar
export const deleteCustomAvatarAPI = deleteCustomAvatar
export const uploadCustomAvatarAPI = uploadCustomAvatar

/* bank 銀行 */
export const getBankCardListAPI = getBankCardList
export const getUsdtListAPI = getUsdtList
export const deleteBankCardAPI = deleteBankCard
export const deleteUsdtCardAPI = deleteUsdtCard
export const addBankCardAPI = addBankCard
export const addUsdtCardAPI = addUsdtCard
export const getBankListAPI = getBankList

/* bonus 優惠 */
export const getBonusListAPI = getBonusList
export const getVipListAPI = getVipList
export const getVipBonusAPI = getVipBonus
export const getVipBonusExtraAPI = getVipBonusExtra
export const userBonusButtonStatusAPI = userBonusButtonStatus
export const checkUserBonusBtnStatusAPI = checkUserBonusBtnStatus
export const userGetBonusAPI = userGetBonus
export const monthlyBonusReceiveAPI = monthlyBonusReceive

/* record 紀錄 */
export const getTransactionRecordAPI = getTransactionRecord
export const getPaymentRecordAPI = getPaymentRecord
export const paymentReportAPI = paymentReport
export const getPaymentInfoAPI = getPaymentInfo
export const getUserredeemRecordAPI = getUserredeemRecord
export const getUserMemberSubtypeListAPI = getUserMemberSubtypeList
export const getRecommendBonusAPI = getRecommendBonus
export const getWagerRecordAPI = getWagerRecord

/* trade 交易 */
export const getPaymentListAPI = getPaymentList
export const depositAPI = deposit
export const withdrawAPI = withdraw
export const setWithdrawPwAPI = setWithdrawPw
export const getPaymentListPipelineAPI = getPaymentListPipeline

/* user 使用者 */
export const userLoginAPI = userLogin
export const tgLoginAPI = tgLogin
export const userDataRefreshAPI = userDataRefresh
export const userLogoutAPI = userLogout
export const userRegisterAPI = userRegister
export const userRecommandAPI = userRecommand
export const editUserPasswordAPI = editUserPassword
export const getUserVipDataAPI = getUserVipData
export const getUserInfoAPI = getUserInfo
export const editUserInfoAPI = editUserInfo
export const getUserWalletDetailAPI = getUserWalletDetail
export const sendEmailValidateCodeAPI = sendEmailValidateCode
export const validateEmailValidateCodeAPI = validateEmailValidateCode
export const resetUserPasswordByNoLoginAPI = resetUserPasswordByNoLogin
export const googleAuthLoginAPI = googleAuthLogin
export const appleAuthLoginAPI = appleAuthLogin
export const lineAuthLoginAPI = lineAuthLogin

/* wallet 錢包 */
export const getWalletListAPI = getWalletList
export const getWalletBalanceAPI = getWalletBalance
export const getWalletListNewAPI = getWalletListNew
export const changeWalletModeAPI = changeWalletMode
export const retrieveGameWalletsAPI = retrieveGameWallets
export const allTransferInGameWalletsAPI = allTransferInGameWallets
export const walletTransferAPI = walletTransfer

/* 活動錢包 */
export const eventwalletDetailsAPI = eventwalletDetails // 活動錢包內容
export const unlockEventAwardsAPI = unlockEventAwards // 解鎖活動獎勵

// egame 遊戲
export const getVendorLogoAPI = getVendorLogo // 取得產品商所有游戲列表
export const getVendorGameListAPI = getVendorGameList // 取得vendor (全部) 遊戲
export const getVendorHotGameListAPI = getVendorHotGameList // 取得vendor(熱門)遊戲
export const getVendorNewGameListAPI = getVendorNewGameList // 取得vendor(最新)遊戲
export const getVendorFavorGameListAPI = getVendorFavorGameList // 取得vendor(收藏/最愛)遊戲
export const editFavorGameAPI = editFavorGame // (新增/移除) 使用者收藏的遊戲
export const getGameLaunchUrlAPI = getGameLaunchUrl // 取得遊戲連結
export const getGameLaunchUrlByDeviceAPI = getGameLaunchUrlByDevice // 取得遊戲連結 @依裝置 (navbar)

// SMS 驗証發送
export const getMobileSmsAPI = getMobileSms
export const verifyMobileSmsAPI = verifyMobileSms
export const getPhoneCountryCodeAPI = getPhoneCountryCode

export const qrcodeLoginAPI = qrcodeLogin
