<template>
  <div>
    <img
      class="cursor-pointer"
      :src="require('@/assets/image/fifa-world-cup.webp')"
      alt="fifa-world-cup"
      width="100%"
      @click="worldCupImgEvent"
    >
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {

  computed: {
    ...mapState({
      worldCupUrl: state => state?.websiteData?.worldcup_info?.worldcup_schedule_url || '',
    }),
  },

  methods: {
    ...mapActions(['show_alert']),

    worldCupImgEvent() {
      if (!this.worldCupUrl) {
        return this.show_alert({
          icon: 'fail',
        })
      }

      this.$router.push({
        path: 'fifa',
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
