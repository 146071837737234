// 優惠活動頁面
import isLocalDev from '../../utils/isLocalDev'

import {
  getBonusListAPI,
  checkUserBonusBtnStatusAPI,
  userGetBonusAPI,
  monthlyBonusReceiveAPI,
} from '@/api'

export const state = () => ({
})

export const getters = {
}

export const mutations = {

}

export const actions = {
  get_website_url() {
    // 是否本地開發
    const website = isLocalDev()
      ? process.env.VUE_APP_WEBSITE
      : location.host
        .replace('m.', '')
        // .replace('www.', '')
        .replace('v2.', '')
    return website
  },

  /**
   * 取得優惠活動列表
   * @date 2021-03-15
   * @param {any} {commit}
   * @param {any} bonusNewsSearchRange 優惠活動 搜尋範圍
   * @returns {any} 響應內容
   */
  async get_bonus_news({ dispatch, commit }, bonusNewsSearchRange) {
    const website = await dispatch('get_website_url')
    bonusNewsSearchRange.website = website
    const res = await getBonusListAPI(bonusNewsSearchRange)
    return res
  },

  /**
   * 確認按鈕是否顯示 (參加/領取)
   * @date 2021-03-16
   * @param {any} {commit}
   * @param {string} promo_id 促銷代碼
   * @returns {object} 響應
   */
  async check_bonus_button({ commit }, promo_id) {
    const res = await checkUserBonusBtnStatusAPI({ promo_id })
    return res
  },

  /**
   * 領取優惠
   * @date 2021-03-16
   * @param {any} {commit}
   * @param {object} promoData promo_id 與 type (apply / redeem)
   * @returns {object} 響應內容
   */
  async get_bonus({ commit }, promoData) {
    const res = await userGetBonusAPI(promoData)
    return res
  },

  // 我要領取 (每月)
  async monthly_bonus_receive(context, promoId) {
    const res = await monthlyBonusReceiveAPI(promoId)
    return res
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
