/* ==================================
語言檔，請都由 翻譯檔 excel
https://docs.google.com/spreadsheets/d/1ddyiqaSmp5kkHz_orWde8EPigrN60EYw/edit#gid=540738104
修改後匯入，不直接修改 json !!

修改完 `npm run lang` 即可
================================== */

import Vue from 'vue'
import VueI18n from 'vue-i18n'

import CN from './config/cn.json'
import EN from './config/en.json'
import TH from './config/th.json'
import VN from './config/vn.json'
import TW from './config/tw.json'
import PH from './config/ph.json'
import IN from './config/in.json'
import HIIN from './config/hi-IN.json'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'zh-CN',
  messages: {
    'zh-CN': CN,
    'zh-TW': TW,
    'zh-HK': TW,
    en: EN,
    'vi-VN': VN,
    th: TH,
    ph: PH,
    in: IN,
    'hi-IN': HIIN,
  },
})
