const Join01 = () => import('./Join01.vue')
const Join02 = () => import('./Join02.vue')
const Join03 = () => import('./Join03.vue')
const Join04 = () => import('./Join04.vue')
const Join05 = () => import('./Join05.vue')
const Join06 = () => import('./Join06.vue')

export default {
  Join01,
  Join02,
  Join03,
  Join04,
  Join05,
  Join06,
}
