import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { getCookie } from '../utils/cookie'
import Vue from '../main'
import {
  ERROR_STRING,
} from '@/utils/api/apiState'
// 處理 axios 錯誤
import {
  isNotInLineLiffMode,
  isTokenExpired,
  handleHttpStatus,
  handleTelegramTokenExpired,
  handleStatusCodeError,
  sendLogToLine,
} from '@/utils/api/axiosErrorHandlers'

/*
  發出每個請求前，如果有 accessToken，會在 Header 加上 Bearer + accessToken
  會員登入後會取得 accessToken
  accessToken 是後端用來辨識是哪一個會員
*/

/* -------------訪api資料實體------------------ */
const request = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 50000, // request timeout
})

/* ------------- 取得雷火 實體 ------------------ */
export const gameEvent = axios.create({
  baseURL: process.env.VUE_APP_TFG_API,
  headers: {
    Authorization: process.env.VUE_APP_TFG_AUTH,
  },
  timeout: 50000, // request timeout
})

// 請求處理預處理
request.interceptors.request.use(
  config => {
    /* wtoken 是後端用來辨識是哪一間營運商，一定要在 headers 加上 wtoken */
    if (sessionStorage.getItem('wtoken')) {
      config.headers.wtoken = sessionStorage.getItem('wtoken')
    }

    // 直接使用 tgToken 登入
    if (config.url === 'api/v1/auth/token-verify' && config?.data) {
      config.headers.Authorization = `Bearer ${config?.data}`
    } else if (getCookie('accessToken')) {
      // 如果有登入過，Headers 加入 Authorization 設定 token
      config.headers.Authorization = 'Bearer ' + getCookie('accessToken')
    }

    // 如果前端語系有切換語系，帶入 lang 值
    const clientLang = sessionStorage.getItem('clientLang')
    if (clientLang) config.headers.lang = clientLang

    return config
  },
  error => {
    Vue.$error(error)
    return Promise.reject(error)
  },
)

// 響應預處理
request.interceptors.response.use(
  async(response) => {
    /* 開發模式，確認 res */
    Vue.$log(
      `%c✅ Response:%c${response.config.url}`,
      'background: deepskyblue; padding: 3px;',
      'padding: 3px;',
      response?.data,
    )

    try {
      // 清空 status error code
      store.dispatch('set_status_error_code', null)

      const data = response.data
      const { errors } = data
      const responseHasErrors = errors && Array.isArray(errors) && errors?.length > 0

      // response API 回傳有 errors: [...] 時
      if (responseHasErrors) {
        /* 如果 status: '0' 有錯誤信息 status === '0' */
        Vue.$error('Response Fail:' + errors[0])

        /*
          根據 Response 回應的特定狀態
          讓 USER 跳轉指定頁面

          1. 營運商狀態被關閉
          2. 系統維護中
        */

        // 營運商狀態被關閉，跳轉到 403 不提供站台服務 (禁止訪問)
        if (errors[0].includes(ERROR_STRING.OPERATOR_CLOSED)) {
          handleStatusCodeError(403)
          return
        }

        // 系統維護中，跳轉到 503 頁
        if (errors[0].includes(ERROR_STRING.MAINTENANCE)) {
          handleStatusCodeError(503)
          return
        }

        /*
          為了避免一直被登出，需要額外判斷例外情況
          由於 API 只會回傳狀態 status: '0'，只能靠 message 判斷
          加上 API 有 i18n 語系關係，無法用 message === errors[0]
          必須用 includes 判斷

          例外情況
            - 營運商產品商或遊戲被關閉
            - 遊戲維護中
        */
        if (errors[0] === ERROR_STRING.GAME_LAUNCH_SWITCH_OFF ||
          errors[0] === ERROR_STRING.GAME_MAINTENANCE) {
          return data
        }

        // Token 過期或失效 && 站台非 Line Liff 模式
        if (isTokenExpired(errors) && isNotInLineLiffMode()) {
          const checkTgTokenInURL = () => {
            const url = new URL(location.href)
            const search = new URLSearchParams(url.search)
            return search.get('tgToken')
          }

          // 重置使用者資料
          store.dispatch('reset_user_data')

          /*
            tgToken: 使用者透過 telegram 連結網址，會帶 tgToken 自動登入
            由於：router.currentRoute.query.tgToken 是空的抓不到
            改成用 URLSearchParams 判斷
          */
          const hasTgToken = checkTgTokenInURL()

          // 網址 query 有帶 tgToken，且 token 過期
          if (hasTgToken) {
            // 網址列帶有 tgToken
            await handleTelegramTokenExpired(response, Vue)
            return
          } else {
            if (router?.currentRoute?.name === 'qrcode') return false

            // 請重新登入
            store.dispatch('show_alert', {
              icon: 'fail',
              text: Vue.$t('subHeader.reLogin'),
            })

            if (router.currentRoute.name === 'login') return false

            setTimeout(() => {
              Vue.$router.push({ name: 'login' }).catch(() => {})
            }, 1000)
          }
        }
      }

      return data
    } catch (jsError) {
      // 捕捉到錯誤
      Vue.$error('[request.js]:159 DEBUG: ⛔ 請求發生錯誤：' + jsError)
      // 統一將參數格式化
      const params = {
        title: 'Axios try...catch 到錯誤，發生異常 (request.js:174)',
        state: store?.state,
        requestHeaders: response?.config?.headers,
        data: response?.config?.data,
        httpStatus: response?.status,
        apiUrl: response?.config?.url,
        error: jsError,
      }

      await sendLogToLine(params)
    }
  },
  async error => {
    // 捕捉到錯誤，像是 500 錯誤，會執行以下程式碼
    Vue.$error('[request.js:184] DEBUG: ⛔ 請求發生錯誤：' + error)

    try {
      // [now] 統一將參數格式化
      const basicParams = {
        title: '--',
        state: store?.state,
        data: error?.config?.data,
        requestHeaders: error?.response?.config?.headers,
        httpStatus: error?.response?.status,
        apiUrl: error?.config?.url,
        error,
      }

      if (error?.response) {
        if (error?.message === 'Network Error') {
          // 處理 Network Error 異常
          await sendLogToLine(
            { ...basicParams, title: error?.message },
          )
        } else {
          // 處理一般 Response 異常
          await sendLogToLine(
            { ...basicParams, title: '請求 API 發生異常，觸發 axios error (request.js:211)' },
          )
        }
      }

      /*
        處理 HTTP 錯誤狀態
        跳轉至錯誤頁面 (Error Page)
      */
      await handleHttpStatus(basicParams, error)

      return Promise.reject(error)
    } catch (jsError) {
      // 捕捉到錯誤
      Vue.$error('[request.js:222] DEBUG: ⛔ 請求發生錯誤：' + jsError)

      // 建立通知訊息
      const params = {
        title: 'Axios try...catch 到錯誤，發生異常 (request.js:230)',
        state: store?.state,
        data: error?.config?.data,
        requestHeaders: error?.response?.config?.headers,
        httpStatus: error?.response?.status,
        apiUrl: error?.config?.url,
        error: jsError,
      }

      // 建立通知訊息
      await sendLogToLine(params)
    }
  },
)

export default request
