<template>
  <div>
    <components
      :is="componentAry[model]"
    />

    <!-- bottom nav -->
    <v-bottom-navigation
      :value="model"
      color="primary"
      class="navBar"
      grow
      fixed
    >
      <v-container
        fluid
      >
        <v-row>
          <v-col
            cols="6"
            class="pa-0"
          >
            <v-btn
              class="pa-0 navBar"
              height="56"
              block
              @click="model = 0"
            >
              <Icon
                data="@icon/bottomNav/service.svg"
                width="24px"
                height="24px"
              />
            </v-btn>
          </v-col>

          <v-col
            cols="6"
            class="pa-0"
          >
            <v-btn
              block
              class="pa-0 navBar"
              height="56"
              @click="model = 1"
            >
              <Icon
                data="@icon/app.svg"
                width="24px"
                height="24px"
              />
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-bottom-navigation>
  </div>
</template>

<script>
import Service from './components/Service.vue'
import App from './components/App.vue'

export default {
  components: {
    Service,
    App,
  },

  data: () => ({
    componentAry: ['Service', 'App'],
    model: 0,
  }),
}
</script>

<style lang="scss" scoped>

</style>
