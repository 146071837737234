var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "userInfo user bg d-flex align-center px-4 p-relative user-info",
    },
    [
      _c(
        "div",
        { staticClass: "user-area" },
        [
          !_vm.isLogin
            ? [
                _c(
                  "div",
                  { staticClass: "no-login-block grey-text" },
                  [
                    !_vm.isLiffMode
                      ? _c(
                          "router-link",
                          {
                            staticClass: "title--text",
                            attrs: { to: "/login" },
                          },
                          [_vm._v(" 您还未登录 ")]
                        )
                      : _vm._e(),
                    _c("br"),
                    _c(
                      "span",
                      { staticClass: "caption" },
                      [
                        !_vm.isLiffMode
                          ? _c("router-link", { attrs: { to: "/login" } }, [
                              _vm._v(_vm._s(_vm.$t("subHeader.login"))),
                            ])
                          : _vm._e(),
                        _c("span", [_vm._v("/")]),
                        _vm.registerStatus
                          ? _c("router-link", { attrs: { to: "/register" } }, [
                              _vm._v(_vm._s(_vm.$t("subHeader.register"))),
                            ])
                          : _vm._e(),
                        _vm._v("后查看 "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "pl-0 login-block grey-text" },
                  [
                    _vm.isLiffMode
                      ? [
                          _vm.lineAvatar
                            ? [
                                _c(
                                  "router-link",
                                  { attrs: { to: "/user" } },
                                  [
                                    _c("v-avatar", [
                                      _c("img", {
                                        attrs: {
                                          size: "20",
                                          src: _vm.lineAvatar,
                                          alt: _vm.lineDisplayName,
                                        },
                                      }),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c("Icon", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.lineIsLogin,
                                      expression: "!lineIsLogin",
                                    },
                                  ],
                                  staticClass: "icon--text",
                                  attrs: {
                                    data: require("@icon/user.svg"),
                                    width: "24",
                                    height: "24",
                                  },
                                }),
                              ],
                          _c(
                            "span",
                            { staticClass: "title--text caption ml-2" },
                            [_vm._v(" " + _vm._s(_vm.lineDisplayName) + " ")]
                          ),
                        ]
                      : [
                          _c(
                            "div",
                            { staticClass: "user-balance d-flex align-center" },
                            [
                              _vm.userBalanceProgressStatus ||
                              _vm.balanceLoading
                                ? _c(
                                    "div",
                                    [
                                      _c("v-progress-circular", {
                                        staticClass: "mr-4",
                                        attrs: {
                                          indeterminate: "",
                                          width: 2,
                                          size: 20,
                                          color: "primary",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticStyle: { "line-height": "0" } },
                                    [
                                      _vm.isLiffMode
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass:
                                                      "text-decoration-none",
                                                    attrs: {
                                                      to: "/user/transfer",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-1 title--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.currencySymbol_
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-h5 title--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.userBalance
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "mr-1 title--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.currencySymbol_
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-h5 title--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.userBalance
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "caption" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "myCenter.mainWallet"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                    ],
                                    2
                                  ),
                            ]
                          ),
                        ],
                  ],
                  2
                ),
              ],
        ],
        2
      ),
      _c("v-spacer"),
      _c(
        "div",
        { staticClass: "user-links d-flex align-center" },
        [
          !_vm.isLiffMode
            ? _c(
                "v-btn",
                {
                  staticClass: "px-2",
                  attrs: { text: "", height: "56" },
                  on: {
                    click: function ($event) {
                      return _vm.goPageFunc("user/deposit")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column align-center justify-center title--text caption",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-h-28 d-flex justify-center align-center",
                        },
                        [
                          _c("Icon", {
                            staticClass: "secondary--text",
                            attrs: {
                              data: require("@icon/user/walletItem/deposit2.svg"),
                              width: "70",
                              height: "70",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" " + _vm._s(_vm.$t("global.deposit")) + " "),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          !_vm.isLiffMode
            ? _c(
                "v-btn",
                {
                  staticClass: "px-2",
                  attrs: { to: "user/withdrawal", text: "", height: "56" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column align-center justify-center title--text caption",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-h-28 d-flex justify-center align-center",
                        },
                        [
                          _c("Icon", {
                            staticClass: "secondary--text",
                            attrs: {
                              data: require("@icon/user/walletItem/withdrawal2.svg"),
                              width: "70px",
                              height: "70px",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" " + _vm._s(_vm.$t("global.withdraw")) + " "),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          !_vm.isLiffMode
            ? _c(
                "v-btn",
                {
                  staticClass: "px-2",
                  attrs: { to: "user/transfer", text: "", height: "56" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column align-center justify-center title--text caption",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-h-28 d-flex justify-center align-center",
                        },
                        [
                          _c("Icon", {
                            staticClass: "secondary--text",
                            attrs: {
                              data: require("@icon/user/walletItem/transfer2.svg"),
                              width: "70px",
                              height: "70px",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" " + _vm._s(_vm.$t("global.transferBtn")) + " "),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          !_vm.isLiffMode && _vm.showVip
            ? _c(
                "v-btn",
                {
                  staticClass: "px-2",
                  attrs: { text: "", height: "56", to: "user/vip" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column align-center justify-center title--text caption",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-h-28 d-flex justify-center align-center",
                        },
                        [
                          _c("Icon", {
                            staticClass: "secondary--text",
                            attrs: {
                              data: require("@icon/user/walletItem/vip.svg"),
                              width: "70",
                              height: "70",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" " + _vm._s("vip") + " "),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }