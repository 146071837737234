<template>
  <!-- 电子遊艺/分類列表 -->
  <div
    id="slot"
    v-scroll.self="onScroll"
    class="pt-56 pb-10 h-100dvh over-flow-y-scroll"
  >
    <!-- General Mode 一般模式 -->
    <template v-if="mode === 'general'">
      <!-- Toolbar -->
      <component :is="layoutTemplate">
        <!-- 切換搜尋模式 -->
        <v-btn
          slot="btn"
          icon
          @click="setSearchMode"
        >
          <Icon
            data="@icon/search.svg"
            width="24"
            height="24"
            class="white--text"
          />
        </v-btn>
      </component>
      <v-container>
        <v-row class="p-sticky top-0 z-index-1">
          <v-col
            cols="12"
            class="py-0 px-0"
          >
            <!-- TAB -->
            <v-tabs
              v-model="tab"
              grow
              background-color="bg"
              color="primary"
            >
              <v-tab
                v-for="item in tabItem"
                :key="item"
                class="px-0"
              >
                <span class="subtitle-1">{{ item }}</span>
              </v-tab>
            </v-tabs>
            <v-divider class="divider" />
          </v-col>
        </v-row>

        <!-- 如果沒有資料 -->
        <template v-if="games.length === 0">
          <v-row>
            <v-col
              cols="12"
              class="mt-10"
            >
              <Nodata
                class="mt-10"
                :text="$t('game.noGames', { type: tabItem[tab] })"
              />
            </v-col>
          </v-row>
        </template>

        <!-- 如果有遊戲 -->
        <template v-else>
          <GameList
            :games="games"
            :scroll-bottom="scrollBottom"
            @favorite-game-toggle="favoriteGameToggle"
          />
        </template>
      </v-container>
    </template>

    <!-- SEARCH MODE 搜尋模式 -->
    <div v-show="mode === 'search'">
      <SearchBar>
        <!-- 取消搜尋按鈕 -->
        <v-btn
          slot="searchCancelBtn"
          icon
          @click="setGeneralMode"
        >
          <template v-if="layout === 'template3'">
            <Icon
              data="@icon/toolbar/toolbar_goback_arrow.svg"
              width="16px"
              height="16px"
              class="icon--text"
            />
          </template>

          <template v-else>
            <Icon
              data="@icon/toolbar/arrow_left.svg"
              width="16px"
              height="16px"
              class="icon--text"
            />
          </template>
        </v-btn>

        <!-- 搜尋輸入框 -->
        <v-text-field
          id="searchInput"
          slot="input"
          v-model.trim="form.filter"
          autofocus
          class="pa-0"
          hide-details
          height="36"
          :placeholder="$t('game.searchGame')"
          rounded
          autocomplete="off"
          dense
        />

        <!-- 刪除輸入文字 -->
        <v-btn
          v-if="form.filter"
          slot="clearInputTest"
          icon
          @click="clearFilterKeyword"
        >
          <v-icon size="16">
            mdi-close-circle
          </v-icon>
        </v-btn>
      </SearchBar>

      <v-container>
        <v-row class="px-1">
          <v-col class="caption comment--text">
            <!-- 未搜尋 -->
            <div
              v-if="!searchResolve"
              class="search__descrition-unsearch"
            >
              <!-- 请输入关键字进行搜索 -->
              {{ $t('game.PleaseEnterKeywordToSearch') }}
            </div>
            <!-- 已搜尋 -->
            <div
              v-else
              class="search__descrition-search"
            >
              <!-- 共搜索到 -->
              {{ $t('game.resultsFound') }}
              <span class="primary--text subtitle-2">
                {{ games.length }}
              </span>
              <!-- 条关于“花”的结果 -->
              {{ $t('game.aboutKeyword', { keyword: form.filter }) }}
            </div>
          </v-col>
        </v-row>

        <!-- 如果沒有遊戲 -->
        <template v-if="games.length === 0 && searchResolve">
          <NodataBig class="mt-11 pt-11" />
        </template>

        <!-- 如果有搜尋到遊戲 -->
        <template v-else>
          <GameList
            :games="games"
            :scroll-bottom="scrollBottom"
            @favorite-game-toggle="favoriteGameToggle"
          />
        </template>
      </v-container>
    </div>
  </div>
</template>

<script>
import slotMixin from '../mixins/slotMixin'

export default {
  mixins: [slotMixin],
}
</script>

<style lang="scss" scoped>
.top-0 {
	top: 0;
}
</style>
