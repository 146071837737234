var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "overlay-opacity": ".8",
        "max-width": _vm.width,
        persistent: _vm.persistentStatus,
      },
      model: {
        value: _vm.status,
        callback: function ($$v) {
          _vm.status = $$v
        },
        expression: "status",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "bg d-flex flex-column containerStyle",
          attrs: { height: _vm.height },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "headline primary pa-0" },
            [_vm._t("title")],
            2
          ),
          _c("v-card-text", { staticClass: "px-0 pb-0" }, [_vm._t("text")], 2),
          _c("v-spacer"),
          _c("v-card-actions", [_c("v-spacer"), _vm._t("btn")], 2),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }