var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      staticClass: "py-2",
      class: [
        { "px-3": _vm.layout !== "template7" && !_vm.activePage },
        { "game-list": _vm.layout === "template7" },
        { "mx-0": _vm.activePage },
      ],
    },
    [
      _vm._l(_vm.games, function (game) {
        return _c(
          "v-col",
          {
            key: game._id,
            class: [
              {
                "px-1 py-1 p-relative":
                  _vm.layout !== "template7" && !_vm.activePage,
              },
              { "px-0 py-1": _vm.activePage },
            ],
            attrs: {
              cols: _vm.layout === "template7" || _vm.activePage ? 4 : 6,
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "overflow-hidden",
                class: [
                  { "shadow-round-container": _vm.layout === "template7" },
                  { maintenanceBox: game.status === "0" },
                  { "pl-2": _vm.activePage },
                ],
              },
              [
                game.status == "0" ? _c("maintenance") : _vm._e(),
                _c(
                  "v-img",
                  {
                    class: [
                      {
                        "cursor-pointer": game.status === "1",
                        "opacity-1": game.status === "0",
                      },
                      _vm.activePage ? "rounded-xl" : "rounded",
                    ],
                    attrs: {
                      height: _vm.activePage ? 90 : 110,
                      src: _vm.baseImgUrl + game.game.image,
                      alt: game.localized_name,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.launchGame(game.game_code, game.status)
                      },
                    },
                  },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "fill-height ma-0",
                        attrs: {
                          slot: "placeholder",
                          align: "center",
                          justify: "center",
                        },
                        slot: "placeholder",
                      },
                      [
                        _c("v-progress-circular", {
                          attrs: { indeterminate: "", color: "primary" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "description d-flex align-center",
                    class: _vm.activePage ? "pt-1" : "py-2",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "description__name subtitle-2",
                        class: [
                          { errorClass: game.status === "0" },
                          { "pd-5": _vm.layout === "template7" },
                          _vm.layout === "template7" || _vm.activePage
                            ? " grey-text"
                            : "px-3 pl-3 pr-1 title--text",
                        ],
                      },
                      [_vm._v(" " + _vm._s(game.localized_name) + " ")]
                    ),
                    _c("v-spacer"),
                    _vm.layout !== "template7" && !_vm.activePage
                      ? _c(
                          "div",
                          { staticClass: "description__icon" },
                          [
                            game.favor === "true" || game.favor === true
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "", small: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit(
                                          "favorite-game-toggle",
                                          game.game_code
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass:
                                        "danger--text description__icon-favorite",
                                      attrs: {
                                        data: require("@icon/favorite.svg"),
                                        width: "20",
                                        height: "20",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : game.favor === "false"
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "", small: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit(
                                          "favorite-game-toggle",
                                          game.game_code
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass:
                                        "description__icon-unfavorite",
                                      class:
                                        _vm.layout === "template3"
                                          ? "danger--text"
                                          : "textfield--text",
                                      attrs: {
                                        data: require("@icon/unfavorite.svg"),
                                        width: "20",
                                        height: "20",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        )
      }),
      _c(
        "v-col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.scrollBottom.progress,
              expression: "scrollBottom.progress",
            },
          ],
          staticClass: "text-center pb-0",
          attrs: { cols: "12" },
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }