/*
  LINE Notify 通知模組
*/
import dayjs from 'dayjs'
import { pushLineNotifyAPI } from '../api/line'

export const checkApiUrlEnv = process.env.VUE_APP_API.includes(
  'giocoplus.gf-gaming',
)
  ? 'Dev'
  : process.env.VUE_APP_API.includes('staging')
    ? 'Staging'
    : 'Master'

// 若是本地 local 環境，則抓另一個環境變數，回傳目前請求的 API 環境
export const transferNodeEnv = () => {
  return process.env.NODE_ENV === 'local'
    ? checkApiUrlEnv
    : process.env.NODE_ENV
}

const handleHeader = requestHeaders => {
  if (!requestHeaders) return '--'

  // 移除預設不需要特別看到的資訊
  requestHeaders['cache-control'] && delete requestHeaders['cache-control']
  requestHeaders['content-type'] && delete requestHeaders['content-type']
  requestHeaders['Content-Type'] && delete requestHeaders['Content-Type']
  requestHeaders.Accept && delete requestHeaders.Accept

  // 簡化 token 的值
  if (requestHeaders?.Authorization) {
    const simplified =
      requestHeaders.Authorization.substr(0, 15) +
      '...(省略)...' +
      requestHeaders.Authorization.substr(-7)
    requestHeaders.Authorization = simplified
  }

  const headers =
    Object.keys(requestHeaders).length > 0
      ? JSON.stringify(requestHeaders)
      : '--'

  return headers
}

/**
 * 判斷是否為 Laravel 傳的預設錯誤訊息
 * @param {Object} obj
 * @param {Object} obj.request
 * @param {Object} obj.config
 * @param {Object} obj.isAxiosError
 * @param {Object} obj.toJSON
 * @param {Object} obj.response
 * @param {String} obj.response.message
 * @param {String} obj.response.exception
 * @param {String} obj.response.file
 * @param {String} obj.response.line
 * @param {String} obj.response.trace
 * 格式預覽
  {"message": "Attempt to read property \"api_url\" on bool",
  "exception": "ErrorException",
  "file": "/home/vhost/gioco_plus/app/Http/Controllers/api/Game/Traits/ProviderDetector.php",
  "line": 41,
  "trace": [{...}]}
 *
 * @returns {Boolean} true: 是 Laravel 傳的預設錯誤格式
 */
const isLaravelErrorMsg = obj => {
  // Object.entries(obj).forEach(item => {
  //   console.error(item[0])
  //   console.error(item[1])
  // })

  if (
    obj?.response &&
    obj?.response?.data?.message &&
    obj?.response?.data?.file &&
    obj?.response?.data?.trace &&
    obj?.response?.data?.file
  ) { return true }

  return false
}

/**
 * 處理 JS 原生 Error 物件的 key 與 value
 * @param {Object} error
 * @param {String} error.message 錯誤訊息
 * @param {String} error.stack 錯誤訊息
 * @returns Object errors: { message: '', stack: '' }
 */
export const getErrorObject = (error) =>
  Object.getOwnPropertyNames(error).reduce((acc, curr) => {
    acc[curr] = error[curr]
    return acc
  }, {})

/**
 * 處理 axios 捕捉到的 Error 處理成字串
 * @param {Object} axiosCatchError js 的 Error 物件
 * @param {Object} error.config 設定
 * @param {Object} error.response 回應
 * @returns {String} error message，整理後的文字格式
 */
export const handleAxiosCatchErrorFormatToMessage = axiosCatchError => {
  // 判斷是否為 JS 原生 Error
  const temp = getErrorObject(axiosCatchError)
  if (temp.stack && temp.message) return axiosCatchError

  // 物件格式
  let text = ''

  // 判斷是否為 API 回傳的 Laravel 格式 Error
  if (isLaravelErrorMsg(axiosCatchError)) {
    const errorResponseData = axiosCatchError?.response?.data || '--'
    text = `
❗️File:
${errorResponseData.file} 第${errorResponseData.line} 行

❗️Exception:
${errorResponseData.exception}

❗️message: ${errorResponseData.message}`
  } else {
    if (axiosCatchError?.response && axiosCatchError?.response?.data) {
      text = `❗️message:
${axiosCatchError?.response?.data?.message}
`
    }
  }

  return text
}

export async function sendLineNotify(message) {
  const params = {
    // token: '5AkGv4bDYMR12ZxP3XjcXmEd71iVyniNKzXHkkHxQDx',
    token: process.env.VUE_APP_LINE_TOKEN,
    message,
  }

  try {
    await pushLineNotifyAPI(params)
  } catch (error) {
    console.error('❌ 發送 Line Notify 發生錯誤', error)
  }
}

export function getBasicInfoNotify({
  title = '發生錯誤',
  state,
  data,
  httpStatus,
  requestHeaders,
  apiUrl = '--',
  error,
}) {
  const account = state?.user?.isLogin
    ? `【使用者 ${state?.user?.userData?.member?.account}】`
    : '【未登入】'

  // 站台名稱
  const website = state?.websiteData
    ? state?.websiteData?.site_params?.site_name
    : '--'

  // 目前時間
  const currentTime = dayjs().format('YYYY-MM-DD HH:mm:ss')

  // 環境
  const environment = transferNodeEnv()

  // 所在的網址
  const currentPage = location.href

  // 檢查並設置請求參數，如果不存在則設為空字符串
  const requestData = data || '--'
  const statusCode = httpStatus || '--'

  // 處理 headers
  const headers = handleHeader(requestHeaders)
  // 處理 error
  const formattedError = handleAxiosCatchErrorFormatToMessage(error)

  return {
    title,
    account,
    website,
    environment,
    currentTime,
    currentPage,
    requestData,
    statusCode,
    headers,
    apiUrl,
    error: formattedError,
  }
}

export function createErrorMessage({
  title = '發生錯誤',
  account = '',
  website,
  environment,
  currentTime,
  currentPage = '',
  requestData,
  statusCode,
  headers,
  apiUrl = '--',
  error = '',
}) {
  const message = `
❌ ${account} ${title}

📱【H5】

👉🏻時間：${currentTime}
👉🏻HTTP 狀態：${statusCode}
👉🏻站台：${website}
👉🏻環境：${environment}
👉🏻API： ${apiUrl}
👉🏻頁面：${currentPage}

👉🏻請求Headers:
${headers}

👉🏻請求參數：
${requestData}

👉🏻回應錯誤：
${error}
`
  return message
}
