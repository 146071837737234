<template>
  <div>
    <!-- 上期冠軍 -->
    <div class="current_champion text-center champion-bg mt-4 mx-7 pa-1 rounded">
      <div class="white py-3">
        <h1 class="primary--text font-weight-bold">
          {{ $t('game.championOfThisIssue') }}
        </h1>
        <!-- 冠軍圖像 -->
        <img
          class="mt-4"
          src="@/assets/image/champion.png"
          alt="current_champion"
          width="80px"
        >

        <!-- 本期冠軍 -->
        <div
          v-for="(user, index) in activeData.champion"
          :key="index"
          class="my-3"
        >
          <!-- 冠軍姓名 -->
          <div class="current_champion-user black--text">
            {{ user.account }}
          </div>
          <!-- 日冠軍次數 -->
          <div class="current_champion-user black--text mt-1">
            {{ $t('game.get') }}
            <strong :class="activeTab === 0 ? 'dragon-text' : 'tiger-text'">
              {{ user.times + $t('myCenter.times') }}
            </strong>
            {{ $t('game.dayChampion') }}
          </div>
        </div>
      </div>
    </div>

    <!-- 週冠軍 -->
    <div
      v-for="(weekData, index) in activeData.weeklyRank"
      :key="index"
      class="champions mt-5 mx-15"
    >
      <div class="text-center black subtitle-1 font-weight-bold py-1 white--text">
        {{ $t('game.championWeek', { week: weekData.week }) }}
      </div>

      <!-- 周冠軍 -->
      <div class="champion-bg rounded pa-1 mb-4">
        <div class="user white">
          <v-row class="ma-0 black--text">
            <!-- 如果沒資料 -->
            <template v-if="!weekData.champion">
              <v-col
                cols="12"
                class="content-bg text-center"
              >
                ...
              </v-col>
            </template>

            <template v-else>
              <!-- 如果有資料 -->
              <v-col
                cols="6"
                class="img-bg pa-0"
              >
                <img
                  :src="baseImgUrl + weekData.champion.image"
                  class="h-100 w-100 object-fit-cover"
                >
              </v-col>

              <v-col
                cols="6"
                class="pa-0 text-right caption content-bg"
              >
                <div class="px-3 py-1 font-weight-bold">
                  {{ weekData.champion.date }}
                </div>
                <div class="border-bt px-3 py-1">
                  {{ weekData.champion.account }}
                </div>
                <div class="px-3 py-1 font-weight-bold">
                  {{ weekData.champion.amount }}
                </div>
              </v-col>
            </template>
          </v-row>
        </div>
      </div>

      <!-- 日紀錄 -->
      <div
        v-for="(user, userIndex) in weekData.dailyRank"
        :key="userIndex"
        class="champion-bg rounded pa-1 mb-4"
      >
        <div class="user white">
          <v-row class="ma-0 black--text">
            <v-col
              cols="6"
              class="img-bg pa-0"
            >
              <img
                :src="baseImgUrl + user.image"
                class="h-100 w-100 object-fit-cover"
              >
            </v-col>

            <v-col
              cols="6"
              class="pa-0 text-right caption"
            >
              <div class="px-3 py-1 font-weight-bold">
                {{ user.date }}
              </div>
              <div class="border-bt px-3 py-1">
                {{ user.account }}
              </div>
              <div class="px-3 py-1 font-weight-bold">
                {{ user.amount }}
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    // 0 龍 1 虎
    activeTab: {
      type: Number,
      required: true,
    },
    resData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['baseImgUrl']),
    activeData() {
      const typeAry = ['dragon', 'tiger']
      return this.resData[typeAry[this.activeTab]]?.last
    },
  },
}
</script>

<style lang="scss" scoped>
.champion-bg {
  background: #9d9d9d;
}

.tiger-text {
  color: #003c7e;
}
.dragon-text {
  color:#7d1c50;
}

.content-bg {
  background: #fbe97a;
}
.border-bt {
  border-top: 1px solid #9d9d9d;
  border-bottom: 1px solid #9d9d9d;
}
.img-bg {
  background: #d0d0d0;
}
</style>
