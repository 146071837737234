import { getUserWalletDetailAPI, verifyMobileSmsAPI, getMobileSmsAPI } from '@/api'

const state = () => ({})

const getters = {}

const mutations = {}

const actions = {
  /**
   * 取得資金明細
   * @date 2021-05-26
   * @param {any} {commit}
   * @param {Object} from
   * @returns {Object} 使用者資金明細
   */
  async get_user_wallet_record({ commit }, from) {
    const res = await getUserWalletDetailAPI(from)
    return res
  },

  /**
   * 發送簡訊取得 驗証碼
   * @date 2021-05-26
   * @param {any} phone
   * @returns {Object}
   */
  async get_mobile_verify_SMS({ commit }, phone) {
    const res = await getMobileSmsAPI(phone)
    return res
  },

  /**
   * 確認驗証碼
   * @date 2021-05-26
   * @param {any} context vuex
   * @param {Object} phoneAndCode 電話與驗証碼
   * @returns {Object} 成功與否
   */
  async verify_mobile_code(context, phoneAndCode) {
    const res = await verifyMobileSmsAPI(phoneAndCode)
    return res
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
