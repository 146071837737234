import { mapActions, mapGetters, mapMutations } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import Nodata from '@/components/base/Nodata'
import NodataTemplate3 from '@/components/base/Nodata-template3'
import { timezone } from '@/utils/day'

export default {
  components: { Nodata, NodataTemplate3 },

  data: () => ({
    form: {
      timezone: '',
      page: 1,
      bonus_type: 'all',
    },

    resLastPage: null,

    tab: [],
    bonusNews: {},
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'currentDevice', 'layout']),

    hasToolbar() {
      const showToolbarTemplateAry = ['template3', 'template4']
      if (showToolbarTemplateAry.includes(this.layout)) return true

      return false
    },
  },

  created() {
    this.form.timezone = timezone()
    this.getBonusNews()

    this.set_toolbar_title(this.$t('menu.preferentialActivity'))
    // if (this.hasToolbar) {
    // }
  },

  methods: {
    ...mapActions(['get_bonus_news', 'set_toolbar_title']),
    ...mapMutations(['SET_BONUS_NEWS']),

    changeBonusNewsType(bonus_type) {
      this.form.page = 1
      this.form.bonus_type = bonus_type
      this.getBonusNews(this.form)
    },

    /**
     * 取得優惠活動 (成功)
     * @date 2021-07-27
     * @param {object} data
     */
    getBonusNewsSuccess(data) {
      // 如果是第二次之後取得，將資料塞入後面
      if (this.form.page !== 1) {
        this.bonusNews = { ...this.bonusNews, ...data.bonus_news.data }
        this.$scrollToTop()
        return false
      }

      // 第一頁取得方式
      this.tab = data.bonus_types
      this.bonusNews = data.bonus_news.data
      this.$scrollToTop()

      this.resLastPage = data.bonus_news.last_page

      // 如果除了一頁之外的資料，再另取
      if (this.resLastPage > this.form.page) {
        this.getOtherBonusNews(this.resLastPage)
      }
    },

    getBonusNewsFail() {
      // 如果優惠活動 status === 0
    },

    /**
     * 取得優惠活動
     * @date 2021-07-27
     */
    async getBonusNews() {
      const res = await this.get_bonus_news(this.form)

      resStatus(res, this.getBonusNewsSuccess, this.getBonusNewsFail)
    },

    /**
     * 取得其它頁活動資料
     * @date 2021-07-27
     * @param {number} lastPage 最後一頁
     */
    getOtherBonusNews(lastPage) {
      this.form.page++
      while (this.form.page < lastPage + 1) {
        this.getBonusNews()
        this.form.page++
      }
    },
  },
}
