var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "header p-relative" },
      [
        _c("v-img", {
          staticClass: "header__bg",
          attrs: { cover: "", src: _vm.baseImgUrl + _vm.serviceData.bgBanner },
        }),
        _c("div", { staticClass: "header__description p-absolute d-flex" }, [
          _c("div", { staticClass: "w-70" }, [
            _c("div", { staticClass: "text-h5 icon--text font-weight-bold" }, [
              _vm._v(
                " " + _vm._s(_vm.websiteName + _vm.serviceData.title) + " "
              ),
            ]),
            _c("div", [
              _vm._v(" " + _vm._s(_vm.serviceData.description) + " "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "website-logo w-h-60" },
            [_c("v-img", { attrs: { src: _vm.baseImgUrl + _vm.websiteLogo } })],
            1
          ),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "px-6" }, [
      _c(
        "div",
        {
          staticClass:
            "navBar app rounded mt-10 px-3 py-4 d-flex align-center justify-center",
        },
        [
          _c(
            "div",
            { staticClass: "app__icon d-flex justify-center align-center" },
            [
              _c("v-img", {
                staticClass: "rounded-lg",
                attrs: {
                  "max-width": "60",
                  src: _vm.baseImgUrl + _vm.application.icon,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "app__description ml-4 flex-grow-1" }, [
            _c("div", { staticClass: "text-h6" }, [
              _vm._v(" " + _vm._s(_vm.application.title) + " "),
            ]),
            _c("div", { staticClass: "caption hint--text" }, [
              _vm._v(" " + _vm._s(_vm.application.description) + " "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "app_download" },
            [
              _c(
                "v-btn",
                {
                  attrs: { height: "32", color: "primary" },
                  on: { click: _vm.downloadApp },
                },
                [
                  _c("span", { staticClass: "caption btnText--text" }, [
                    _vm._v(" " + _vm._s(_vm.$t("global.download")) + " "),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "download-problem-btn w-100 px-6" },
      [
        _c(
          "router-link",
          {
            staticClass: "text-decoration-none",
            attrs: { to: "/app/tutorial" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex rounded justify-center align-center py-5 px-4",
                style: _vm.customGradientBgTypeA,
              },
              [
                _c(
                  "div",
                  { staticClass: "btnText--text caption flex-grow-1" },
                  [_vm._v(" " + _vm._s(_vm.$t("global.downloadProblem")) + " ")]
                ),
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "v-icon",
                      { staticClass: "btnText--text", attrs: { right: "" } },
                      [_vm._v(" mdi-chevron-right ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }