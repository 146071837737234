import {
  getUserVipDataAPI,
  getVipListAPI,
  userBonusButtonStatusAPI,
  getVipBonusAPI,
  getVipBonusExtraAPI,
} from '@/api'

import { resStatus } from '../../utils/resUtils'

import { SET_VIP_DATA } from '../mutation-types'

const state = () => ({
  account: '',
  now: {},
  next: {},
  progress: {},
})

const getters = {}

const mutations = {
  [SET_VIP_DATA](state, vipData) {
    const { vip, vip_next, vip_progress, account } = vipData

    state.account = account
    state.progress = vip_progress
    state.now = vip
    state.next = vip_next
  },
}

const actions = {
  /**
   * 取得使用者 vip 資料
   * - 由 SET_VIP_DATA 進行資料存放共用 (user / vip)
   * @date 2021-04-15
   * @param {function} {commit} SET_VIP_DATA 存放 vip 資料
   * @returns {object} 使用者 vip 資料
   */
  async get_user_vip({ commit }) {
    const res = await getUserVipDataAPI()
    resStatus(res, data => commit(SET_VIP_DATA, data))
    return res
  },

  async get_vip_list() {
    const res = await getVipListAPI()
    return res
  },

  /**
   * 確認 指定等級 優惠領取按鈕狀態
   * @date 2021-04-20
   * @param {object} context
   * @param {object} activeVipLevel 指定 vip 等級 {vip_level: 'vip8'}
   * @returns {object} 按鈕狀態
   */
  async check_user_vip_bonus_btn_status(context, activeVipLevel) {
    const res = await userBonusButtonStatusAPI(activeVipLevel)
    return res
  },

  /**
   * 領取 vip 優惠
   * @date 2021-04-20
   * @param {object} bonusType 領取資訊 {sub_type, vip_level}
   * @returns {object} 使用者領取相關資料
   */
  async get_vip_bonus(context, bonusType) {
    const res = await getVipBonusAPI(bonusType)
    return res
  },

  async get_vip_extra(context, vipLevel) {
    const res = await getVipBonusExtraAPI(vipLevel)
    return res
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
