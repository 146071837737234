<template>
  <v-bottom-navigation
    fixed
    background-color="navBar"
    grow
    :height="navHeight"
  >
    <!-- WEBSITE HOME -->
    <v-btn
      to="/"
      color="navBar"
      class="service-btn p-relative"
      :height="navHeight"
    >
      <Icon
        v-if="$route.name === 'home'"
        data="@icon/bottomNav/home.svg"
        width="22px"
        height="22px"
        class="primary--text nav-active"
      />
      <Icon
        v-else
        data="@icon/bottomNav/home.svg"
        width="22px"
        height="22px"
        class="hint--text"
      />
    </v-btn>

    <!-- 優惠 -->
    <v-btn
      color="navBar"
      :height="navHeight"
      to="/bonus"
    >
      <Icon
        v-if="$route.name === 'bonus'"
        data="@icon/bottomNav/gif.svg"
        width="22px"
        height="22px"
        class="primary--text nav-active"
      />
      <Icon
        v-else
        data="@icon/bottomNav/gif.svg"
        width="22px"
        height="22px"
        class="hint--text"
      />
    </v-btn>

    <!-- 客服 -->
    <v-btn
      color="navBar"
      class="service-btn p-relative"
      :height="navHeight"
      @click="showServiceArea"
    >
      <!-- to="/service" -->
      <Icon
        v-if="$route.name === 'service'"
        data="@icon/bottomNav/service-template3.svg"
        width="31px"
        height="31px"
        class="primary--text nav-active"
      />
      <Icon
        v-else
        data="@icon/bottomNav/service-template3.svg"
        width="31px"
        height="31px"
        class="hint--text"
      />

      <!-- Service Items -->
      <div
        v-show="serviceArea.status"
        class="service-items-area text-left"
      >
        <ul class="pl-0">
          <li
            v-for="(service, index) in serviceItems"
            :key="service + index"
            class="my-3 cursor-pointer"
            @click.stop="goService(service.name)"
          >
            <div v-if="service.name === 'Line' && showLineService || service.name === '24h'">
              <v-avatar
                color="white"
                size="40"
              >
                <img
                  class="pa-2"
                  :src="service.icon"
                  alt="line-service"
                >
              </v-avatar>
              <span class="caption ml-2 white--text">
                {{ service.text }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </v-btn>

    <!-- 紀錄 -->
    <v-btn
      color="navBar"
      :height="navHeight"
      to="/record"
    >
      <Icon
        v-if="$route.name === 'record'"
        data="@icon/bottomNav/cord-template3.svg"
        width="22px"
        height="22px"
        class="primary--text nav-active"
      />
      <Icon
        v-else
        data="@icon/bottomNav/cord-template3.svg"
        width="22px"
        height="22px"
        class="hint--text"
      />
    </v-btn>

    <!-- 我的 -->
    <v-btn
      color="navBar"
      :height="navHeight"
      to="/user"
    >
      <Icon
        v-if="$route.name === 'user'"
        data="@icon/bottomNav/user-template3.svg"
        width="22px"
        height="22px"
        class="primary--text nav-active"
      />
      <Icon
        v-else
        data="@icon/bottomNav/user-template3.svg"
        width="22px"
        height="22px"
        class="hint--text"
      />
    </v-btn>

    <v-overlay
      :value="serviceArea.status"
      opacity=".9"
    />
  </v-bottom-navigation>
</template>

<script>
import bottomNavMixins from '../mixins/bottomNavMixins'

export default {
  mixins: [bottomNavMixins],
}
</script>

<style lang="scss" scoped>
.websiteLogo {
  transform: translateY(-10px);
}

.wh-56px {
  height: 56px;
  width: 56px;
}

.wh-38px {
  height: 38px;
  width: 38px;
}

.service-items-area {
  position: absolute;
  bottom: 72px;
  left: 0;
  z-index: 99;

  >ul {
    list-style: none;
  }
}
</style>
