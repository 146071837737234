<template>
  <v-dialog
    v-model="status"
    overlay-opacity=".8"
    :max-width="width"
    :persistent="persistentStatus"
  >
    <v-card
      class="bg d-flex flex-column containerStyle"
      :height="height"
    >
      <v-card-title class="headline">
        <slot name="title" />
      </v-card-title>

      <v-card-text class="px-0 pb-0">
        <slot name="text" />
      </v-card-text>
      <v-spacer />
      <v-card-actions>
        <v-spacer />
        <slot name="btn" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    /* eslint-disable */
    width: {
      type: Number,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
  },

  computed: {
    // Dialog 狀態
    status: {
      get() {
        return this.dialog
      },
      set(newStatus) {
        this.$emit('update-dialog', newStatus)
      },
    },

    /**
     * 不要凍結功能的路由
     * @date 2021-09-23
     * @returns {Boolean} 當前頁面是否凍結
     */
    persistentStatus() {
      const notPersistentRouterName = ['add-card', 'home', 'login']
      const nowRouteName = this.$route.name
      return !notPersistentRouterName.includes(nowRouteName)
    }
  },
}
</script>

<style lang="scss" scoped></style>
