/**
 * 判斷是否觸底
 * @date 2021-05-03
 * @param {object} {target:{
 * @param {number} scrollHeight 滾動總高度
 * @param {number} clientHeight 客戶端可視高度
 * @param {number} scrollTop } 客戶端可視高度至頂高度
 * @returns {boolean} 是否觸底
 */
export const isScrollToBottom = ({
  target: { scrollTop, clientHeight, scrollHeight },
}) => {
  if (scrollTop === 0) return false // 無資料置頂
  if (scrollTop + clientHeight >= (scrollHeight / 2)) {
    return true
  }
  return false
}

export const scrollBottomFunc = ({ form, scrollData, func }) => {
  const obj = {
    status: scrollData.status,
    ing: scrollData.ing,
    progress: scrollData.progress,
    page: form.page,
  }

  return {
    get(index) {
      return obj[index]
    },
    set(index, value) {
      obj[index] = value
      return obj[index]
    },
    reset() {
      scrollData.status = true
      scrollData.ing = false
      scrollData.progress = false
    },
    runing() {
      scrollData.status = false
      scrollData.ing = true
      scrollData.progress = true
    },
    getData() {
      if (!obj.status || obj.ing) return false

      form.page = form.page + 1
      this.runing()
      func()
    },
  }
}
