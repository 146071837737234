var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-center justify-center" }, [
    _c(
      "div",
      { staticClass: "text-center" },
      [
        _c("v-img", {
          staticClass: "mb-4",
          attrs: {
            width: "200",
            src: require("../../assets/icons/nodata.png"),
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }