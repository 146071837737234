// TOOLBAR 相關

import { SET_TOOLBAR_TITLE } from '../mutation-types'

const state = () => ({
  title: '',
})

const mutations = {
  [SET_TOOLBAR_TITLE](state, title) {
    state.title = title
  },
}

const actions = {
  /**
   * 設置 toolbar title 標題
   * @date 2021-03-15
   * @param {any} {commit}
   * @param {any} title title 標題 名稱
   */
  set_toolbar_title({ commit }, title) {
    commit('SET_TOOLBAR_TITLE', title)
  },
}

const getters = {}
export default {
  state,
  mutations,
  actions,
  getters,
}
