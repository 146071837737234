var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-56 h-100dvh over-flow-y-scroll text-center" },
    [
      _c("v-img", { attrs: { src: _vm.imgUrl } }),
      _c(
        "v-btn",
        {
          staticClass: "mt-4 mb-10 font-weight-bold",
          attrs: { color: "primary", "x-large": "" },
          on: { click: _vm.goGiftPage },
        },
        [_vm._v(" 兌換 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }