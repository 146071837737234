<template>
  <div
    class="maintenance d-flex justify-center align-center"
    :style="{ backgroundColor: bgColor }"
  >
    <Icon
      data="@icon/maintenance.svg"
      width="24px"
      height="24px"
      class="white--text"
    />
    <strong class="white--text ml-3">
      {{ $t('global.maintenance') }}
    </strong>
  </div>
</template>
<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: '#7e7e7e82',
    },
  },
}
</script>
<style lang="scss">
// 維修中
.maintenanceBox {
  position: relative;
}
.maintenance {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  strong {
    font-size: 20px;
  }
  & + div {
    filter: blur(5px);
    z-index: 1;
  }
}
</style>
