
<template>
  <div
    class="subNav_Box bg"
    :class="[ currentDevice === 'ios' ? 'pb-72' : 'pb-56' ]"
  >
    <!-- Logo 圖  -->
    <div
      class="subNav_LogoBox d-flex justify-space-between align-center"
    >
      <img
        :src="websiteLogoURL()"
        :alt="websiteName"
        class="navbar__logo"
      >
      <Icon
        data="@/assets/icons/subNav/closeIcon.svg"
        width="32"
        height="32"
        @click="closeSubNav(false)"
      />
    </div>
    <!-- 使用著資訊 區塊-->
    <div
      v-if="isLogin"
      class="subNav_UserInfo d-flex flex-column justify-space-between"
    >
      <!-- 帳號 -->
      <div class="item user-area-box d-flex">
        <span class="card1" />
        <div class="user-area">
          <div>{{ $t('subHeader.account') }}</div>
          <div class="text-h6">
            {{ userAccount }}
          </div>
        </div>
      </div>
      <!-- 中心錢包 -->
      <div class="item user-balance-box  d-flex">
        <span class="card1" />
        <div class="user-balance">
          <div>{{ $t('myCenter.mainWallet') }}</div>
          <!-- 轉換 -->
          <!-- 取得錢包 loading -->
          <div
            v-if="userBalanceProgressStatus || balanceLoading"
            style="height:36px"
          >
            <v-progress-circular
              class="mr-4"
              indeterminate
              :width="2"
              :size="20"
              color="primary"
            />
          </div>
          <div
            v-else
            class="d-flex align-center justify-space-between"
          >
            <!-- liff 模式可導向 transfer 頁面 -->
            <template v-if="isLiffMode">
              <router-link
                to="/user/transfer"
                class="text-decoration-none"
              >
                <span class="mr-1 title--text">
                  {{ currencySymbol_ }}
                </span>
                <span class="text-h5 title--text">
                  {{ userBalance }}
                </span>
              </router-link>
            </template>

            <!-- 一般模式，僅顯示 -->
            <template v-else>
              <span
                class="text-h6 title--text"
              >
                {{ currencySymbol_ }} {{ userBalance }}
              </span>
            </template>

            <!-- 一鍵回收 -->
            <v-btn
              v-if="!overlay && isLogin"
              icon
              font-weight-bold
              @click="retrieveAllWalletBalance"
            >
              <v-img
                width="15"
                class="ml-4"
                src="@icon/user/walletItem/transfer3.png"
              />
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- 存、取款按鈕 區塊-->
    <div
      v-if="isLogin"
      class="subNav_payment d-flex justify-space-between"
    >
      <div class="row">
        <div class="col-6">
          <!-- 取款按鈕 -->
          <v-btn
            to="/user/withdrawal"
            height="40"
            width="100%"
            class="title--text text-center px-1 mr-3"
            style="background: linear-gradient(142.61deg, #35FFDB 3.79%, #0085FF 94.82%);"
          >
            <span>
              {{ $t('global.withdraw') }}
            </span>
          </v-btn>
        </div>
        <div class="col-6">
          <!-- 存款按鈕 -->
          <v-btn
            to="/user/deposit"
            height="40"
            width="100%"
            class="title--text text-center px-1 thaigi-gradient"
            :style="style === 'darkGD' ? '' : customGradientBgTypeA"
          >
            <span>
              {{ $t('global.deposit') }}
            </span>
          </v-btn>
        </div>
      </div>
    </div>
    <!-- 側選單list 區塊-->
    <div
      class="subNav_List"
    >
      <div
        v-for="(item, index) in itemsTree "
        :key="index"
        class="d-flex align-center flex-wrap  "
        :class="item.children? 'allGameList':''"
        @click="handlerItemsTree(item.class,item.router)"
      >
        <div
          v-if="item.loginStatus"
          class="GameListTitle d-flex justify-space-between align-center"
        >
          <span class="font-weight-medium d-flex align-center">
            <v-img
              class="mr-2"
              max-width="24"
              :src="require(`@/assets/icons/subNav/${ item.class }.png`)"
            />
            <span>{{ item.game_type }}</span>
          </span>
          <v-icon
            v-if="item.children"
            :class="allGameList ?'active':''"
            right
          >
            mdi-chevron-right
          </v-icon>
        </div>
        <!-- 子選單 -->
        <ul
          v-if="allGameList && item.children"
          class="w-100"
        >
          <li
            v-for="(childrenItem, index) in item.children[0] "
            :key="index"
            class="GameListTitle d-flex align-center"
            @click="goVendorPage(childrenItem._id)"
          >
            <v-img
              v-if="childrenItem.type=='game_type'"
              class="mr-2"
              max-width="22"
              :src="require(`@/assets/icons/subNav/gamesIcon/${ childrenItem.class }.png`)"
            />
            <img
              v-else
              class="mr-2"
              :src="baseImgUrl+navIcon(childrenItem)"
              width="22"
            >
            <span
              class="font-weight-medium"
            >
              {{ childrenItem.game_type }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 邀請好友按鈕＋客服按鈕 區塊-->
    <div class="subNav_BtnS">
      <router-link
        to="/recommend"
        class="mb-2"
      >
        <v-img
          v-img
          src="@/assets/icons/subNav/btn/friend.png"
        >
          <div class="friend txt d-flex flex-column justify-center h-100 ">
            <h3>{{ $t('myCenter.inviteFriend') }}</h3>
            <span>{{ $t('myCenter.inviteSlogan') }}</span>
          </div>
        </v-img>
      </router-link>
      <router-link
        to="/service"
      >
        <v-img
          src="@/assets/icons/subNav/btn/service.png"
        >
          <div class="service txt d-flex flex-column justify-center h-100 ml-6">
            <h3>{{ $t('bottomNav.service') }}</h3>
            <span>24/7 online</span>
          </div>
        </v-img>
      </router-link>
    </div>
    <!-- 語系 區塊 -->
    <div class="subNav_Lan d-flex flex-wrap justify-space-between align-center">
      <!--當前語言的國旗＋文字 -->
      <div class="subNav_Lan_txt d-flex align-center">
        <img
          :src="baseImgUrl +language.active_default_Icon"
          :alt="language.active_default_name"
          width="20px"
          height="14px"
          class="mr-2"
        >
        <span> {{ language.active_default_name }}</span>
      </div>
      <!-- 地球 Icon -->
      <div class="subNav_Lan_icon d-flex align-center ">
        <Icon
          data="@/assets/icons/subNav/lan_icon.svg"
          width="15px"
          height="15px"
          class="secondary--text"
          @click="languageListVisible = !languageListVisible"
        />
      </div>
      <!-- 語系清單 -->
      <div
        v-if="languageListVisible"
        class="subNav_Lan__list d-flex flex-column align-center py-3 px-3 inputBg mt-2"
      >
        <div
          v-for="lang in switchLanguages"
          :key="lang.code"
          class="subNav_Lan__list__item d-flex justify-left align-center my-1 py-1 px-3"
          :class="{ 'active card1': lang.code === activeLang }"
          @click="settingClientLang(lang.code)"
        >
          <img
            :src="baseImgUrl + lang.icon"
            :alt="lang.default_name"
            width="20px"
            height="14px"
            class="mr-2"
          > {{ lang.default_name }}
        </div>
      </div>
    </div>
    <!-- 開發svg漸層icon樣式功能 -->
    <!-- <div class="transfer" /> -->
  </div>
</template>

<script>
import subNav from '../mixins/subNav'
import userInfoMixin from '../mixins/userinfo'
export default {
  mixins: [subNav, userInfoMixin],
}

</script>

<style lang="scss">
// svg漸層
.transfer{
  background: linear-gradient(-258deg, rgb(255, 173, 34), rgb(255, 107, 0));
  width: 250px;
  height: 187.5px;
  mask: url('../../../../assets/icons/verify.svg');
  mask: url('../../../../assets/logo.svg');
}
.subNav_{
  &Box{
    padding: 20px 16px;
    min-height: 100%;
    >div{
      margin-bottom: 30px;
    }
  }
  // Logo 圖
  &LogoBox{
    img{
      width: 150px;
      height: 40px;
      object-fit: contain;
    }
  }
  // 使用著資訊
  &UserInfo {
    height: 125px;
    .item {
      .card1{
        width: 10px;
        margin-right: 10px;
        height: 50px;
        &+div{
          width: 100%;
          div:first-child{
            color: rgba(255, 255, 255, 0.6);
            font-size: 14px;
          }
        }
      }
    }
  }
  // 側選單list
  &List {
    a {
      height: 44px;
      display: block;
      width: 100%;
      text-decoration: none;
    }
    .font-weight-medium {
      display: inline-block;
      font-size: 14px;
      height: 24.5px;
      line-height: 24.5px;
    }
     .GameListTitle{
        width: 100%;
        height: 44px;
        line-height: 44px;
      }
    .allGameList{
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-image: linear-gradient(to right, rgba(217, 217, 217, 0.1), rgba(217, 217, 217, 1),rgba(217, 217, 217,  0.1)) 1;
      i.active{
        transform: rotate(90deg);
      }
      ul{
        list-style: none;
      }

    }
  }
  // 邀請好友按鈕＋客服按鈕
  &BtnS{
    a{
      display: block;
      text-decoration: none;
    }
    img{
      width: 100%;
    }
    .txt{
      height: 100%;
      h3{
        font-size: 18px;
      }
      span{
        font-size: 12px;
      }
    }
    .friend{
      margin-left: 108px;
      span{
        color:#1D2F5A;
        font-weight: 700;
      }
    }
    .service{
       span{
          color:#FFFFFF99;
       }
    }
  }
  &Lan{
    &_txt span{
      font-size: 14px;
    }
    &_icon{
    cursor: pointer;
      &::after {
        display: block;
        content: '';
        width: 0;
        height: 0;
        margin-left: 5px;
        border-style: solid;
        border-width: 6px 5px 0 5px;
        border-color: #F87248 transparent transparent transparent;
      }
    }
    &__list {
      border-radius: 10px;
      width: 100%;

      &__item {
        width: 100%;
        cursor: pointer;
        font-size: 12px;

        img {
          object-fit: contain;
        }
      }
    }
  }
}
.navbar {
  .v-overlay {
    position: fixed;
  }
}

</style>
