// Utils
// 與 API 返回相關的訊息
import {
  tokenIsExpiredList,
} from '@/utils/api/apiState'
import router from '@/router'
import store from '@/store'

import {
  sendLineNotify,
  getBasicInfoNotify,
  createErrorMessage,
} from '@/utils/lineNotify'
import { detectDevIsLineLiffMode } from '@/utils/liff'

/* ----------- Axios 處理 API 回應異常 Function ----------- */

// 不是 LINE Liff 模式會回傳 True
export const isNotInLineLiffMode = () => (!detectDevIsLineLiffMode() || !window.liff.isInClient())

// Token 失效時，會回傳 true
export const isTokenExpired = (errors) => (tokenIsExpiredList.find(errorItem => errors.length > 0 && errors[0].includes(errorItem)))

// 根據 Http Status Code 讓使用者跳轉到指定頁面
export const handleHttpStatus = async(basicParams, error) => {
  if (!error) {
    console.error('[axiosErrorHandler.js] 缺少參數！')
    return
  }

  // HTTP 狀態碼
  const statusCode = error?.response?.status || '--'

  // 目前使用的錯誤頁面
  const errorPages = {
    500: '500',
    503: '503',
    403: '403',
  }

  // 讓使用者導向到指定頁面
  if (errorPages[statusCode]) {
    store.dispatch('set_status_error_code', statusCode)
    router.push({ name: errorPages[statusCode] })
  } else {
    console.error(`[handleHttpStatus] ❌ 發生預期以外的 ${statusCode} 錯誤`)

    // 統一將參數格式化
    const formattedParams = getBasicInfoNotify({
      title: 'Axios 發生錯誤，沒有將使用者導向對應頁面',
      ...basicParams,
    })

    // 建立 Log 文字模板
    const message = createErrorMessage(formattedParams)

    // 發送 LINE 通知
    await sendLineNotify(message)
  }
}

// 若網址列有 query 帶 tgToken，但 Token 過期，導向至對應頁面
export const handleTelegramTokenExpired = async(response, Vue) => {
  if (!response || !Vue) {
    console.error('[handleTelegramTokenExpired] 缺少參數！')
    return
  }

  // 初始化變數
  let errorFromRouter = null

  // 導向至 Telegram 訊息已逾時頁面
  await Vue.$router.push({ path: 'tokenExpiredFromTelegram' })
    .catch(async(error) => {
      console.log(error)
      errorFromRouter = error.toString()
    })
    .finally(async() => {
      // 由於有異步問題，無法直接寫在 router catch error 裡
      console.log('errorFromRouter', errorFromRouter)
      if (errorFromRouter) {
        const params = getBasicInfoNotify({
          title: '透過 Telegram 連到站台網址並挾帶 tgToken 自動登入，發生錯誤',
          state: store?.state,
          data: response?.config?.data,
          requestHeaders: response?.headers,
          httpStatus: response?.status,
          error: errorFromRouter,
        })

        // 建立通知訊息
        const message = createErrorMessage({
          ...params,
        })

        // 發送 LINE 通知
        await sendLineNotify(message)
      }
    })

  // eslint-disable-next-line no-useless-return
  return
}

// 根據 Http 狀態碼，設置 vuex 狀態、跳轉到指定頁面
export const handleStatusCodeError = async(statusCode) => {
  store.dispatch('set_status_error_code', statusCode)
  return router.push({ name: statusCode.toString() })
}

// 傳送 Log 通知給 Line
export const sendLogToLine = async(basicParams) => {
  // 統一將參數格式化
  const formattedParams = getBasicInfoNotify({
    ...basicParams,
  })

  // 建立 Log 文字模板
  const message = createErrorMessage(formattedParams)

  // 發送 LINE 通知
  await sendLineNotify(message)
}
