<template>
  <v-app-bar
    fixed
    flat
    class="pa-0 ma-0"
    color="#C01200"
  >
    <!-- 回上一頁 -->
    <v-btn
      icon
      class="pa-0"
      @click="goBack"
    >
      <template v-if="layout === 'template3' && theme === 'light'">
        <Icon
          data="@icon/toolbar/toolbar_goback_arrow.svg"
          width="16px"
          height="16px"
          class="white--text"
        />
      </template>

      <template v-else>
        <Icon
          data="@icon/toolbar/toolbar_goback_arrow.svg"
          width="16px"
          height="16px"
          class="icon--text"
        />
      </template>
    </v-btn>

    <template v-if="layout === 'template3' && theme === 'light'">
      <!-- 標題文字 -->
      <v-toolbar-title class="white--text font-weight-medium">
        {{ title }}
      </v-toolbar-title>
    </template>

    <template v-else>
      <!-- 標題文字 -->
      <v-toolbar-title class="title--text font-weight-medium">
        {{ title }}
      </v-toolbar-title>
    </template>

    <v-spacer />

    <!-- button -->
    <slot name="btn" />
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import ToolbarMixin from '../mixins/ToolbarMixin'

export default {
  mixins: [ToolbarMixin],
  computed: {
    ...mapGetters(['layout', 'theme']),
  },
}
</script>

<style lang="scss" scoped>

</style>
