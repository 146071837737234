import {
  getPaymentRecordAPI,
  getTransactionRecordAPI,
  getUserredeemRecordAPI,
  getUserMemberSubtypeListAPI,
  getWagerRecordAPI,
  getVendorLogoAPI,
} from '@/api'
import vm from '../../main'

const state = () => ({})

const getters = {}

const mutations = {}

const actions = {
  /**
   * 取得 存款/取款 紀錄
   * @date 2021-03-17
   * @param {any} {commit}
   * @param {object}} params 請求參數
   * @returns {object 存/取款資料
   */
  async get_paymentLog({ commit }, params) {
    const res = await getPaymentRecordAPI(params)
    return res
  },

  /**
   * 取得 額度轉換 紀錄
   * @date 2021-03-17
   * @param {any} {commit}
   * @param {object} params 請求參數
   * @returns {object} 存/取款資料
   */
  async get_transaction_record({ commit }, params) {
    const res = await getTransactionRecordAPI(params)
    return res
  },

  /**
   * 取得使用者兌獎紀錄
   * @date 2021-03-18
   * @param {object} 請求搜尋內容
   * @returns {object} 兌獎紀錄
   */
  async get_redeem_record({ commit }, form) {
    const res = await getUserredeemRecordAPI(form)
    return res
  },

  /**
   * 兌獎紀錄 - 取得紅利 的 類型清單 sub_type
   * @date 2021-03-19
   * @returns {object} 類型清單的列表
   */
  async get_bonus_subtype() {
    const bonusSubTypeList = await getUserMemberSubtypeListAPI()
    return bonusSubTypeList
  },

  /**
   * 取得投注紀錄
   * @date 2021-03-19
   * @param {any} {commit}
   * @param {object} form
   * @returns {object} 投注紀錄
   */
  async get_wager_record({ commit }, form) {
    const res = await getWagerRecordAPI(form)
    return res
  },

  /**
   * 處理資料 => {vendorName: vendorCode}
   * @date 2021-03-19
   * @param {any} context
   * @param {object} vendorBrands res 未整理的資料
   * @returns {object} vendor list
   */
  dataReduce(context, vendorBrands) {
    const vendorAry = [{ [vm.$t('global.all')]: 'all' }]

    if (!vendorBrands.data) return vendorAry

    const vendorList = vendorBrands.data.game_brands
    vendorList.forEach(item => {
      vendorAry.push({
        [item.name]: item.game_brand_code,
      })
    })
    return vendorAry
  },

  /**
   * 取得所有遊戲資料
   * @date 2021-03-19
   * @param {any} {dispatch}
   * @returns {object} 處理好的資料
   */
  async get_vendor_brands({ dispatch }) {
    const res = await getVendorLogoAPI()
    return dispatch('dataReduce', res)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
