import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

// 引入默認色彩包 (包含 通用色)
import lightBO from '@/utils/theme/light/lightBO.json'
import darkGO from '@/utils/theme/dark/darkGO.json'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {

    themes: {
      light: lightBO, // 默認 色彩包設置
      dark: darkGO,
    },
  },
})
