import Vue from '@/main.js'
// Utils
import isLocalDev from '@/utils/isLocalDev'
// 使用 require 導入 JSON 檔案
const template1 = require('./template/template1.json')
const template2 = require('./template/template2.json')
const template3 = require('./template/template3.json')
const template4 = require('./template/template4.json')

/*
  template 版型
  layout_tag 風格
  layout 樣式
  mode 模式
  color 主題色
*/
export const configureUIAppearance = (
  paramsTemplate,
  paramsLayout,
  paramsLayoutTag,
) => {
  if (!paramsTemplate) {
    console.error('❌發生錯誤：沒有設定版型 Template')
    return
  }

  if (!paramsLayoutTag) {
    console.error('❌發生錯誤：沒有設定風格 Layout Tag')
    return
  }

  if (!paramsLayout) {
    console.error('❌發生錯誤：沒有設定樣式 Layout')
    return
  }

  // 處理版型，template 對應 json
  handleTemplate(paramsTemplate, paramsLayout, paramsLayoutTag)
}

/**
 * 設定 template 版型
 * @param {any} paramsTemplate='template1' 版型
 * @param {any} paramsLayout='slot1_light' 風格
 * @param {any} paramsLayoutTag='slot' Tag
 * @returns {any}
 */
const handleTemplate = (
  paramsTemplate = 'template1',
  paramsLayout = 'slot1_light',
  paramsLayoutTag = 'slot') => {
  // 自選版型，根據 .env.local 環境變數設置
  // 處理 layout
  // template = template1 時，layout 格式為 card1_dark
  // template = 其他時，layout 格式為 template2_card1_dark
  const resultLayout = paramsTemplate === 'template1'
    ? paramsLayout.split('_')[0]
    : paramsLayout.split('_')[1]

  try {
    switch (paramsTemplate) {
      case 'template1':
        console.log('[configureUIAppearance] template1')
        // TODO: 待之後完善 template1-4.json後 刪除
        // 由於 template1-4.json 尚未完成 slot1 以外的設置，暫時先固定 slot1
        // localStorage.setItem('component', JSON.stringify(template1[paramsLayoutTag][resultLayout]))
        localStorage.setItem('component', JSON.stringify(template1.slot.slot1))
        break

      case 'template2':
        // localStorage.setItem('component', JSON.stringify(template2[paramsLayoutTag][resultLayout]))
        localStorage.setItem('component', JSON.stringify(template2.slot.slot1))
        break

      case 'template3':
        // localStorage.setItem('component', JSON.stringify(template3[paramsLayoutTag][resultLayout]))
        localStorage.setItem('component', JSON.stringify(template3.slot.slot1))
        break

      case 'template4':
        // localStorage.setItem('component', JSON.stringify(template4[paramsLayoutTag][resultLayout]))
        localStorage.setItem('component', JSON.stringify(template4.slot.slot1))
        break

      default:
        localStorage.setItem('component', JSON.stringify(template1.slot.slot1))
        break
    }
  } catch (error) {
    console.error('DEBUG: 設定網站版型錯誤 (configureUIAppearance.js)')
    console.error(error)
  }
}

/**
 * 設定深色、淺色以及主題色包
 * @param {any} paramsTheme='light/dark' 模式
 * @param {String} paramsColor='lightBB' 主題色名稱
 * @returns {Object} colorset={} 來自 API 色彩包
 */
export const handleTheme = (paramsTheme, paramsColor, paramsTemplate, colorSetFromApi = null) => {
  // 通用靜態色彩包
  const staticColors = require(`./theme/${paramsTheme}/static.json`)
  // 主題色彩包
  const colorsPackage = require('./theme/theme.json')

  if (isLocalDev) {
    Vue.$log('======================================')
    Vue.$log('樣式處理 [handleTheme] paramsTheme', paramsTheme)
    Vue.$log('樣式處理 [handleTheme] paramsColor', paramsColor)
    Vue.$log('樣式處理 [handleTheme] paramsTemplate', paramsTemplate)
    Vue.$log('樣式處理 [handleTheme] colorSetFromApi', colorSetFromApi)
    Vue.$log('======================================')
  }

  // template1.lightBB
  // console.log(colorsPackage[paramsTemplate][paramsColor])
  // template2.light / template2.dark
  // console.log(colorsPackage[paramsTemplate][paramsTheme])

  // 設置 (深/淺) 模式
  Vue.$vuetify.theme[paramsTheme] = true

  /**
   * 設置 (深/淺)模式 「靜態顏色」
   * @date 2021-04-12
   * @param {object}} staticColors 指定模式，通用的顏色
   */
  Object.keys(staticColors).forEach(colorVariableName => {
    Vue.$vuetify.theme.themes[paramsTheme][colorVariableName] =
      staticColors[colorVariableName]
  })

  /**
   * 設置 主題色 「動態顏色」
   * 若無傳入值，則以靜態檔案參數寫入
   * @date 2021-04-12
   * @param {object} colorVariableName
   */

  if (!colorSetFromApi) {
    /*
      由於 icon 和 title 顏色只差一點點，故設定 icon = title
      由於 navbar 和 background 顏色只差一點點，故設定 navbar = background
    */

    if (paramsTemplate === 'template1') {
      console.log('主題色來自 靜態主題色包(theme.json)')
      // Ex: template1.lightBB
      Vue.$vuetify.theme.themes[paramsTheme].primary = colorsPackage[paramsTemplate][paramsColor].primary
      Vue.$vuetify.theme.themes[paramsTheme].secondary = colorsPackage[paramsTemplate][paramsColor].secondary
      Vue.$vuetify.theme.themes[paramsTheme].title = colorsPackage[paramsTemplate][paramsColor].title
      Vue.$vuetify.theme.themes[paramsTheme].icon = colorsPackage[paramsTemplate][paramsColor].title
      Vue.$vuetify.theme.themes[paramsTheme].bg = colorsPackage[paramsTemplate][paramsColor].background
      Vue.$vuetify.theme.themes[paramsTheme].navBar = colorsPackage[paramsTemplate][paramsColor].background
    } else {
      // Ex: template2.light / template2.dark
      // template4-light
      Vue.$vuetify.theme.themes[paramsTheme].primary = colorsPackage[paramsTemplate][paramsTheme].primary
      Vue.$vuetify.theme.themes[paramsTheme].secondary = colorsPackage[paramsTemplate][paramsTheme].secondary
      Vue.$vuetify.theme.themes[paramsTheme].title = colorsPackage[paramsTemplate][paramsTheme].title
      Vue.$vuetify.theme.themes[paramsTheme].icon = colorsPackage[paramsTemplate][paramsTheme].title
      Vue.$vuetify.theme.themes[paramsTheme].bg = colorsPackage[paramsTemplate][paramsTheme].background
      Vue.$vuetify.theme.themes[paramsTheme].navBar = colorsPackage[paramsTemplate][paramsTheme].background
    }
    console.log('主題色來自 靜態主題色包(theme.json)')
  } else {
    // 使用 API 版型，顏色色碼來自 API
    Vue.$vuetify.theme.themes[paramsTheme].primary = colorSetFromApi.primary
    Vue.$vuetify.theme.themes[paramsTheme].secondary = colorSetFromApi.secondary
    Vue.$vuetify.theme.themes[paramsTheme].title = colorSetFromApi.title
    Vue.$vuetify.theme.themes[paramsTheme].icon = colorSetFromApi.title
    Vue.$vuetify.theme.themes[paramsTheme].bg = colorSetFromApi.background
    Vue.$vuetify.theme.themes[paramsTheme].navBar = colorSetFromApi.background
  }
}

/**
 * 返回主題色包任一值，使用於漸層色 gradientBg
 * @param {string} paramsTheme=light/dark
 * @param {string} paramsColor=lightGO
 * @param {string} paramsTemplate
 * @param {string} key=primary, background2_1等，key值請看 theme.json
 * @returns {any} 色碼 EX: #e7e7e7
 */
export const getColorPackage = (paramsTheme, paramsColor, paramsTemplate, key) => {
  // 主題色彩包
  const colorsPackage = require('./theme/theme.json')

  if (paramsTemplate === 'template1') {
    // Ex: template1.lightBB
    // console.log('colorsPackage[paramsTemplate][paramsColor][key]', colorsPackage[paramsTemplate][paramsColor][key])
    return colorsPackage[paramsTemplate][paramsColor][key]
  } else {
    // Ex: template2.light / template2.dark
    // template4-light
    // console.log('colorsPackage[paramsTemplate][paramsTheme][key]', colorsPackage[paramsTemplate][paramsTheme][key])
    return colorsPackage[paramsTemplate][paramsTheme][key]
  }
}
