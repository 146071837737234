<template>
  <div class="d-flex align-center justify-center">
    <div class="text-center">
      <Icon
        class="hint--text d-block mb-4 o-5"
        data="@icon/nodata.svg"
        width="86"
        height="81"
      />
      <span class="hint--text">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.o-5 {
  opacity: .5;
}
</style>
