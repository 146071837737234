import Vue from '../../main'

import {
  SET_APPS_DATA,
  SET_WEBVIEW_DATA,
  SET_APP_DOWNLOAD_TYPE,
} from '../mutation-types'

const state = () => ({
// 首頁 app 下載 downloadApp.vue
  downloadApp: {
    description: '让您轻松体验聊球投注，乐在其中',
  },

  // 客服中心 使用者區塊
  user: {
    title: '--',
    description: '--',
  },

  // 客服 app 描述
  serviceApp: {
    icon: null,
    description: '--',
    // download URL state.websiteData.mobile.download_cs_profile
  },

  // service-app 客服
  service: {
    title: '--',
    description: '--',
    bgBanner: null,
  },

  // service-app 應用
  app: {
    title: '--',
    description: '--',
    bgBanner: null,
    app_download_type: '',
  },

  // 全站 app
  application: {
    title: '--',
    description: '--',
    icon: null,
    downloadUrl: null,
  },

  // 體電 app
  sportApp: {
    icon: null,
    title: '--',
    description: '--',
    downloadUrl: null,
  },
})

const getters = {

}

const mutations = {
  /**
   * 設置 app 文字資料
   * @date 2021-10-18
   * @param {any} {commit}
   * @param {array} appData app 相關資料
   */
  [SET_APPS_DATA](state, appData) {
    appData.forEach((item, index) => {
      // 如果索引為 0
      const saveAppItemName = index === 0 ? state.application : state.sportApp

      saveAppItemName.title = item.app_title
      saveAppItemName.description = item.app_desc
      saveAppItemName.downloadUrl = item.app_download_url
      saveAppItemName.icon = item.app_icon
    })
  },

  /**
   * 設置 webview 資料
   * @date 2021-10-18
   * @param {any} {commit}
   * @param {object} webviewData webview 頁面資料
   */
  [SET_WEBVIEW_DATA](state, webviewData) {
    // 客服 app icon
    state.serviceApp.icon = webviewData.cs_profile_icon
    // 客服 desc
    state.service.description = webviewData.cs_profile_page1_desc
    state.service.title = `${Vue.$t('service.application')}`
    state.service.bgBanner = webviewData.cs_profile_banner_1

    // 應用 desc
    state.app.description = webviewData.cs_profile_page2_desc
    state.app.title = `${Vue.$t('bottomNav.service')}`
    state.app.bgBanner = webviewData.cs_profile_banner_2

    // 客服中心
    state.user.title = Vue.$t('service.hiDearUser')
    state.user.description = Vue.$t('service.welcomeToTheCustomerServiceCenter')
    state.serviceApp.description = Vue.$t('service.addDesktopCustomerService')
  },

  /**
   * 設置 App提供下載類型 動態控制選單 App 是否顯示
   */
  [SET_APP_DOWNLOAD_TYPE](state, data) {
    state.app.app_download_type = data
  },
}

const actions = {

  /**
   * 設置 app 文字資料
   * @date 2021-10-18
   * @param {any} {commit}
   * @param {array} appData app 相關資料
   */
  set_apps_data({ commit }, appData) {
    commit(SET_APPS_DATA, appData)
  },

  /**
   * 設置 webview 資料
   * @date 2021-10-18
   * @param {any} {commit}
   * @param {object} webviewData webview 頁面資料
   */
  set_webview_data({ commit }, webviewData) {
    commit(SET_WEBVIEW_DATA, webviewData)
  },

  /**
   * 設置 App提供下載類型
   * @param {any} {commit}
   * @param {any} data
   * @returns {any}
   */
  set_app_download_type({ commit }, data) {
    commit(SET_APP_DOWNLOAD_TYPE, data)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
