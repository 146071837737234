import {
  getUserInfoAPI,
  getAvatarListAPI,
  setAvatarAPI,
  editUserInfoAPI,
} from '@/api'

import { SET_USER_BASE_INFO } from '../mutation-types'

import { resStatus } from '../../utils/resUtils'

const state = () => ({
  baseInfo: [],
})

const getters = {}

const mutations = {
  [SET_USER_BASE_INFO](state, userBaseInfo) {
    state.baseInfo = userBaseInfo
  },
}

const actions = {
  get_user_base_info_success({ commit }, userBaseInfo) {
    commit(SET_USER_BASE_INFO, userBaseInfo.member)
  },

  async get_user_base_info({ dispatch, commit }) {
    const res = await getUserInfoAPI()
    resStatus(res, data => dispatch('get_user_base_info_success', data))
    return res
  },

  /**
   * 取得使用者頭像 列表
   * @date 2021-03-26
   * @returns {any}
   */
  async get_user_avatar_list() {
    const res = await getAvatarListAPI()
    return res
  },

  async fetch_set_user_avatar({ dispatch }, userAvatarUrl) {
    const res = await setAvatarAPI(userAvatarUrl)
    return res
  },

  async set_user_avatar({ dispatch }, userAvatarUrl) {
    const res = await dispatch('fetch_set_user_avatar', userAvatarUrl)
    return res
  },

  async edit_user_profile({ commit }, userProfile) {
    const res = await editUserInfoAPI(userProfile)
    return res
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
