import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import { detectDevIsLineLiffMode } from '@/utils/liff'
import ProfileIcon from '@/components/icon/Profile'
import Dialog from '@/components/dialog/index'
import { deviceCheck } from '@/utils/device'
import { getCookie } from '@/utils/cookie'
import iconGlobal from '@/assets/icons/global.svg'

export default {
  components: {
    ProfileIcon,
    Dialog,
  },
  data() {
    return {
      languageListVisible: false, // 顯示語系下拉選單
      activeLang: null, // 當前語系
      iconGlobal, // 語言按鈕
      userBaseInfo: [],
      dialog: {
        status: false,
        avatarList: [],
        form: {
          avatar: '',
        },
      },
      langSheetStatus: false, // 語系開關
      language: {
        status: false,
        searchKeyword: '',
        resultLangs: [],
        active_default_name: '',
        active_default_Icon: '',
      },
      initiallyOpen: ['public'],
      items: [],
      subNavChildrenItem: [], // 後端傳的遊戲類情清單
      allGameList: false, // 顯示遊戲類情清單
    }
  },
  computed: {
    ...mapState({
      isLogin: state => state.user.isLogin,
      userData: state => state.user.userData,
      theme: state => state.theme,
      userWallet: state => state.wallet.main,
      primaryColor() {
        return this.$vuetify.theme.themes[this.theme]?.primary || ''
      },
      userBalanceProgressStatus: state => state.userInfo.mainBalanceProgressStatus,
      /**
     * 類別中的 vendor
     * @date 2021-09-10
     * @returns {array} 所有 vendor
     */
      // navbarContent() {
      //   return this.data.data.navbar
      // },

      // 控制選單 App是否顯示，後台：編輯營運商 / App提供下載類型
      appDownloadType: state => state.service.app.app_download_type,

      // 可選語言
      switchLanguages: state => state.websiteData.lang_available,

      // line
      lineDisplayName: state => state.line.liff.displayName,
      lineAvatar: state => state.line.liff.avatar,

      free: state => state.wallet.free,
      player: state => state.wallet.player,
      // 取款方式
      withdrawalMethod: state => state.websiteData.withdrawal_method,
    }),

    ...mapGetters([
      'customGradientBgTypeA',
      'baseImgUrl',
      'lang',
      'dynamicAgentDisplay',
      'clientLang', // 前端當前切換語系
      'currencySymbol', // 營商幣別
      'currencySymbol_', // 營商幣別符號
      'style',
      'currentDevice',
      'isMycardDomainService',
      'theme',
      'layout', // 版型
      'websiteName',
    ]),

    profileClr() {
      return this.$vuetify.theme.themes[this.theme]?.icon
    },

    dynamicClr() {
      return this.style === 'darkGD' ? this.primaryColor : this.profileClr
    },

    avatarBorderClr() {
      return {
        border: `1px solid ${this.$vuetify.theme.themes[this.theme]?.primary}`,
      }
    },

    // 因應語系動態調整側選單寬度
    itemsWidth() {
      const widthAry = ['en', 'vi-VN', 'ph']
      return widthAry.includes(this.clientLang) ? 'width:275px' : ''
    },

    isLiffMode() {
      return detectDevIsLineLiffMode() || window.liff.isInClient()
    },

    /*
      後台設定[提款]為直接攤平
      withdrawalIsFlatten: true
      1. 隱藏銀行卡
      2. 取款頁不強制跳轉，不跳出訊息「初次新增銀行卡，請先完善個人資料」
    */
    withdrawalIsFlatten() {
      return this.withdrawalMethod === 'direct'
    },
    // 側選單內容
    itemsTree() {
      return [
        {
          router: '',
          game_type: this.$t('subHeader.index'),
          class: 'home',
          loginStatus: true,
        },
        {
          router: 'user/personal',
          game_type: this.$t('myCenter.personalInformation'),
          class: 'user',
          loginStatus: this.isLogin,
        },
        {
          router: 'user/vip',
          game_type: this.$t('myCenter.myVip'),
          class: 'vip',
          loginStatus: this.isLogin,
        },
        {
          router: 'user/news',
          game_type: this.$t('myCenter.news'),
          class: 'news',
          loginStatus: this.isLogin,
        },
        {
          router: 'user/card',
          game_type: this.$t('global.cards'),
          class: 'card',
          loginStatus: this.isLogin,
        },
        {
          router: 'user/prize',
          game_type: this.$t('global.prizeRecord'),
          class: 'prize',
          loginStatus: this.isLogin,
        },
        {
          router: 'user/trade',
          game_type: this.$t('global.transactionRecord'),
          class: 'trade',
          loginStatus: this.isLogin,
        },
        {
          router: 'bonus',
          game_type: this.$t('menu.preferentialActivity'),
          class: 'activity',
          loginStatus: true,
        },
        {
          router: '',
          game_type: this.$t('menu.allGameList'),
          class: 'gameList',
          loginStatus: true,
          children: [
          ],
        },
        {
          router: 'agent',
          game_type: this.$t('menu.agent'),
          class: 'agent',
          loginStatus: this.dynamicAgentDisplay,
        },
        {
          router: 'login',
          game_type: this.$t('subHeader.login'),
          class: 'logIn',
          loginStatus: !this.isLogin,
        },
        {
          router: '',
          game_type: this.$t('subHeader.logout'),
          class: 'logOut',
          loginStatus: this.isLogin,
        },
      ]
    },
  },

  watch: {
    // 當前語系
    'language.active_default_name': {
      handler(active_default_name) {
        const default_name = this.switchLanguages.filter(lang => lang.code === this.activeLang)
        if (default_name) {
          this.active_default_name = default_name.default_name
        }
      },
      immediate: true,
    },
    // 篩選語系
    'language.searchKeyword': {
      handler(searchKeyword) {
        this.language.resultLangs = this.switchLanguages.filter(lang => {
          const filterItem = ['code', 'default_name', 'localized_name']
          return filterItem.find(item => lang[item].includes(searchKeyword))
        })
      },
      immediate: true,
    },

    // 監聽切換 popup
    'language.status'(newStatus) {
      if (!newStatus) this.language.searchKeyword = ''
    },
  },

  mounted() {
    // 初始化「當前」語系
    const clientLang = sessionStorage.getItem('clientLang')
    if (clientLang) {
      this.activeLang = clientLang
      this.currentLangObj()
      this.getSubNavData()// 取得 navbarData
      return false
    }
    this.activeLang = this.lang
    this.currentLangObj()
    this.getSubNavData() // 取得 navbarData
  },

  methods: {
    ...mapActions([
      'get_nav_bar',
      'get_user_vip',
      'get_user_base_info',
      'get_user_balance',
      'user_logout',
      'show_alert',
      'get_user_avatar_list',
      'set_user_avatar',
      'get_service_url',
      'get_website_theme',
      'get_homepage_layout_area',
      'set_main_balance',
      'set_locked_balance',
      'get_user_wallet_list',
    ]),

    ...mapMutations(['SET_FREE_BALANCE', 'SET_PLAYER_BALANCE']),
    currentLangObj() {
      const currentLangObj = this.switchLanguages.find(lang => lang.code === this.activeLang)
      if (currentLangObj) {
        const { default_name, icon } = currentLangObj
        // 將 default_name 和 icon 屬性寫入 language 對象中的 active_default_name 和 active_default_Icon 屬性中
        this.language.active_default_name = default_name
        this.language.active_default_Icon = icon
      }
    },
    /**
     * 點側選單!item.router的item按鈕，
     * @date 2023-03-04
     */
    handlerItemsTree(itemClass, itemRouter,
    ) {
      switch (itemClass) {
        case 'gameList':
          // 所有遊戲 按鈕
          this.allGameList = !this.allGameList
          break
        case 'home':
          // 首頁 按鈕
          this.closeSubNav(false)
          break
        case 'logOut':
          // 登出 按鈕
          this.userLogout()
          break
        default:
          this.$router.push({ path: itemRouter })
          break
      }
    },
    // 後端的allGameList，寫入靜態側選單的所有遊戲
    getSubNavChildren() {
      const SubNavChildren = this.itemsTree
      const childrenItemIndex = SubNavChildren.findIndex(item => item.class === 'gameList')
      console.log('SubNavChildren', SubNavChildren)
      if (childrenItemIndex !== -1) {
        console.log('childrenItemIndex', childrenItemIndex)
        SubNavChildren[childrenItemIndex].children.push(
          this.subNavChildrenItem,
        )
      }
    },
    // 取得navbarData資料 成功/失敗 處理
    getSubNavDataSuccess(subNavData) {
      this.subNavChildrenItem = subNavData.navbar
      console.log('取得navbarData資料', subNavData)
      this.getSubNavChildren()
    },
    getSubNavDataFail() {
      console.log('再看看')
    },

    // 取得 navbarData
    async getSubNavData() {
      const subNavData = await this.get_nav_bar()
      resStatus(
        subNavData,
        this.getSubNavDataSuccess,
        this.getSubNavDataFail,
      )
    },

    // 設置前端語系
    settingClientLang(clientLang) {
      this.language.status = false
      if (clientLang === this.clientLang) return false

      this.$i18n.locale = clientLang
      sessionStorage.setItem('clientLang', clientLang)
      this.activeLang = clientLang

      // 更新 layout 資料
      this.currentLangObj()
      this.getSubNavData()
      this.get_website_theme()
      this.get_homepage_layout_area()
      this.languageListVisible = false
    },
    /**
     * 關閉側選單
     * @date 2024-02-08
     */
    closeSubNav(type) {
      this.$emit('close-sub-nav', type)
    },
    /**
     * 判斷現在是亮版或暗版 決定icon
     * @date 2023-01-08
     */
    navIcon(item) {
      return this.theme === 'dark' ? item.nav_icon_dark || '' : item.nav_icon_light || ''
    },

    /**
     * 到 vendor 頁
     * @date 2021-12-10
     * @param {string} vendorID
     */
    goVendorPage(vendorID) {
      this.$router.push({
        name: 'vendor',
        params: {
          id: vendorID,
        },
      })
    },
    userLogoutSuccess(data) {
      this.closeSubNav(false)
      this.userBaseInfo = []
      this.show_alert({
        icon: 'done',
        // text: data.message,
      })

      setTimeout(() => {
        this.$router.push({ path: '/' })
      }, 500)
    },

    failDialog(error) {
      this.show_alert({
        icon: 'fail',
        text: error,
      })
    },

    /**
     * 使用者登出
     * @date 2021-04-09
     */
    async userLogout() {
      const res = await this.user_logout()
      resStatus(res, this.userLogoutSuccess, this.failDialog)
    },

    closeDialog() {
      this.dialog.status = false
      this.dialog.form.avatar = this.userBaseInfo.avatar
    },

    goQrCode() {
      // 如果尚未登入
      if (!this.isLogin) {
        return this.show_alert({
          icon: 'fail',
          text: this.$t('flashMessage.loginFirst'),
        })
      }

      const { href } = this.$router.resolve({ path: '/user' })
      const url = `${window.location.origin}/${href}`
      const device = deviceCheck()

      const data = {
        url,
        Authorization: getCookie('accessToken'),
        wtoken: sessionStorage.getItem('wtoken'),
        lang: this.lang,
      }

      this.$log(data)
      this.$log(device)

      try {
        if (device === 'ios') {
          window.webkit.messageHandlers.openQRCode.postMessage(data)
        } else if (device === 'android') {
          window.WebScript.openQRCode(JSON.stringify(data))
        } else this.$router.push({ path: '/qrcode' })
      } catch {
        this.$router.push({ path: '/qrcode' })
      }
    },

    // 卡片 && 存款 && 交易紀錄 按鈕功能
    goPageFunc(routerPath) {
      if (this.isLiffMode && this.isMycardDomainService) return false
      this.$router.push({ path: routerPath })
    },
  },
}
