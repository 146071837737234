<template>
  <div>
    <div class="header p-relative">
      <!-- 底圖 -->
      <v-img
        class="header__bg"
        :src="baseImgUrl + serviceData.bgBanner"
      />

      <!-- 文字說明區塊 -->
      <div class="header__description p-absolute d-flex">
        <div class="w-70">
          <!-- TITLE -->
          <div class="text-h5 icon--text font-weight-bold">
            {{ websiteName + serviceData.title }}
          </div>
          <!-- DESCRIPTION -->
          <div>
            {{ serviceData.description }}
          </div>
        </div>

        <div />
      </div>
    </div>

    <!-- 在線客服 -->
    <div class="px-6 p-relative">
      <div class="primary rounded text-center pt-8 pb-6">
        <Icon
          data="@icon/onliveService.svg"
          height="50"
          width="80"
        />

        <div class="text-h6 white--text mt-1">
          {{ $t('global.onlineCs') }}
        </div>

        <div class="subtitle-2 btnText--text mt-2 mb-5">
          {{ $t('service.24hourService') }}
        </div>

        <!-- 聯繫客服 BTN -->
        <v-btn
          class="navBar"
          height="32"
          @click="toServicePage"
        >
          <span class="white--text caption">
            {{ $t('service.contactService') }}
          </span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import hexToRgba from 'hex-to-rgba'

export default {
  data: () => ({}),

  computed: {
    ...mapGetters(['gradientBg', 'websiteName', 'baseImgUrl']),

    ...mapState({
      serviceUrl: state => state.serviceUrl,
      theme: state => state.theme,
      serviceData: state => state.service.service,
      // 來自 api 的客服設定
      fromApiAboutService: state => state.websiteData.cs_settings,
    }),

    /**
     * primary 色 轉 hex
     * @date 2021-10-08
     * @returns {any}
     */
    primaryColor() {
      const color = this.$vuetify.theme.themes[this.theme]?.primary || ''
      return hexToRgba(color)
    },

    /**
     * 客製化漸層色
     * @date 2021-10-08
     * @returns {any}
     */
    customerClr() {
      const computePrimaryClr = this.primaryColor.split(', ')
      const r = computePrimaryClr[0].slice(5, computePrimaryClr[0].length)
      const g = computePrimaryClr[1]
      const b = computePrimaryClr[2]

      return `background-image: linear-gradient(251deg, rgba(${r}, ${g}, ${b}, 1), rgba(${r - 21}, ${g - 28}, ${b - 42}, 1));`
    },

    /**
     * 判斷取得客服 url 方式
     * @date 2021-12-28
     * @returns {boolean} 是否 打 api 取得 url
     */
    isChatboxUrl() {
      return this.fromApiAboutService.cs_platform_type === 'chatbox'
    },
  },

  mounted() {
    // 如果打 api 取得客服 url 才打
    if (this.isChatboxUrl) this.getServiceUrl()
  },

  methods: {
    ...mapActions([
      'get_service_url',
      'show_alert',
    ]),
    /**
     * 取得客服連結 (失敗)
     * @date 2021-08-17
     */
    getServiceUrlFail() {
      this.show_alert({ icon: 'fail' })
    },

    /**
     * 取得客服連結
     * @date 2021-08-17
     */
    getServiceUrl() {
      this.get_service_url()
    },

    /**
     * 另開客服連結頁面
     * @date 2021-08-17
     */
    toServicePage() {
      if (!this.isChatboxUrl && this.fromApiAboutService.cs_direct_url) return window.open(this.fromApiAboutService.cs_direct_url, '_blank')
      if (!this.serviceUrl) return this.getServiceUrlFail()
      window.open(this.serviceUrl, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
	&__description {
		top: 50px;
		left: 24px;
	}
}

.w-70 {
	width: 70%;
}

</style>
