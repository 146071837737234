<template>
  <div class="d-flex align-center justify-center">
    <div class="text-center ">
      <v-img
        width="200"
        class="mb-4"
        src="../../assets/icons/nodata.png"
      />
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped></style>
