import { getRecommendBonusAPI } from '@/api'

const state = () => ({
  value: 'recommend',
})

const getters = {
}

const mutations = {

}

const actions = {
  async get_user_recommend() {
    const res = await getRecommendBonusAPI()
    return res
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
