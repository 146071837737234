var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-center justify-center" }, [
    _c(
      "div",
      { staticClass: "text-center" },
      [
        _c("Icon", {
          staticClass: "hint--text d-block mb-4 o-5",
          attrs: {
            data: require("@icon/nodata.svg"),
            width: "86",
            height: "81",
          },
        }),
        _c("span", { staticClass: "hint--text" }, [_vm._v(_vm._s(_vm.text))]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }