<template>
  <div
    class="bonusPage"
    :class="[ currentDevice === 'ios' ? 'pb-72' : 'pb-56', { 'pt-56': hasToolbar } ]"
  >
    <v-container>
      <v-row class="">
        <v-col
          cols="12"
          class="p-fixed pb-0 px-0 bg z-index-4"
        >
          <h1
            v-if="!hasToolbar"
            class="title--text text-h6 pl-6"
          >
            {{ $t('menu.preferentialActivity') }}
          </h1>

          <v-tabs
            background-color="bg"
            center-active
            show-arrows
          >
            <v-tab
              class="subtitle-1 font-weight-medium"
              @click="changeBonusNewsType('all')"
            >
              {{ $t('global.all') + $t('bottomNav.preferentialActivity') }}
            </v-tab>

            <v-tab
              v-for="item in tab"
              :key="item._id"
              class="subtitle-1 font-weight-medium"
              @click="changeBonusNewsType(item.code)"
            >
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-row
        v-if="bonusNews.length === 0"
        class="ma-0 pt-100"
        align="center"
        justify="center"
      >
        <v-col>
          <NodataTemplate3 :text="$t('global.noRecords')" />
        </v-col>
      </v-row>

      <v-row
        v-else
        class="pt-70 px-1"
      >
        <v-col
          v-for="item in bonusNews"
          :key="item._id"
          cols="12"
          class="py-1 "
        >
          <div class="p-relative">
            <router-link
              class="text-decoration-none bonus z-index-3"
              :to="{
                name: 'bonusItem',
                params: {
                  id: item.promo_id,
                },
              }"
            >
              <div
                class="subtitle-2 p-absolute  z-index-1 white--text tag-bg px-2 py-1 custom-radius"
              >
                {{ item.valid_date }}
              </div>
              <v-img
                min-height="130"
                class="rounded-lg"
                :src="baseImgUrl + item.banner"
                :alt="item.title"
              >
                <v-row
                  slot="placeholder"
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  />
                </v-row>
              </v-img>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import indexMixins from '../mixins/indexMixin'

export default {
  mixins: [indexMixins],
}
</script>

<style lang="scss" scoped>

// TODO fixed 高度設定
.pt-70 {
	padding-top: 70px;
}

.bonus {
	&__more {
		right: 16px;
		bottom: 14px;
		z-index: 2;
	}

	&__description {
		line-height: 24px;
	}

	&__item-bg {
		right: 0;
		bottom: 0;
		width: 110px;
		height: 100%;
		background-image:
			linear-gradient(to right,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.47) 36%,
			rgba(0, 0, 0, 0.8) 90%);
	}
}

.z-index-3 {
	z-index: 3;
}

.z-index-4 {
	z-index: 4;
}
.rounded-10px {
  border-radius: 10px;
}
.tag-bg {
  background: linear-gradient(270deg, #BE5A4F -6.94%, #C03111 100%);
}
.custom-radius {
  border-top-left-radius: 10px;
  border-bottom-right-radius: 4px;
}
</style>
