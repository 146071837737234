export const mobileDevice = () => {
  const device = navigator.userAgent

  // MOBILE CHROME
  if (device.indexOf('CriOS') > 0 && device.indexOf('Safari') > 0) return false

  // MOBILE SAFARI
  else if (device.indexOf('Mobile') > 0 && device.indexOf('Safari') > 0) return 'Safari'
  // Desktop Safari
  else if (device.indexOf('Safari') > 0 && device.indexOf('Version') > 0) return 'Safari'
}
