// 國家城市入口
import china from './china.json'
import vn from './vn.json'
import thai from './thai.json'

export default {
  china,
  vn,
  thai,
}
