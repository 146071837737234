<template>
  <!-- 电子遊艺/分類列表 -->
  <div
    id="slot"
    v-scroll.self="onScroll"
    class="pt-56 pb-10 h-100dvh over-flow-y-scroll"
  >
    <!-- Toolbar -->
    <component :is="layoutTemplate" />
    <v-container>
      <SearchBarTemplate7>
        <!-- 搜尋輸入框 -->
        <v-text-field
          id="searchInput"
          slot="input"
          v-model.trim="form.filter"
          autofocus
          class="pa-0 searchBarTemp7"
          hide-details
          height="36"
          :placeholder="$t('game.searchGame')"
          rounded
          autocomplete="off"
          dense
          @focus="setSearchMode"
          @input="setSearchMode"
        />

        <!-- 刪除輸入文字 -->
        <v-btn
          v-if="form.filter"
          slot="clearInputTest"
          icon
          @click="clearFilterKeyword"
        >
          <v-icon size="16">
            mdi-close-circle
          </v-icon>
        </v-btn>
      </SearchBarTemplate7>

      <!-- 如果沒有資料 -->
      <template v-if="games.length === 0">
        <v-row>
          <v-col
            cols="12"
            class="mt-10"
          >
            <Nodata
              class="mt-10"
              :text="$t('game.noGames', { type: tabItem[tab] })"
            />
          </v-col>
        </v-row>
      </template>

      <!-- 如果有遊戲 -->
      <template v-else>
        <GameList
          :games="games"
          :scroll-bottom="scrollBottom"
          @favorite-game-toggle="favoriteGameToggle"
        />
      </template>
    </v-container>
  </div>
</template>

<script>
import slotMixin from '../mixins/slotMixin'

export default {
  mixins: [slotMixin],
}
</script>

<style lang="scss" scoped>
.top-0 {
	top: 0;
}

</style>
