import store from '../store'
import { Decimal } from 'decimal.js'

export const spec = (money) => {
  // 來自的國家
  const nation = store.getters.nation

  // 判斷定義
  const isNumber = typeof money === 'number' // 為數字
  const notFloat = money % 1 === 0 // 為整數
  const isZero = money === '0'

  // 字尾訂義
  const chinaMoneyEndStr = '.00'
  const vnMoneyEndStr = '.000'

  switch (nation) {
    // 如果 china
    case 'china':
      // 如果數字為 0 或 為整數
      if ((isNumber && notFloat) || isZero) return money + chinaMoneyEndStr
      else return money

      // 如果 泰國
    case 'thai':
      // 如果數字為 0 或 為整數
      if ((isNumber && notFloat) || isZero) return money + chinaMoneyEndStr
      else return money

    // 如果 越南
    case 'vn':
      // 如果數字為 0 或 為整數
      if ((isNumber && notFloat) || isZero) return money
      else return money

    // 未定義 國家 回傳原始
    default:
      return money
  }
}

/**
 * 金額做無條件捨去至小數點二位
 * @param {string | number} amount 金額
 * @returns {string}
 */
export function amountRoundDown(amount) {
  const fmt = (amount) => {
    // 將金額做無條件捨去至小數點二位
    const val = new Decimal(amount).toFixed(2, Decimal.ROUND_DOWN)
    return val
  }
  return isNaN(amount) || amount === '' ? '-' : fmt(amount)
}
