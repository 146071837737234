import device from 'current-device'

/**
 * 判斷裝置
 * @date 2022-01-12
 * @returns {object} IOS ANDROID DESKTOP 判斷
 */
export const deviceCheck = () => {
  const ios = device.os === 'ios'
  const android = device.os === 'android'
  const all = device.type === 'desktop' // option
  const outPutObj = {
    ios,
    android,
    all,
  }
  let outPutDevice = null
  Object.keys(outPutObj).forEach(index => {
    if (outPutObj[index]) outPutDevice = index
  })
  return outPutDevice
}

// 判斷 webview
export const isWebview = () => {
  const userAgent = navigator.userAgent

  const webview = userAgent.indexOf('platform')
  const ios = userAgent.indexOf('iOS')
  const android = userAgent.indexOf('android')

  if (webview < 0 && ios < 0) return false
  if (webview < 0 && android < 0) return false
  else return true
}
