var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "header w-100" }, [
      _vm.layout != "template7"
        ? _c("div", { staticClass: "service-bg", style: _vm.customerClr })
        : _vm._e(),
      _c("div", { staticClass: "service-bg-2 primary" }),
      _c(
        "div",
        {
          staticClass: "toolbar px-5 py-4 d-flex align-center w-100",
          style: _vm.layout == "template7" ? "" : _vm.customerClr,
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "pa-0",
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [
              _c("Icon", {
                staticClass: "icon--text",
                attrs: {
                  data: require("@icon/toolbar/arrow_left.svg"),
                  width: "16px",
                  height: "16px",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            { staticClass: "title--text font-weight-medium text-h6 ml-5" },
            [_vm._v(" " + _vm._s(_vm.$t("service.customerService")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "contentBox w-100",
          style: { bottom: _vm.bottomNavigationHight + 5 + "px" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "user-area navBar rounded mx-6 px-5 py-4 d-flex align-center",
              class:
                _vm.layout == "template7" ? "shadow-round-container mt-3" : "",
            },
            [
              _c("div", { staticClass: "w-100" }, [
                _c(
                  "div",
                  { staticClass: "user-area__info d-flex align-center" },
                  [
                    _c(
                      "div",
                      { staticClass: "user-area__avatar" },
                      [
                        _c(
                          "v-avatar",
                          {
                            staticClass: "mt-2",
                            style: _vm.avatarBorderClr,
                            attrs: { size: "60", color: "primary" },
                          },
                          [
                            _vm.userData.avatar
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.baseImgUrl + _vm.userData.avatar,
                                    alt: _vm.userName,
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "user-area__info ml-6" }, [
                      _c("div", { staticClass: "subtitle-1 title--text" }, [
                        _vm._v(" " + _vm._s(_vm.userInfo.title) + " "),
                      ]),
                      _c("div", { staticClass: "caption comment--text" }, [
                        _vm._v(" " + _vm._s(_vm.userInfo.description) + " "),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "px-6 mt-4" },
            [
              _vm.fromApiAboutService.cs_platform_type !== "revechat"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "cal-center-btn",
                      style:
                        _vm.layout == "template7"
                          ? _vm.gradientBg
                          : _vm.customerClr,
                      attrs: { block: "", height: "87" },
                      on: {
                        click: function ($event) {
                          return _vm.toServicePage()
                        },
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "8" } }, [
                                _c("div", { staticClass: "d-flex" }, [
                                  _c(
                                    "div",
                                    [
                                      _c("Icon", {
                                        staticClass: "btnText--text",
                                        attrs: {
                                          data: require("@icon/bottomNav/service.svg"),
                                          width: "24px",
                                          height: "24px",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "btnText--text subtitle-1 text-left",
                                        class: [
                                          {
                                            caption: _vm.clientLang === "vi-VN",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("global.onlineCs")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "caption",
                                        class:
                                          _vm.layout == "template7"
                                            ? "btnText--text"
                                            : "icon--text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("service.24hourService")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex justify-end align-center",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "btnText--text",
                                      attrs: { right: "" },
                                    },
                                    [_vm._v(" mdi-chevron-right ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "px-8 mt-3" },
            [
              _c(
                "v-row",
                _vm._l(_vm.floatingPanels, function (item) {
                  return _c(
                    "v-col",
                    {
                      key: item._id,
                      staticClass: "pb-0 pl-1 pr-1",
                      attrs: { cols: _vm.floatingPanelsisOdd ? 4 : 6 },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "cal-center-btn",
                          style:
                            _vm.layout == "template7"
                              ? `background-blend-mode: color; ${_vm.customerClr}`
                              : _vm.customerClr,
                          attrs: { width: "100%", height: "50" },
                          on: {
                            click: function ($event) {
                              return _vm.toServicePage(item.click_url)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "btnText--text",
                            attrs: {
                              src: _vm.baseImgUrl + item.icon_h5,
                              width: "24px",
                              height: "24px",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }