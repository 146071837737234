import Cookie from 'js-cookie'

/**
 * 取得 cookie (若無 index 則取全部)
 * @date 2021-03-03
 * @param {any} index - 項目
 * @returns {any}
 */
export const getCookie = (index = '') => Cookie.get(index)

/**
 * 設置 cookie
 * @date 2021-03-03
 * @param {string} index - 項目
 * @param {string} value - 內容
 */
export const setCookie = (index = '', value = '', expires) => {
  if (expires) {
    return Cookie.set(index, value, {
      expires: expires,
    })
  }
  Cookie.set(index, value)
}

/**
 * 移除單項 cookie
 * @date 2021-03-03
 * @param {string} index - 要刪除的項目
 */
export const removeCookie = (index = '') => {
  Cookie.remove(index)
}

/**
 * 移除所有 cookie
 * @date 2021-03-03
 */
export const removeCookieAll = () => {
  const allCookieData = getCookie()
  Object.keys(allCookieData).forEach(index => Cookie.remove(index))
}
