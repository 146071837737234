<template>
  <div class="userInfo user primary d-flex align-center px-4 p-relative">
    <!-- 使用者區塊 -->
    <div class="user-area w-100">
      <v-container>
        <v-row>
          <v-col
            cols="4"
            class="pa-0"
          />
          <v-col
            cols="4"
            class="text-center pa-0"
          >
            <v-avatar
              color="secondary"
              size="48"
            >
              <img
                v-if="websiteLogo"
                :src="baseImgUrl + websiteLogo"
              >
            </v-avatar>
          </v-col>
          <v-col
            cols="4"
            class="text-right pa-0 d-flex justify-end align-center"
          >
            <template v-if="!isLogin">
              <router-link
                to="/register"
                class="caption white--text text-decoration-none"
              >
                {{ $t('subHeader.register') }}
              </router-link>
              <span class="mx-1">
                |
              </span>
              <router-link
                to="/login"
                class="caption white--text text-decoration-none"
              >
                {{ $t('subHeader.login') }}
              </router-link>
            </template>
            <template v-else>
              <router-link to="/service">
                <Icon
                  class="white--text"
                  data="@icon/userinfo-service-template3.svg"
                  width="25"
                  height="25"
                />
              </router-link>
            </template>
          </v-col>
        </v-row>
      </v-container>
      <template v-if="false">
        <!-- 如果尚未登入 -->
        <template v-if="(!isLogin)">
          <!-- 登入 -->
          <!-- 如果 liff 不顯示登入 btn -->
          <v-btn
            v-if="!isLiffMode"
            to="/login"
            outlined
            height="31"
            class="title--text text-center px-1 mr-3"
          >
            <span class="caption">
              {{ $t('subHeader.login') }}
            </span>
          </v-btn>

          <!-- 註冊 -->
          <v-btn
            v-if="registerStatus"
            to="/register"
            height="31"
            class="title--text text-center px-1 thaigi-gradient"
            :style="style === 'darkGD' ? '' : customGradientBgTypeA"
          >
            <span class="caption">
              {{ $t('subHeader.register') }}
            </span>
          </v-btn>
        </template>

        <!-- 如果登入 -->
        <template v-else>
          <div class="pl-0">
            <!-- liff 模式 -->
            <template v-if="isLiffMode">
              <!-- 有頭像 -->
              <template v-if="lineAvatar">
                <router-link to="/user">
                  <v-avatar>
                    <img
                      size="20"
                      :src="lineAvatar"
                      :alt="lineDisplayName"
                    >
                  </v-avatar>
                </router-link>
              </template>

              <!-- 無頭像 -->
              <template v-else>
                <Icon
                  v-show="!lineIsLogin"
                  data="@icon/user.svg"
                  width="24"
                  height="24"
                  class="icon--text"
                />
              </template>
              <span class="title--text caption ml-2">
                {{ lineDisplayName }}
              </span>
            </template>

            <!-- 一般模式 -->
            <template v-else>
              <Icon
                data="@icon/user.svg"
                width="24"
                height="24"
                class="icon--text"
              />
              <span class="title--text caption ml-2">
                {{ userAccount }}
              </span>
            </template>
          </div>
        </template>
      </template>
    </div>

    <!-- 使用者錢包 -->
    <div
      v-if="false"
      class="user-balance d-flex align-center"
    >
      <!-- 取得錢包 loading -->
      <div
        v-if="userBalanceProgressStatus || balanceLoading"
      >
        <v-progress-circular
          class="mr-4"
          indeterminate
          :width="2"
          :size="20"
          color="primary"
        />
      </div>

      <!-- (成功) 取得使用者錢包 -->
      <div
        v-else
        class="d-flex align-center"
      >
        <!-- 一鍵回收 -->
        <v-btn
          v-if="!overlay && isLogin"
          icon
          @click="retrieveAllWalletBalance"
        >
          <Icon
            data="@icon/user/walletItem/transfer.svg"
            width="23"
            height="20"
          />
        </v-btn>

        <span class="mr-1 title--text">
          {{ currencySymbol_ }}
        </span>
        <span class="text-h5 title--text">
          {{ userBalance }}</span>
      </div>
      <!-- 豬 pig icon -->
      <Icon
        data="@icon/moneyPig.svg"
        width="32"
        height="32"
        class="secondary--text ml-1"
        @click="fastTrade"
      />
    </div>

    <!-- OVERLAY -->
    <v-overlay
      :value="overlay"
      opacity=".8"
    />

    <div
      v-show="overlay"
      class="btn-grop p-absolute"
    >
      <div class="d-flex align-center">
        <!-- 取款 -->
        <v-btn
          v-if="(isLiffMode && !isMycardDomainService) || (!isLiffMode)"
          to="user/withdrawal"
          text
          height="56"
          class="px-2"
        >
          <div class="d-flex flex-column align-center justify-center white--text caption font-weight-bold">
            <div class="iconGy rounded-circle w-h-32 d-flex justify-center align-center">
              <Icon
                data="@icon/user/walletItem/withdrawal.svg"
                width="23px"
                height="20px"
                class="black--text"
              />
            </div>
            {{ $t('global.withdraw') }}
          </div>
        </v-btn>

        <!-- 轉換 -->
        <v-btn
          to="user/transfer"
          text
          height="56"
          class="px-2"
        >
          <div class="d-flex flex-column align-center justify-center white--text caption font-weight-bold">
            <div class="iconGy rounded-circle w-h-32 d-flex justify-center align-center">
              <Icon
                data="@icon/user/walletItem/transfer.svg"
                width="23px"
                height="20px"
                class="black--text"
              />
            </div>
            {{ $t('global.transferBtn') }}
          </div>
        </v-btn>

        <!-- 存款 -->
        <v-btn
          text
          height="56"
          class="px-2"
          @click="goPageFunc('user/deposit')"
        >
          <div class="d-flex flex-column align-center justify-center white--text font-weight-bold subtitle-2">
            <div class="white rounded-circle w-h-35 d-flex justify-center align-center">
              <Icon
                data="@icon/user/walletItem/deposit.svg"
                class="black--text"
                width="32"
                height="25"
              />
            </div>
            {{ $t('global.deposit') }}
          </div>
        </v-btn>

        <!-- 關閉快捷 -->
        <v-btn
          icon
          class="mr-4"
          @click="overlay = false"
        >
          <div class="rounded-circle w-h-32 d-flex align-center justify-center">
            <Icon
              data="@icon/money-btn-close.svg"
              width="31"
              height="31"
              class="secondary--text"
            />
          </div>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import userInfoMixin from '../mixins/userinfo'

export default {
  mixins: [userInfoMixin],
}
</script>

<style lang="scss" scoped>
.userInfo {
	height: 56px;
}

.btn-grop {
	right: 0;
	z-index: 99;
}

.w-h-32 {
	width: 32px;
	height: 32px;
}

.w-h-35 {
	width: 35px;
	height: 35px;
}

.thaigi-gradient {
  top: 0;
  right: 0;
  background-image: linear-gradient(110deg, #e6ce71 -5%, #bc954d 20%, #a57539 49%, #c09a50 77%, #e6ce71 100%);
}
</style>
