import axios from 'axios'

const lineNotify = axios.create({
  baseURL: 'https://bo.hello.laiplay.win',
  timeout: 3000,
})

lineNotify.interceptors.request.use(
  config => {
    return config
  },
)

lineNotify.interceptors.response.use(
  response => {
    return response
  },
  error => {
    console.error(error) // for debug
    return Promise.reject(error)
  },
)

export default lineNotify
