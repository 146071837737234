import { getMarqueeAPI } from '@/api'

const state = () => ({

})

const getters = {

}

const mutations = {

}

const actions = {
  async get_marquee() {
    const res = await getMarqueeAPI()
    return res
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
