<template>
  <div>
    <div class="download-app d-flex align-center px-2 white">
      <!-- CLOSE BTN -->
      <v-btn
        icon
        width="25"
        @click="$emit('close-download-app')"
      >
        <v-icon
          size="18"
          color="black"
        >
          mdi-close
        </v-icon>
      </v-btn>

      <!-- APP ICON -->
      <div class="app-icon primary w-h-35 rounded ml-0 mr-1">
        <v-img
          class="rounded"
          :src="appIcon"
        />
      </div>

      <!-- desription -->
      <div class="app-description d-flex flex-column">
        <h5 class="black--text">
          {{ websiteName }} APP
        </h5>
        <div class="caption grey--text">
          {{ appData.description && appData.description.length > 10 ? appData.description.slice(0, 22) + '...' : appData.description }}
        </div>
      </div>

      <v-spacer />

      <!-- Download Btn -->
      <v-btn
        height="26"
        class="caption font-weight-bold bg"
        @click="dialog = true"
      >
        {{ $t('global.downloadNow') }}
      </v-btn>
    </div>

    <!-- DIALOG -->
    <v-dialog
      v-model="dialog"
      overlay-opacity=".8"
      :max-width="280"
      persistent
    >
      <!-- 關閉 dialog btn -->
      <div class="text-right mb-3">
        <v-btn
          icon
          outlined
          dark
          max-height="24"
          max-width="24"
          class="close-dialog-icon mr-1"
          @click="dialog = false"
        >
          <v-icon
            size="18"
            color="white"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <v-card
        class="white rounded-lg"
      >
        <v-img
          class="py-0"
          :src="downloadAppBgLogo ? baseImgUrl + downloadAppBgLogo : require('@/assets/image/downloadAppPopup.png')"
        />

        <v-container
          class="ma-0"
          fluid
        >
          <v-row dense>
            <v-col
              cols="12"
              class="py-0"
            >
              <template v-if="sportAppData.downloadUrl">
                <!-- 虎爷体电APP -->
                <div class="app-item d-flex align-center py-4 px-1">
                  <!-- APP ICON -->
                  <div class="app-icon primary w-h-47 rounded pa-1 ml-0 mr-2">
                    <v-img
                      :src="baseImgUrl + sportAppData.icon"
                    />
                  </div>

                  <!-- desription -->
                  <div class="app-description d-flex flex-column">
                    <h5 class="black--text subtitle-2 font-weight-bold">
                      {{ sportAppData.title }}
                    </h5>
                    <div class="caption line-height-4 grey--text">
                      {{ sportAppData.description }}
                    </div>
                  </div>
                  <!-- Download Btn -->
                  <v-btn
                    rounded
                    height="24"
                    class="font-weight-bold ml-1 bg"
                    @click="downloadApp(sportAppData.downloadUrl)"
                  >
                    {{ $t('global.download') }}
                  </v-btn>
                </div>

                <div class="px-1">
                  <v-divider class="divider" />
                </div>
              </template>

              <!-- 金虎爷全站APP -->
              <div
                v-if="appData.title"
                class="app-item d-flex align-center py-4 px-1"
              >
                <div class="app-icon primary w-h-47 rounded ml-0 mr-2">
                  <v-img
                    class="rounded"
                    :src="baseImgUrl + appData.icon"
                  />
                </div>

                <!-- desription -->
                <div class="app-description d-flex flex-column">
                  <h5 class="black--text subtitle-2 font-weight-bold">
                    {{ appData.title }}
                  </h5>
                  <div class="caption line-height-4 grey--text">
                    {{ appData.description }}
                  </div>
                </div>

                <!-- Download Btn -->
                <div>
                  <!-- 橫向 app 下載 -->
                  <v-btn
                    rounded
                    height="24"
                    class="font-weight-bold ml-1 bg mb-3 caption"
                    @click="downloadApp('landscape')"
                  >
                    {{ $t('global.download') }}
                  </v-btn>

                  <!-- 直向 app 下載 -->
                  <!-- <v-btn
                    rounded
                    height="24"
                    class="font-weight-bold ml-1 bg caption"
                    @click="downloadApp('')"
                  >
                    {{ $t('global.upright') + '  APP' }}
                  </v-btn> -->
                </div>
              </div>

              <!-- 若 全站與體電 app 都沒有設置資料 -->
              <div
                v-if="!appData.title && !sportAppData.downloadUrl"
                class="black--text text-center"
              >
                Nodata...
              </div>
            </v-col>

            <input
              id="recommendCode"
              type="text"
              :value="recommendCode"
            >
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-divider class="divider" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { copyNumber } from '../../../utils/copy'
import { deviceCheck } from '../../../utils/device'

export default {

  data: () => ({
    dialog: false,

    downloadUrl: '',
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'websiteName']),

    ...mapState({
      webLogo: state => state.websiteData.site_params.logo_app,
      mobile: state => state.websiteData.mobile,
      recommendCode: state => state.user.userData.recommend_code,

      downloadData: state => state.service.downloadApp,
      // 全站 app
      appData: state => state.service.application,
      // 體電 app
      sportAppData: state => state.service.sportApp,
      downloadAppBgLogo: state => state.websiteData.mobile.app_download_image,
    }),

    /**
     * 虎爷体电APP URL
     * 如果有 url 才顯示
     * @date 2021-08-27
     * @returns {string} url
     */
    sportAppUrl() {
      return false
    },

    /**
     * android 下載連結
     * @date 2021-08-27
     * @returns {string} android 下載連結
     */
    androidDownloadUrl() {
      return this.mobile.download_url_android
    },

    /**
     * ios 下載連結
     * @date 2021-08-27
     * @returns {string} ios 下載連結
     */
    iosDownloadUrl() {
      return this.mobile.download_url_ios
    },

    /**
     * APP ICON LOGO (若後台沒有設置 logo 則帶 website logo)
     * @date 2021-10-20
     */
    appIcon() {
      const appICon = this.appData.icon ? this.appData.icon : this.webLogo
      return this.baseImgUrl + appICon
    },
  },

  mounted() {
    this.deviceCheck()
  },

  methods: {
    ...mapActions(['get_app_download_url', 'set_website', 'show_alert']),

    /**
     * 復製文字
     * @date 2021-08-31
     * @param {string} id 需要復製文字的 input id
     */
    copyNumber(id) {
      copyNumber(id)
    },

    goDownloadPage(downloadUrl) {
      if (!downloadUrl) {
        this.show_alert({
          icon: 'fail',
        })
        return false
      }

      this.copyNumber('recommendCode')
      location.href = downloadUrl
    },

    /**
     * 下載 app && 復製 recommend
     * @date 2022-01-22
     */
    async downloadApp(downloadType) {
      const device = deviceCheck()
      const website = await this.set_website()
      const {
        data: {
          android_landscape_update_url, app_landscape_download_url,
          android_update_url, app_download_url,
        },
      } = await this.get_app_download_url({ website })

      // 如果安卓
      if (device === 'android') {
        // 如果橫板
        downloadType === 'landscape'
          ? this.goDownloadPage(android_landscape_update_url)
          : this.goDownloadPage(android_update_url)
      } else {
        downloadType === 'landscape'
          ? this.goDownloadPage(app_landscape_download_url)
          : this.goDownloadPage(app_download_url)
      }
    },

    /**
     * 判斷行動裝置 (ios/android)
     * @date 2021-08-31
     */
    deviceCheck() {
      const device = navigator.userAgent
      const isAndroid = device.indexOf('Android') > -1 || device.indexOf('Adr') > -1
      const isiOS = !!device.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

      if (isiOS) this.downloadUrl = this.iosDownloadUrl
      else this.downloadUrl = this.androidDownloadUrl
    },
  },
}
</script>

<style lang="scss" scoped>
.download-app {
	height: 52px;
}

.w-h-35 {
	width: 35px;
	height: 35px;
}

.w-h-47 {
	width: 47px;
	height: 47px;
}

.line-height-4 {
	line-height: 16px;
}

#recommendCode {
	height: 0;
}
</style>
