var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "download-app d-flex align-center px-2 white" },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "", width: "25" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close-download-app")
                },
              },
            },
            [
              _c("v-icon", { attrs: { size: "18", color: "black" } }, [
                _vm._v(" mdi-close "),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "app-icon primary w-h-35 rounded ml-0 mr-1" },
            [
              _c("v-img", {
                staticClass: "rounded",
                attrs: { src: _vm.appIcon },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "app-description d-flex flex-column" }, [
            _c("h5", { staticClass: "black--text" }, [
              _vm._v(" " + _vm._s(_vm.websiteName) + " APP "),
            ]),
            _c("div", { staticClass: "caption grey--text" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.appData.description &&
                      _vm.appData.description.length > 10
                      ? _vm.appData.description.slice(0, 22) + "..."
                      : _vm.appData.description
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "caption font-weight-bold bg",
              attrs: { height: "26" },
              on: {
                click: function ($event) {
                  _vm.dialog = true
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.downloadNow")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "overlay-opacity": ".8", "max-width": 280, persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "div",
            { staticClass: "text-right mb-3" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "close-dialog-icon mr-1",
                  attrs: {
                    icon: "",
                    outlined: "",
                    dark: "",
                    "max-height": "24",
                    "max-width": "24",
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { size: "18", color: "white" } }, [
                    _vm._v(" mdi-close "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "white rounded-lg" },
            [
              _c("v-img", {
                staticClass: "py-0",
                attrs: {
                  src: _vm.downloadAppBgLogo
                    ? _vm.baseImgUrl + _vm.downloadAppBgLogo
                    : require("@/assets/image/downloadAppPopup.png"),
                },
              }),
              _c(
                "v-container",
                { staticClass: "ma-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _vm.sportAppData.downloadUrl
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "app-item d-flex align-center py-4 px-1",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "app-icon primary w-h-47 rounded pa-1 ml-0 mr-2",
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            src:
                                              _vm.baseImgUrl +
                                              _vm.sportAppData.icon,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "app-description d-flex flex-column",
                                      },
                                      [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "black--text subtitle-2 font-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.sportAppData.title) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "caption line-height-4 grey--text",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.sportAppData.description
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "font-weight-bold ml-1 bg",
                                        attrs: { rounded: "", height: "24" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadApp(
                                              _vm.sportAppData.downloadUrl
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("global.download")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "px-1" },
                                  [_c("v-divider", { staticClass: "divider" })],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm.appData.title
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "app-item d-flex align-center py-4 px-1",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "app-icon primary w-h-47 rounded ml-0 mr-2",
                                    },
                                    [
                                      _c("v-img", {
                                        staticClass: "rounded",
                                        attrs: {
                                          src:
                                            _vm.baseImgUrl + _vm.appData.icon,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "app-description d-flex flex-column",
                                    },
                                    [
                                      _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "black--text subtitle-2 font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.appData.title) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "caption line-height-4 grey--text",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.appData.description) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "font-weight-bold ml-1 bg mb-3 caption",
                                          attrs: { rounded: "", height: "24" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadApp(
                                                "landscape"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("global.download")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          !_vm.appData.title && !_vm.sportAppData.downloadUrl
                            ? _c(
                                "div",
                                { staticClass: "black--text text-center" },
                                [_vm._v(" Nodata... ")]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c("input", {
                        attrs: { id: "recommendCode", type: "text" },
                        domProps: { value: _vm.recommendCode },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "divider" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }