import request from '@/api/request'

/*
  API 紀錄
  紀錄相關的 API
    - 取得轉帳紀錄
    - 取得投注紀錄
    - 取得存取款紀錄
*/

/* 取得轉帳紀錄 - 10.4 交易紀錄 */
export const getTransactionRecord = data =>
  request.post('api/v1/member/transactionlog', data)

/* 取得投注紀錄 - 10.5 投注紀錄 */
export const getWagerRecord = data =>
  request.post('api/v1/member/betlogs', data)

/* 取得存取款紀錄 - 10.4 交易紀錄 */
export const getPaymentRecord = data =>
  request.post('api/v1/member/paymentlog', data)

/* 玩家回報已匯款 */
export const paymentReport = data =>
  request.post('/api/v1/member/bankcard-payment-report', data)

/* 取得玩家私卡付款資訊 */
export const getPaymentInfo = data =>
  request.post('/api/v1/member/bankcard-payment-info', data)

/* 取得兌獎紀錄 */
export const getUserredeemRecord = data =>
  request.post('/api/v1/member/redeem-log', data)

/* 取得兌獎紀錄-紅利-類型清單 */
export const getUserMemberSubtypeList = () =>
  request.get('/api/v1/member/redeem-type')

/* 取得玩家推薦禮金總覽 */
export const getRecommendBonus = () =>
  request.get('/api/v1/member/recommend-bonus')
