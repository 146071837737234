<template>
  <v-app
    :class="style"
    class="bg"
  >
    <img
      v-if="layout && layout==='template6'"
      :src="watermarkImgUrl"
      height="300"
      class="watermarkImgUrl"
    >
    <img
      v-if="layout && layout==='template6'"
      :src="watermarkImgUrl"
      height="300"
      class="watermarkImgUrl"
    >
    <!-- 設置背景色 -->
    <v-main
      :class="layout"
    >
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import isLocalDev from './utils/isLocalDev'
import { deviceCheck } from './utils/device'
import VConsole from 'vconsole'
import { parseUrlQuery } from './utils/liff'

import pageConfig from '../package.json'

export default {
  name: 'App',

  data: () => ({}),

  computed: {
    ...mapGetters(['baseImgUrl', 'isLiffMode', 'layout', 'theme']),
    ...mapState({
      style: (state) => state.style,
      favicon: (state) => state.websiteData.site_params?.favicon,
      websiteLogo: state => state.websiteData.site_params,
    }),
    device() {
      return deviceCheck()
    },
    // 浮水印路徑
    watermarkImgUrl() {
      return `${this.baseImgUrl}${this.websiteLogo[`logo_${this.theme}`]}`
    },

  },
  watch: {
    $route(to, from) {
      // console.log(to)
      if (to.path !== from.path) {
        // 同頁重整不打API
        this.pageVisit()
      }
    },
  },
  mounted() {
    this.$detail(this.isLiffMode)
    // vConsole 顯示 (本地開發 && 行動裝置) 或 (url 加上 ?log=true)
    if (
      (this.device !== 'all' && isLocalDev()) ||
      parseUrlQuery(location.search).log === 'true'
    ) {
      const vConsole = new VConsole({ theme: 'dark' })
    }
    this.$log(`--- v${pageConfig.version} ---`)
    this.$log(`Liff Mode(getters): ${this.isLiffMode}`)
  },
  methods: {
    ...mapActions([
      'page_visit_send',
    ]),
    async pageVisit() {
      const data = {
        url: window.location.href,
      }
      await this.page_visit_send(data)
    },
  },
}
</script>

<style lang="scss">
.watermarkImgUrl{
  position: fixed;
  left: 20vw;
  top: 20vh;
  opacity: 0.12;
  &+.watermarkImgUrl{
    top: 55vh;
    left: -80vw;
  }
 }
</style>
