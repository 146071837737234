// Utils
import isLocalDev from './isLocalDev'
import store from '@/store'
import { checkApiUrlEnv } from '@/utils/lineNotify'

// Const
const color = store.state?.websiteData?.site_params?.color
const mode = store.state?.websiteData?.site_params?.mode

const currentColorName = () => {
  return process.env.VUE_APP_USE_API_LAYOUT === 'true'
    ? `${color} (來自API)`
    : `${process.env.VUE_APP_TYPE ? process.env.VUE_APP_TYPE + '(來自環境變數)' : ''}`
}

const currentTheme = () => {
  return process.env.VUE_APP_USE_API_LAYOUT === 'true'
    ? `${mode}` ? `${mode}(來自API)` : ''
    : `${process.env.VUE_APP_THEME ? process.env.VUE_APP_THEME : ''}`
}

const showProjectDetail = (
  isLiffMode,
  theme = currentTheme,
  color = currentColorName) => {
  if (!isLocalDev()) return

  const style = 'border-radius: 8px; padding: 0.3rem 1.6rem; font-family: Roboto; font-size: 1.3em; line-height: 1.4em; color: white; background-color: #4158D0; background-image: linear-gradient(43deg, #4158D0 0%, #c850C0 46%, #FFCC70 100%);'

  let str = `%c🦄 環境(NODE_ENV)：${process.env.NODE_ENV}
🦄 站台：${process.env.VUE_APP_WEBSITE}
🦄 使用來自 API 版型設定：${process.env.VUE_APP_USE_API_LAYOUT}
🦄 請求 API 環境：${checkApiUrlEnv}
🦄 請求 API URL：${process.env.VUE_APP_API}
🦄 Liff Mode 模式: ${isLiffMode}`

  if (process.env.VUE_APP_USE_API_LAYOUT === 'false') {
    str += `
🦄 版型：${process.env.VUE_APP_TEMPLATE}
🦄 主題色：${color()}
🦄 模式：${theme()}`
  }

  console.log(str, style)
}

/**
 * console 工具
 * @date 2021-03-04
 * @param {any} ...params 任何傳的東西，都會拆解來 console
 * @returns {any} 拆解後的內容
 */
export default {
  $log: (...params) => console.log(...params),
  $error: (...params) => console.error(...params),
  $warn: (...params) => console.warn(...params),
  $detail: (...params) => showProjectDetail(...params),
}
