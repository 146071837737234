<template>
  <div>
    <Alert
      class="alert"
      :dialog="alert.status"
      :icon="alert.icon"
      :text="alert.text"
    />
    <Progress
      v-if="!statusCodeError"
      class="progress"
      :status="progress"
    />
    <Toolbar
      v-if="isShowToolbar"
      class="toolbar"
    />
    <router-view />
    <BootomNav v-if="isShowBottmNav" />
  </div>
</template>

<script>
import figlet from 'figlet'
import standard from 'figlet/importable-fonts/Standard.js'

import BootomNav from '@/components/base/BottomNav'
import Alert from '@/components/dialog/Alert'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Progress from '@/components/dialog/Progress'
import Toolbar from '../components/base/Toolbar.vue'
import isLocalDev from '../utils/isLocalDev'
import { resStatus } from '../utils/resUtils'
import { detectDevIsLineLiffMode } from '../utils/liff'

export default {
  components: { BootomNav, Alert, Progress, Toolbar },

  data: () => ({
    // 要 顯示底部導覽列 頁面
    showBottmNav: ['bonus', 'record', 'user', 'home', 'service'],

    // 視窗寬度
    screenWidth: document.body.clientWidth,

    idToken: null,
  }),

  computed: {
    ...mapState({
      alert: state => state.dialog.alert,
      progress: state => state.dialog.progress.status,
      serviceSystem: state => state.websiteData.cs_settings,
    }),

    ...mapGetters(['lineLiffId', 'layout', 'isLiffMode', 'statusCodeError', 'primaryColor', 'secondaryColor', 'background2_1Color', 'background2_2Color']),

    // 要 顯示頂部工具列 頁面
    showToolbar() {
      if (!this.layout) {
        this.$error('無法取得 layout')
        return
      }

      const origin = [
        'bonusItem',
        'trade',
        'card',
        'add-card',
        'withdrawal',
        'deposit',
        'vip-rules',
        'news',
        'news-item',
        'help',
        'personal',
        'recommend',
        'gift',
        'prize',
        'bonus',
      ]

      // const dynamicLayoutAry = ['template3', 'template4']
      // this.$log(this.layout)
      // if (!dynamicLayoutAry.includes(this.layout)) {
      //   origin.push('prize', 'bonus')
      // } else {
      //   origin.push('bonus')
      // }
      return origin
    },

    isShowBottmNav() {
      return this.showBottmNav.includes(this.$route.name)
    },

    isShowToolbar() {
      return this.showToolbar.includes(this.$route.name)
    },

    getUrlQueryOBJ() {
      const urlSearchString = location.search
      const searchStr = urlSearchString.replace('?liff.state=%3F', '')
      const searchAry = searchStr.split('%26')
      const searchItemsAry = {}

      searchAry.forEach(item => {
        const itemAry = item.split('%3D')
        searchItemsAry[itemAry[0]] = itemAry[1]
      })
      return searchItemsAry
    },

    urlSearch() {
      const locationSearch = location.search
      let liffId = null
      let agentCode = null

      const liffState = new URLSearchParams(locationSearch).get('liff.state')
      if (liffState) {
        liffId = new URLSearchParams(liffState).get('liff_Id')
        agentCode = new URLSearchParams(liffState).get('agent_code')
      } else {
        liffId = new URLSearchParams(locationSearch).get('liff_Id')
        agentCode = new URLSearchParams(locationSearch).get('agent_code')
      }

      return { liff_Id: liffId, agent_code: agentCode }
    },
  },

  watch: {
    /**
     * 監聽文件寬度
     * @date 2021-08-10
     * @param {any} resizeWidth
     * @returns {any}
     */
    screenWidth: {
      handler(resizeWidth) {
        // 如果是 Line Liff 模式不轉向 web
        if (detectDevIsLineLiffMode()) return false
        // 本地開發模式，不轉向 web
        if (process.env.NODE_ENV === 'local') return false
        // 如果開發模式 (橫向>768 或 slot遊戲中) 不轉向 web
        if ((resizeWidth < 768 || this.$route.name === 'launchGame')) return false
        this.pushWebPage()
      },
      immediate: true, // 初始化即執行
    },
  },

  mounted() {
    // 當視窗調整尺吋時執行
    window.onresize = this.onWindowsResize

    // SHOW CONSOLE 圖形文字
    this.showConsoleText()

    // 初始化 revechat
    this.loadRevechatScript()
    document.documentElement.style.setProperty('--primary-color', this.primaryColor)
    document.documentElement.style.setProperty('--secondary-color', this.secondaryColor)
    document.documentElement.style.setProperty('--background2_1-color', this.background2_1Color)
    document.documentElement.style.setProperty('--background2_2-color', this.background2_2Color)
  },

  methods: {

    ...mapActions(['line_login', 'set_website', 'set_user_data', 'show_alert', 'get_user_balance', 'set_line_user_data']),
    ...mapMutations(['SET_LIFF_STATUS']),
    /**
     * 視窗調整尺吋時執行
     * @date 2021-08-10
     */
    onWindowsResize() {
      this.screenWidth = document.body.clientWidth
    },

    /**
     * 轉至 web 頁面
     * @date 2021-08-10
     */
    pushWebPage() {
      const website = this.isLocalDevEv()
      const redirectPages = ['register']
      // 有推薦碼或代理碼，則回傳 true
      const hasRecmmendCodeOrAgentCode = this.$route.query.recommend || this.$route.query.agent_code

      // 若為註冊頁且帶有推薦碼、代理碼，則轉註冊頁面
      if (redirectPages.includes(this.$route.name) && (hasRecmmendCodeOrAgentCode)) {
        this.redirectToRegisterPageURL()
      } else if (redirectPages.includes(this.$route.name)) {
        // 直接跳轉到註冊頁
        location.href = `https://${website}/#/register`
      } else {
        console.error(`準備跳轉到: https://${website}`)
        location.href = `https://${website}`
      }
    },

    /**
     * 建立註冊頁面 + 參數 URL 並跳轉到該頁面
     * @date 2024-03-08
     */
    redirectToRegisterPageURL() {
      const website = this.isLocalDevEv()

      const recommendQuery = this.$route.query.recommend
        ? `recommend=${this.$route.query.recommend}`
        : ''
      const agentQuery = this.$route.query.agent_code
        ? `agent_code=${this.$route.query.agent_code}`
        : ''

      if (recommendQuery && agentQuery) {
        // console.error(1, `https://${website}/#/register?${recommendQuery}&${agentQuery}`)
        location.href = `https://${website}/#/register?${recommendQuery}&${agentQuery}`
      } else if (recommendQuery && !agentQuery) {
        // console.error(2, `https://${website}/#/register?${recommendQuery}`)
        location.href = `https://${website}/#/register?${recommendQuery}`
      } else if (!recommendQuery && agentQuery) {
        // console.error(3, `https://${website}/#/register?${agentQuery}`)
        location.href = `https://${website}/#/register?${agentQuery}`
      }
    },

    /**
     * 是否為本地開發
     * true env 的 站名
     * false host 字串處理
     * @date 2021-08-13
     * @returns {website} 站名
     */
    isLocalDevEv() {
      const website = isLocalDev()
        ? process.env.VUE_APP_WEBSITE
        : location.host
          .replace('m.', '')
          .replace('v2.', '')
      return website
    },

    showConsoleText() {
      // Error: FIGlet header contains invalid values.
      figlet.parseFont('Standard', standard)

      // figlet('[H5] ' + location.host, { font: 'Standard' }, (err, data) => {
      //   if (err) return console.log(err)
      //   console.log(data)
      // })
    },

    async getUserBalance() {
      const walletcode = { walletcode: 'player_balance' }
      await this.get_user_balance(walletcode)
    },

    async getIdToken() {
      const idToken = window.liff.getIDToken()
      // Liff ID && AGENT CODE
      console.log(`Liff ID: ${this.urlSearch.liff_Id} / Agent Code: ${this.urlSearch.agent_code}`)
      if (idToken) {
        const loginData = {
          id_token: idToken,
          website: await this.set_website(),
        }
        // 如果有 agent code 的話，帶入登入資料
        if (this.urlSearch.agent_code) {
          loginData.agent_code = this.urlSearch.agent_code
        }
        const res = await this.line_login(loginData)
        resStatus(res, this.set_user_data)
        this.getUserBalance()
      }
      this.idToken = idToken

      this.getUserData()
    },

    getUserData() {
      window.liff
        .getProfile()
        .then((profile) => {
          this.set_line_user_data(profile)
        })
        .catch((err) => {
          console.log('error', err)
        })
    },

    // 取得 line access token
    getUserAccessToken() {
      const accessToken = window.liff.getAccessToken()
      if (accessToken) {
        this.accessToken = accessToken
      }
    },

    // 初始化 revechat
    loadRevechatScript() {
      const { cs_platform_type, data: { client_id } } = this.serviceSystem
      if (cs_platform_type !== 'revechat' && !client_id) return this.$log('NO REVECHAT =>', `cs_platform_type: ${cs_platform_type}`, `data.client_id: ${client_id}`)
      this.$log('REVECHAT MODE:', cs_platform_type, client_id)

      let $_REVECHAT_API = null

      window.$_REVECHAT_API || (function(d, w) {
        $_REVECHAT_API = function(c) {
          $_REVECHAT_API._.push(c)
        }

        w.__revechat_account = client_id// '5524733'
        w.__revechat_version = 2
        $_REVECHAT_API._ = []
        const rc = d.createElement('script')
        rc.type = 'text/javascript'
        rc.async = true
        rc.setAttribute('charset', 'utf-8')
        rc.src = (document.location.protocol === 'https:' ? 'https://' : 'http://') + 'static.revechat.com/widget/scripts/new-livechat.js?' + new Date().getTime()
        const s = d.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(rc, s)
      })(document, window)
    },
  },
}
</script>

<style lang="scss" scoped>
.alert {
	z-index: 2;
}
</style>
