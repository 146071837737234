<template>
  <!-- 遊戲顯示 -->
  <v-row
    class="py-2"
    :class="[{'px-3': layout!=='template7'&& !activePage},{'game-list': layout==='template7'},{'mx-0': activePage}]"
  >
    <v-col
      v-for="game in games"
      :key="game._id"
      :cols="layout==='template7'|| activePage ? 4 : 6"
      :class="[{'px-1 py-1 p-relative': layout !== 'template7'&& !activePage},{'px-0 py-1': activePage}]"
    >
      <div
        class="overflow-hidden"
        :class="[{'shadow-round-container': layout==='template7'},{'maintenanceBox':game.status==='0'},{'pl-2': activePage}]"
      >
        <!-- 維修中 父層要加 :class="game.status==='0'?'maintenanceBox':''"-->
        <maintenance v-if="game.status=='0'" />

        <!-- 遊戲圖片 -->
        <v-img
          :height="activePage?90:110"
          :src="baseImgUrl + game.game.image"
          :class="[{'cursor-pointer': game.status === '1', 'opacity-1': game.status === '0'},activePage?'rounded-xl':'rounded']"
          :alt="game.localized_name"
          @click="launchGame(game.game_code, game.status)"
        >
          <v-row
            slot="placeholder"
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-row>
        </v-img>

        <!-- <div
          v-if="game.status === '0'"
          class="p-absolute tag caption btnText--text primary rounded px-2 py-0"
        >
          {{ $t('global.inMaintenance') }}
        </div> -->

        <!-- 遊戲敘述 -->
        <div
          class="description d-flex align-center "
          :class="activePage?'pt-1':'py-2'"
        >
          <!-- 遊戲名稱 -->
          <span
            class="description__name subtitle-2"
            :class="[
              {'errorClass': game.status === '0'},
              {'pd-5':layout === 'template7' },
              layout === 'template7' || activePage ? ' grey-text' : 'px-3 pl-3 pr-1 title--text'
            ]"
          >
            {{ game.localized_name }}
          </span>

          <v-spacer />
          <div
            v-if="layout!=='template7' && !activePage "
            class="description__icon"
          >
            <!-- 已收藏 ♥ -->
            <v-btn
              v-if="game.favor === 'true' || game.favor === true"
              icon
              small
              @click="$emit('favorite-game-toggle', game.game_code)"
            >
              <Icon
                class="danger--text description__icon-favorite"
                data="@icon/favorite.svg"
                width="20"
                height="20"
              />
            </v-btn>

            <!-- 未收藏 ♡ -->
            <v-btn
              v-else-if="game.favor === 'false'"
              icon
              small
              @click="$emit('favorite-game-toggle', game.game_code)"
            >
              <Icon
                class="description__icon-unfavorite"
                :class="layout === 'template3' ? 'danger--text' : 'textfield--text'"
                data="@icon/unfavorite.svg"
                width="20"
                height="20"
              />
            </v-btn>
          </div>
        </div>
      </div>
    </v-col>

    <!--  Bottom LOADING  -->
    <v-col
      v-show="scrollBottom.progress"
      cols="12"
      class="text-center pb-0"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import maintenance from '@/components/maintenance'

export default {
  components: { maintenance },
  props: {
    games: {
      type: Array,
      required: true,
    },
    scrollBottom: {
      type: Object,
      required: true,
    },
    activePage: { // 專屬gameType7使用的參數
      type: Boolean,
      required: true,
      default: false,
    },
  },

  computed: {
    ...mapState({
      isLiffMode: state => state.line.liff.status,
    }),
    ...mapGetters(['baseImgUrl', 'layout']),
  },

  methods: {
    ...mapActions([
      'get_game_url',
      'set_progress_status',
      'show_alert',
    ]),

    /**
     * 啟動遊戲 (成功)
     * 另開視窗啟動遊戲
     * @date 2021-06-15
     * @param {string} gameUrl 啟動連結
     */
    launchGameSuccess(gameUrl, windowObj) {
      console.log(`Slot 開啟遊戲，liff mode: ${this.isLiffMode}`)
      const ua = navigator.userAgent.toLowerCase()
      console.log(ua)
      console.log('LINE browser: ' + ua.indexOf('line'))

      // liff 模式 or line 開啟
      if (this.isLiffMode || ua.indexOf('line') >= 0) {
        console.log('遊戲嵌入模式')
        // 進入嵌入遊戲頁面
        this.$router.push({
          name: 'launchGame',
          query: {
            url: gameUrl.url,
            redirectUrl: this.$route.path,
          },
        })
      } else {
        // 一般模式
        windowObj.location.href = gameUrl.url
      }
    },

    launchGameFail() {
      this.show_alert({ icon: 'fail' })
    },

    /**
     * 取得啟動遊戲連結
     * @date 2021-06-15
     * @param {string} gameCode 遊戲代碼
     */
    async launchGame(gameCode, gameStatus) {
      if (gameStatus === '0') return false

      this.set_progress_status(true)

      const windowObj = window.open('', '_blank')
      const gameUrl = await this.get_game_url({ gamecode: gameCode, is_mobile: 1 })
      this.set_progress_status(false)
      resStatus(gameUrl, this.launchGameSuccess, this.launchGameFail, windowObj)
    },
  },
}
</script>

<style scoped>
.opacity-1 {
	opacity: .2;
}

.tag {
	top: 12px;
	right: 12px;
}
</style>
