import { mapGetters, mapState } from 'vuex'
import service24HIcon from '@/assets/icons/24h_service.webp'
import lineServiceIcon from '@/assets/icons/live_service.webp'

export default {
  data: () => ({
    // 目前點擊的項目
    navActive: null,
    lineServiceIcon,
    service24HIcon,

    // serviceItemsArea
    serviceArea: {
      status: false,
    },
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'websiteLogo', 'websiteName', 'currentDevice', 'isLiffMode']),
    ...mapState({
      showLineService: state => state.websiteData.cs_settings.cs_line_enable,
      lineServiceUrl: state => state.websiteData.cs_settings.line_url,
    }),
    navHeight() {
      return this.currentDevice === 'ios' ? 72 : 56
    },

    serviceItems() {
      return {
        serviceLine: {
          icon: this.lineServiceIcon,
          text: 'LINE',
          name: 'Line',
          url: this.lineServiceUrl,
        },
        // 暫時隱藏 24客服
        // service24h: {
        //   icon: this.service24HIcon,
        //   text: this.$t('service.24hService'),
        //   name: '24h',
        //   url: '/service',
        // },
      }
    },
  },

  mounted() {
    this.ifServiceOverlayClick()
  },

  methods: {
    showServiceArea() {
      this.serviceArea.status = true
    },

    ifServiceOverlayClick() {
      document.onmouseup = (e) => {
        if (e.target.classList.value === 'v-overlay__scrim') {
          this.serviceArea.status = false
        }
      }
    },

    serviceLine() {
      location.href = this.serviceItems.serviceLine.url
    },
    service24h() {
      if (this.$route.path === '/service') return false
      this.$router.push({ path: this.serviceItems.service24h.url })
    },

    goService(goType) {
      this[`service${goType}`]()
      this.serviceArea.status = false
    },
  },
}
