var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "userInfo user card1 d-flex align-center px-4 p-relative" },
    [
      _c(
        "div",
        { staticClass: "user-area" },
        [
          !_vm.isLogin
            ? [
                !_vm.isLiffMode
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "title--text text-center px-1 mr-3",
                        attrs: { to: "/login", outlined: "", height: "31" },
                      },
                      [
                        _c("span", { staticClass: "caption" }, [
                          _vm._v(" " + _vm._s(_vm.$t("subHeader.login")) + " "),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.registerStatus
                  ? _c(
                      "v-btn",
                      {
                        staticClass:
                          "title--text text-center px-1 thaigi-gradient",
                        style:
                          _vm.style === "darkGD"
                            ? ""
                            : _vm.customGradientBgTypeA,
                        attrs: { to: "/register", height: "31" },
                      },
                      [
                        _c("span", { staticClass: "caption" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("subHeader.register")) + " "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "pl-0" },
                  [
                    _vm.isLiffMode
                      ? [
                          _vm.lineAvatar
                            ? [
                                _c(
                                  "router-link",
                                  { attrs: { to: "/user" } },
                                  [
                                    _c("v-avatar", [
                                      _c("img", {
                                        attrs: {
                                          size: "20",
                                          src: _vm.lineAvatar,
                                          alt: _vm.lineDisplayName,
                                        },
                                      }),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c("Icon", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.lineIsLogin,
                                      expression: "!lineIsLogin",
                                    },
                                  ],
                                  staticClass: "icon--text",
                                  attrs: {
                                    data: require("@icon/user.svg"),
                                    width: "24",
                                    height: "24",
                                  },
                                }),
                              ],
                          _c(
                            "span",
                            { staticClass: "title--text caption ml-2" },
                            [_vm._v(" " + _vm._s(_vm.lineDisplayName) + " ")]
                          ),
                        ]
                      : [
                          _c("Icon", {
                            staticClass: "icon--text",
                            attrs: {
                              data: require("@icon/user.svg"),
                              width: "24",
                              height: "24",
                            },
                          }),
                          _c(
                            "span",
                            { staticClass: "title--text caption ml-2" },
                            [_vm._v(" " + _vm._s(_vm.userAccount) + " ")]
                          ),
                        ],
                  ],
                  2
                ),
              ],
        ],
        2
      ),
      _c("v-spacer"),
      _c(
        "div",
        { staticClass: "user-balance d-flex align-center" },
        [
          _vm.userBalanceProgressStatus || _vm.balanceLoading
            ? _c(
                "div",
                [
                  _c("v-progress-circular", {
                    staticClass: "mr-4",
                    attrs: {
                      indeterminate: "",
                      width: 2,
                      size: 20,
                      color: "primary",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  !_vm.overlay && _vm.isLogin
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: { click: _vm.retrieveAllWalletBalance },
                        },
                        [
                          _c("Icon", {
                            attrs: {
                              data: require("@icon/user/walletItem/transfer.svg"),
                              width: "23",
                              height: "20",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLiffMode
                    ? [
                        _c(
                          "router-link",
                          {
                            staticClass: "text-decoration-none",
                            attrs: { to: "/user/transfer" },
                          },
                          [
                            _c("span", { staticClass: "mr-1 title--text" }, [
                              _vm._v(" " + _vm._s(_vm.currencySymbol_) + " "),
                            ]),
                            _c("span", { staticClass: "text-h5 title--text" }, [
                              _vm._v(" " + _vm._s(_vm.userBalance) + " "),
                            ]),
                          ]
                        ),
                      ]
                    : [
                        _c("span", { staticClass: "mr-1 title--text" }, [
                          _vm._v(" " + _vm._s(_vm.currencySymbol_) + " "),
                        ]),
                        _c("span", { staticClass: "text-h5 title--text" }, [
                          _vm._v(" " + _vm._s(_vm.userBalance) + " "),
                        ]),
                      ],
                ],
                2
              ),
          _c("Icon", {
            staticClass: "secondary--text ml-1",
            attrs: {
              data: require("@icon/moneyPig.svg"),
              width: "32",
              height: "32",
            },
            on: { click: _vm.fastTrade },
          }),
        ],
        1
      ),
      _c("v-overlay", { attrs: { value: _vm.overlay, opacity: ".8" } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.overlay,
              expression: "overlay",
            },
          ],
          staticClass: "btn-grop p-absolute",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              !_vm.isLiffMode
                ? _c(
                    "v-btn",
                    {
                      staticClass: "px-2",
                      attrs: { to: "user/withdrawal", text: "", height: "56" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column align-center justify-center white--text caption font-weight-bold",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "iconGy rounded-circle w-h-32 d-flex justify-center align-center",
                            },
                            [
                              _c("Icon", {
                                staticClass: "black--text",
                                attrs: {
                                  data: require("@icon/user/walletItem/withdrawal.svg"),
                                  width: "23px",
                                  height: "20px",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" " + _vm._s(_vm.$t("global.withdraw")) + " "),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "px-2",
                  attrs: { to: "user/transfer", text: "", height: "56" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column align-center justify-center white--text caption font-weight-bold",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "iconGy rounded-circle w-h-32 d-flex justify-center align-center",
                        },
                        [
                          _c("Icon", {
                            staticClass: "black--text",
                            attrs: {
                              data: require("@icon/user/walletItem/transfer.svg"),
                              width: "23px",
                              height: "20px",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" " + _vm._s(_vm.$t("global.transferBtn")) + " "),
                    ]
                  ),
                ]
              ),
              !_vm.isLiffMode
                ? _c(
                    "v-btn",
                    {
                      staticClass: "px-2",
                      attrs: { text: "", height: "56" },
                      on: {
                        click: function ($event) {
                          return _vm.goPageFunc("user/deposit")
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column align-center justify-center white--text font-weight-bold subtitle-2",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "white rounded-circle w-h-35 d-flex justify-center align-center",
                            },
                            [
                              _c("Icon", {
                                staticClass: "black--text",
                                attrs: {
                                  data: require("@icon/user/walletItem/deposit.svg"),
                                  width: "32",
                                  height: "25",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" " + _vm._s(_vm.$t("global.deposit")) + " "),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.overlay = false
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "rounded-circle w-h-32 d-flex align-center justify-center",
                    },
                    [
                      _c("Icon", {
                        staticClass: "secondary--text",
                        attrs: {
                          data: require("@icon/money-btn-close.svg"),
                          width: "31",
                          height: "31",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }