import request from '@/api/request'
/*
  API 交易-存取款
  交易相關的 API
    - 取得支付類型列表
    - 玩家存款
    - 玩家取款
    - 設置取款密碼
*/

/* 取得支付類型列表 */
export const getPaymentList = (deviceType) => request.post('api/v1/trans/payment-list', deviceType)

// 支付類型 渠道
export const getPaymentListPipeline = (data) => request.post('/api/v1/trans/payment-channel-list', data)

/* 玩家存款 */
export const deposit = (data) => request.post('api/v2/trans/deposit', data)

/* 玩家取款 */
export const withdraw = (data) => request.post('api/v2/trans/withdrawal', data)

/* 設置取款密碼 */
export const setWithdrawPw = (data) =>
  request.post('api/v1/trans/withdrawal/password', data)
