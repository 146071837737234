import {
  getNewsAPI,
  getMemberNewsAPI,
  updateMemberNewsStatusAPI,
} from '@/api'

import { resStatus } from '../../utils/resUtils'

import {
  SET_NEWS_DATA,
  SET_PROCLAMATION_DATA,
  PUSH_NEWS_DATA,
  SET_MEMBER_NEWS,
  PUSH_MEMBER_NEWS,
} from '../mutation-types'

const state = () => ({
  // 消息中心
  data: {
    items: [],
    lastPage: 1,
  },

  // 公告
  proclamation: {
    items: [],
    lastPage: 1,
  },

  // 站內信
  message: {
    items: [],
    lastPage: 1,
  },
})

const getters = {
  filterNews: state => id =>
    state.data.items.filter(news => news._id === id)[0],

  filterMember: state => id =>
    state.message.items.filter(message => message._id === id)[0],
}

const mutations = {
  /**
   * 設置 消息 新聞
   * @date 2021-05-05
   * @param {object} state
   * @param {array} news 消息 新聞 資料
   */
  [SET_NEWS_DATA](state, news) {
    state.data.items = news.data
    state.data.lastPage = news.last_page
  },

  [PUSH_NEWS_DATA](state, newsData) {
    state.data.items = [...state.data.items, ...newsData]
  },

  /**
   * 設置 公告
   * @date 2021-05-05
   * @param {object} state
   * @param {array} proclamation 公告資料
   */
  [SET_PROCLAMATION_DATA](state, proclamation) {
    state.proclamation.items = proclamation.data
    state.proclamation.lastPage = proclamation.last_page
  },

  [SET_MEMBER_NEWS](state, message = {}) {
    state.message.items = message.data
    state.message.lastPage = message.last_page
  },

  [PUSH_MEMBER_NEWS](state, newMessage = {}) {
    state.message.items = [...state.message.items, ...newMessage]
  },
}

const actions = {
  /**
   * 如果成功取得資料
   * @date 2021-05-05
   * @param {function} commit 存入 SET_NEWS_DATA
   * @param {array} newsData 消息中心 與 公告 資料
   * @returns {any}
   */
  fetch_news_api_success({ commit }, newsData) {
    commit(SET_NEWS_DATA, newsData.news)
    commit(SET_PROCLAMATION_DATA, newsData.index)
  },

  /**
   * api 實例
   * @date 2021-05-05
   * @param {object} context store 實例
   * @param {object} timezoneNPage {timezone, page}
   * @returns {function} api 實例
   */
  fetch_news_api(context, timezoneNPage) {
    return getNewsAPI(timezoneNPage)
  },

  /**
   * 取得 消息 與 公告
   * @date 2021-05-05
   * @param {aobjectny} {dispatch} actions 方法
   * @param {object} timezoneNPage {timezone, page}
   * @returns {object} 響應資料
   */
  async get_news_api({ dispatch }, timezoneNPage) {
    const res = await dispatch('fetch_news_api', timezoneNPage)
    const { page } = timezoneNPage

    if (page === 1) { resStatus(res, data => dispatch('fetch_news_api_success', data)) }

    return res
  },

  async get_member_news({ commit }, timezoneNPage) {
    const res = await getMemberNewsAPI(timezoneNPage)
    return res
  },

  set_member_news({ commit }, message) {
    commit(PUSH_MEMBER_NEWS, message)
  },

  async update_member_news_status(context, newsId) {
    const res = await updateMemberNewsStatusAPI({ news_id: newsId })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
