<template>
  <div
    id="slot"
    flat
    class="ma-0 px-0 py-1 w-100"
  >
    <div
      class="rounded-l-xl rounded-r-xl d-flex align-center w-100"
      :class="bgClr"
    >
      <Icon
        data="@icon/search.svg"
        width="25"
        height="25"
        class="icon--text font-weight-bold"
      />
      <div class="searchInput w-100">
        <!-- 搜尋輸入框 -->
        <slot
          name="input"
        />
      </div>

      <div class="clear">
        <slot name="clearInputTest" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    color: {
      type: String,
      required: false,
    },
  },

  computed: {
    ...mapGetters(['layout', 'theme']),
    ...mapState({
      title: state => state.toolbar.title,
    }),

    bgClr() {
      if (this.layout === 'template3' && this.theme === 'dark') return 'black'
      else if (this.layout === 'template3' && this.theme === 'light') return 'white'
      return 'inputBg'
    },
  },

  destroyed() {
    this.set_toolbar_title('')
  },

  methods: {
    ...mapActions(['set_toolbar_title']),
  },

}
</script>

<style lang="scss" scoped>
.cancelSearch,
.clear {
	width: 48px;
}

.w-100 {
	width: 100%;
}

.svg-icon{
  margin: 0 6px 0 10px;
}

</style>
