var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Alert", {
        staticClass: "alert",
        attrs: {
          dialog: _vm.alert.status,
          icon: _vm.alert.icon,
          text: _vm.alert.text,
        },
      }),
      !_vm.statusCodeError
        ? _c("Progress", {
            staticClass: "progress",
            attrs: { status: _vm.progress },
          })
        : _vm._e(),
      _vm.isShowToolbar ? _c("Toolbar", { staticClass: "toolbar" }) : _vm._e(),
      _c("router-view"),
      _vm.isShowBottmNav ? _c("BootomNav") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }