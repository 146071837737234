<template>
  <component :is="layoutTemplate" />
</template>

<script>
import { mapGetters } from 'vuex'
import { curryTemplate } from '../../../utils/template'

import template1 from './template/UserInfoTemplate1.vue'
import template3 from './template/UserInfoTemplate3.vue'
import template4 from './template/UserInfoTemplate4.vue'
import template5 from './template/UserInfoTemplate5.vue'
import template6 from './template/UserInfoTemplate6.vue'
import template7 from './template/UserInfoTemplate7.vue'

export default {
  computed: {
    ...mapGetters(['layout']),

    layoutTemplate() {
      const templateObj = {
        template1,
        template3,
        template4,
        template6,
        template7,
      }
      if (this.layout === 'template5') return template5
      return curryTemplate(this.layout, templateObj)
    },
  },
}
</script>
